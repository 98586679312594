import { Stack } from "@mui/material";
import { createContext } from "react";
import { Outlet } from "react-router-dom";
import RegionFilter from "./RegionFilter";
import { useRegion } from "./regionHelper";
import { VP_RIGHT_WRAPPER } from "./styles";

export const RegionContext = createContext<any>(null);

export default function Region() {
  const {
    filterLoading,
    dashboardLoading,
    chartLoading,
    regions,
    regionDashboardData,
    regionChartData,
    regionHeader,
    gridColumns,
    selectedWeek,
    selectedDistrict,
    selectedLastWeek,
    getSelectedWeek,
    handleSearchRegions,
    handleRegionChange,
    handleShowAll,
    onDistrictChange,
    handleLastWeekChange,
  } = useRegion();

  return (
    <Stack flex={1} direction={"row"} spacing={2}>
      <RegionFilter
        regionsData={regions}
        isLoading={filterLoading}
        onSearchChange={handleSearchRegions}
        onRegionChange={handleRegionChange}
        onShowAll={handleShowAll}
        onDistrictChange={onDistrictChange}
      />

      <Stack sx={VP_RIGHT_WRAPPER} spacing={2}>
        <RegionContext.Provider
          value={
            {
              regions,
              regionHeader,
              dashboardLoading,
              chartLoading,
              regionDashboardData,
              regionChartData,
              gridColumns,
              selectedWeek,
              selectedDistrict,
              selectedLastWeek,
              getSelectedWeek,
              handleLastWeekChange,
            } as any
          }
        >
          <Outlet />
        </RegionContext.Provider>
      </Stack>
    </Stack>
  );
}
