import { useLocalStorage } from "@uidotdev/usehooks";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import districtService from "../../services/districtService";
import romService from "../../services/romService";
import { generateChartData } from "../../utils/helpers";
import { DISTRICT_COLUMN_DEFS } from "../Region/GridColumns/districtColumns";
import { District } from "../Region/RegionFilter";
import { WEEKS_DATA } from "../../utils/constants";

export const useROM = () => {
  const [districts, setDistricts] = useState([]);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState<string>("");
  // eslint-disable-next-line
  const [storedWeek, setStoredWeek] = useLocalStorage("storedWeek", "");
  const [districtHeader] = useState<any>({
    title: "Analysis for Region",
    subTitle: "Here is your analysis for stores in Districts",
  });
  const [gridColumns] = useState(DISTRICT_COLUMN_DEFS);
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [districtChartData, setDistrictChartData] = useState<any>({
    target: null,
    plan: null,
    store: null,
  });
  const [districtDashboardData, setDistrictDashboardData] = useState<any>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<District | null>(
    null,
  );
  const [districtEffiencies, setDistrictEffiencies] = useState<any>(null);
  const [districtPlans, setDistrictPlans] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedLastWeek, setSelectedLastWeek] = useState<any>(WEEKS_DATA[0]);

  useEffect(() => {
    getRomDistricts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedWeek) {
      getDistrictDashboard();
      getDistrictChartData();
      getDistrictEfficiency();
      getDistrictPlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  const getSelectedWeek = (weekRange: any) => {
    if (weekRange) {
      const startWeek = format(weekRange.start, "yyyy-MM-dd");
      setSelectedWeek(startWeek);
      setStoredWeek(JSON.stringify(weekRange));
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getRomDistricts = async () => {
    try {
      // Fetch logged in user store
      if (districts.length === 0) {
        setIsDistrictLoading(true);
        await romService
          .getRomDistricts()
          .then(async (response) => {
            if (response?.data) {
              setDistricts(response?.data);
              setIsDistrictLoading(false);
            }
          })
          .catch((error) => {
            console.error("Error fetching districts:", error);
            setIsDistrictLoading(false);
          });
      }
    } catch (error) {
      console.error("[getRomDistricts]: ", error);
    }
  };

  const getDistrictDashboard = async () => {
    try {
      setIsDistrictLoading(true);
      const response = await romService.getDistrictROMDashboard(selectedWeek);
      if (response?.data) {
        setDistrictDashboardData(response?.data);
        setIsDistrictLoading(false);
      }
    } catch (error) {
      console.error("[getDistrictDashboard]: ", error);
      setIsDistrictLoading(false);
    }
  };

  const getDistrictChartData = async (week: number = null) => {
    setChartLoading(true);
    try {
      await romService
        .getROMDistrictChart(selectedWeek, week ?? selectedLastWeek.value)
        .then(async (response) => {
          if (response?.data) {
            const tempChartData = generateChartData(response?.data);
            setDistrictChartData({
              ...tempChartData,
              target: {
                ...tempChartData.target,
                chartTitle: "Weekly Efficiency Variance to Target by District",
                chartSubtitle: "Efficiency KPI % Over/Under Target",
              },
              plan: {
                ...tempChartData.plan,
                chartTitle: "Weekly Hours Variance to Plan by District",
                chartSubtitle: "Actual Hours % Over/Under Plan",
              },
            });
            setChartLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching region chart data:", error);
          setChartLoading(false);
        });
    } catch (error) {
      console.error("[getRegionChartData]: ", error);
      setChartLoading(false);
    }
  };

  const getDistrictEfficiency = async () => {
    try {
      setIsDistrictLoading(true);
      const response =
        await districtService.getDistrictEfficiency(selectedWeek);
      if (response?.data) {
        setDistrictEffiencies(response?.data);
        setIsDistrictLoading(false);
      }
    } catch (error) {
      console.error("[getDistrictEfficiency]: ", error);
      setIsDistrictLoading(false);
    }
  };

  const getDistrictPlans = async () => {
    try {
      setIsDistrictLoading(true);
      const response = await districtService.getDistrictPlans(selectedWeek);
      if (response?.data) {
        setDistrictPlans(response?.data);
        setIsDistrictLoading(false);
      }
    } catch (error) {
      console.error("[getDistrictPlans]: ", error);
      setIsDistrictLoading(false);
    }
  };

  const onDistrictChange = (district: District | null) => {
    setSelectedDistrict(district);
  };

  const handleLastWeekChange = async (val: any) => {
    try {
      setSelectedLastWeek(val);
      await getDistrictChartData(val.value);
    } catch (error) {
      console.error("[handleLastWeekChange] Error:", error);
    }
  };

  return {
    isDistrictLoading,
    districts,
    selectedTab,
    districtDashboardData,
    districtEffiencies,
    districtPlans,
    districtHeader,
    gridColumns,
    districtChartData,
    chartLoading,
    selectedWeek,
    selectedDistrict,
    selectedLastWeek,
    getSelectedWeek,
    handleTabChange,
    onDistrictChange,
    handleLastWeekChange,
  };
};
