import { useAccount, useMsal } from "@azure/msal-react";
import {
  Box,
  CSSObject,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  SwipeableDrawer,
  SxProps,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { useLocalStorage } from "@uidotdev/usehooks";
import { format } from "date-fns";
import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useMatch, useNavigate } from "react-router-dom";
import NOTIFICATION_ICON from "../assets/icons/Icon-notification.svg";
import STORE_ICON from "../assets/icons/Icon-store.svg";
import KPI_ICON from "../assets/icons/Icon-target.svg";
import ERROR_ICON from "../assets/icons/error-Icon.svg";
import LOGOUT_ICON from "../assets/icons/icon-logout.svg";
import OPEN_NEW_ICON from "../assets/icons/open-new.svg";
import READ_ERROR_ICON from "../assets/icons/read-error.svg";
import READ_ICON_UPDATE from "../assets/icons/read-icon-update.svg";
import UNREAD_ICON from "../assets/icons/unread-icon.svg";
import UNREAD_NOTIFICATION_ICON from "../assets/icons/unread-notificatoin-icon.svg";
import LOGO from "../assets/images/p-logo.png";
import { ROUTES } from "../routes/constants";
import { HomeContext } from "../screens/Home";
import storeService from "../services/storeService";
import { NotificationType, UserRole } from "../utils/constants";
import { getInitials } from "../utils/helpers";
import { NotificationsOffOutlined } from "@mui/icons-material";
import { CONTENT_BOX_TITLE } from "../screens/Store/styles";
import { BrowserUtils } from "@azure/msal-browser";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  background: "#204EC4",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  background: "#204EC4",
  padding: "32px 8px 16px 8px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const INITIALS_WRAPPER: SxProps = {
  width: 40,
  height: 40,
  padding: "8px 10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "34px",
  background: "#FEBE38",
  alignSelf: "center",
};

const INITIALS: SxProps = {
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
};

const CONTENT_DIVIDER: SxProps = {
  borderBottom: "thin solid #1E3AAB",
  marginBottom: 1.5,
};

const LIST_ITEM_WRAPPER: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 1,
  padding: "8px 4px",
  width: 72,
  "&.Mui-selected, :hover": {
    backgroundColor: "#1D65D6",
    borderRadius: "8px",
  },
};

const LIST_ITEM_TEXT: SxProps = {
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#FFF7F5",
};

const MENU_ITEMS = [
  {
    id: 0,
    icon: STORE_ICON,
    iconAlt: "store",
    text: "My Store",
    path: ROUTES.STORE,
    roles: [UserRole.Developer, UserRole.StoreManager],
  },
  {
    id: 1,
    icon: KPI_ICON,
    iconAlt: "kpi",
    text: "KPI's",
    path: ROUTES.REGION,
    roles: [UserRole.Developer, UserRole.VP],
  },
  {
    id: 2,
    icon: KPI_ICON,
    iconAlt: "kpi",
    text: "KPI's",
    path: ROUTES.DISTRICT,
    roles: [UserRole.Developer, UserRole.DistrictManagers],
  },
  {
    id: 3,
    icon: KPI_ICON,
    iconAlt: "kpi",
    text: "KPI's",
    path: ROUTES.ROM,
    roles: [UserRole.Developer, UserRole.RegionalManagers],
  },
  {
    id: 4,
    icon: STORE_ICON,
    iconAlt: "stores",
    text: "Stores",
    path: ROUTES.REGION_STORES,
    roles: [UserRole.Developer, UserRole.VP],
  },
  {
    id: 5,
    icon: STORE_ICON,
    iconAlt: "stores",
    text: "Stores",
    path: ROUTES.DISTRICT_STORES,
    roles: [UserRole.Developer, UserRole.DistrictManagers],
  },
  {
    id: 6,
    icon: STORE_ICON,
    iconAlt: "stores",
    text: "Stores",
    path: ROUTES.ROM_STORES,
    roles: [UserRole.Developer, UserRole.RegionalManagers],
  },
];

const NOTIFICATION_INDEX = 7;

export default function MiniDrawer() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const account = useAccount();

  const IS_REGION_KPI = useMatch(`${ROUTES.REGION}/*`);
  const IS_REGION_STORES = useMatch(ROUTES.REGION_STORES);
  const IS_ROM_KPI = useMatch(`${ROUTES.ROM}/*`);
  const IS_ROM_STORES = useMatch(ROUTES.ROM_STORES);
  const IS_DISTRICT_KPI = useMatch(`${ROUTES.DISTRICT}/*`);
  const IS_DISTRICT_STORES = useMatch(ROUTES.DISTRICT_STORES);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [storeAlertsData, setStoreAlertsData] = useState<any>(null);
  const [storedWeek] = useLocalStorage("storedWeek", "");
  const [isNotificationLoading, setIsNotificationLoading] =
    useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { currentRole } = useContext(HomeContext);

  // console.log("My Account: ", account);
  const getHistoricAlerts = async () => {
    try {
      setIsNotificationLoading(true);
      const response = await storeService.getHistoricAlerts();
      if (response?.data) {
        setStoreAlertsData(response.data);
      }
    } catch (error) {
    } finally {
      setIsNotificationLoading(false);
    }
  };

  useEffect(() => {
    if (openDrawer) {
      getHistoricAlerts();
    }
  }, [openDrawer]);

  useLayoutEffect(() => {
    if (IS_REGION_STORES) {
      setSelectedIndex(4);
    } else if (IS_ROM_STORES) {
      setSelectedIndex(6);
    } else if (IS_DISTRICT_STORES) {
      setSelectedIndex(5);
    } else if (IS_REGION_KPI) {
      setSelectedIndex(1);
    } else if (IS_ROM_KPI) {
      setSelectedIndex(3);
    } else if (IS_DISTRICT_KPI) {
      setSelectedIndex(2);
    }
  }, [
    IS_REGION_KPI,
    IS_REGION_STORES,
    IS_DISTRICT_KPI,
    IS_DISTRICT_STORES,
    IS_ROM_KPI,
    IS_ROM_STORES,
  ]);

  const handleListItemClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    path?: string,
  ) => {
    if (index === NOTIFICATION_INDEX) {
      setOpenDrawer(true);
      return;
    }
    setSelectedIndex(index);
    navigate(path as string, { state: { fromDrawer: true } });
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenDrawer(open);
    };

  const getIcon = (item: any) => {
    if (item.isRead) {
      if (item.notificationType.toLowerCase() === NotificationType.Message)
        return <img src={READ_ICON_UPDATE} alt={NotificationType.Message} />;
      else if (item.notificationType.toLowerCase() === NotificationType.Error) {
        return <img src={READ_ERROR_ICON} alt={NotificationType.Error} />;
      }
    } else {
      if (item.notificationType.toLowerCase() === NotificationType.Message) {
        return <img src={UNREAD_NOTIFICATION_ICON} alt="unread" />;
      } else if (
        item.notificationType.toLowerCase() === NotificationType.Error
      ) {
        return <img src={ERROR_ICON} alt="error" />;
      }
    }
  };
  const onNotificationClick = async (item: any) => {
    setStoreAlertsData((prev: any) => {
      const updatedData = prev.map((alert: any) => {
        if (alert.id === item.id) {
          alert.isRead = true;
        }
        return alert;
      });
      return updatedData;
    });
    try {
      const { start } = JSON.parse(storedWeek);
      await storeService.updateAlert(
        item.id,
        format(new Date(start), "yyyy-MM-dd"),
        28,
      );
    } catch (error) {
      console.log("onNotificationClick error", error);
    }
  };

  const onNotificationRedirect = (item: any) => {
    setOpenDrawer(false);
    if (item?.storeId) {
      navigate(`${ROUTES.STORE}/${item.storeId}`);
    }
    if (item?.districtId) {
      if (IS_DISTRICT_KPI) {
        navigate(ROUTES.DISTRICT);
      }
      if (IS_ROM_KPI) {
        navigate(`${ROUTES.ROM}/district/${item?.districtId}`);
      }
    }
  };

  return (
    <>
      <Drawer variant="permanent" open={false}>
        <Stack alignItems={"center"} gap={2}>
          <img
            src={LOGO}
            alt="logo"
            width={40}
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(ROUTES.HOME);
            }}
          />
        </Stack>
        <Stack
          justifyContent={"space-between"}
          alignItems={"center"}
          pt={2}
          flex={1}
        >
          <List disablePadding>
            {MENU_ITEMS.map((menu, index) =>
              menu.roles.includes(currentRole as UserRole) ? (
                <ListItemButton
                  key={`top-menu-${index}`}
                  sx={LIST_ITEM_WRAPPER}
                  onClick={(event) =>
                    handleListItemClick(event, menu.id, menu.path)
                  }
                  selected={selectedIndex === menu.id}
                >
                  <ListItemIcon sx={{ minWidth: 24 }}>
                    <img src={menu.icon} alt={menu.iconAlt} />
                  </ListItemIcon>
                  <ListItemText
                    primary={menu.text}
                    sx={LIST_ITEM_TEXT}
                    disableTypography
                  />
                </ListItemButton>
              ) : null,
            )}
          </List>

          <Stack>
            <List disablePadding>
              {currentRole !== UserRole.VP && (
                <ListItemButton
                  sx={LIST_ITEM_WRAPPER}
                  onClick={(event) =>
                    handleListItemClick(event, NOTIFICATION_INDEX)
                  }
                  selected={selectedIndex === NOTIFICATION_INDEX}
                >
                  <ListItemIcon sx={{ minWidth: 24 }}>
                    <img src={NOTIFICATION_ICON} alt="notification" />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Notification"}
                    sx={LIST_ITEM_TEXT}
                    disableTypography
                  />
                </ListItemButton>
              )}
              <ListItemButton
                sx={LIST_ITEM_WRAPPER}
                onClick={() => {
                  instance.logoutRedirect({
                    account: instance.getActiveAccount(),
                    onRedirectNavigate: () => !BrowserUtils.isInIframe(),
                  });
                  if (window.localStorage) {
                    window.localStorage.clear();
                    window.location.reload();
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: 24 }}>
                  <img src={LOGOUT_ICON} alt="logout" />
                </ListItemIcon>
                <ListItemText
                  primary={"Logout"}
                  sx={LIST_ITEM_TEXT}
                  disableTypography
                />
              </ListItemButton>
            </List>
            <Divider sx={CONTENT_DIVIDER} />
            <Box sx={INITIALS_WRAPPER}>
              <Typography sx={INITIALS}>{getInitials(account)}</Typography>
            </Box>
          </Stack>
        </Stack>
      </Drawer>

      <SwipeableDrawer
        sx={{
          "& .MuiDrawer-paper": {
            margin: "24px",
            borderRadius: "12px",
            border: "1px solid rgba(6, 30, 35, 0.30)",
          },
        }}
        anchor={"right"}
        variant="temporary"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Stack sx={{ width: "380px" }}>
          <Stack
            sx={{ padding: "10px 16px", marginBottom: "5px" }}
            alignItems={"center"}
            direction={"row"}
          >
            <Typography
              sx={{
                color: "#061E23",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              Notifications
            </Typography>
          </Stack>
          <Divider orientation="horizontal" />
          <Stack>
            {isNotificationLoading ? (
              <Stack spacing={1.5}>
                {[...Array(5)].map((_, index) => (
                  <Skeleton
                    key={`kpi-skeleton-${index}`}
                    variant="rounded"
                    height={70}
                    sx={{ flex: 1, minHeight: "70px" }}
                  />
                ))}
              </Stack>
            ) : (
              <List dense={true} sx={{ marginBottom: "24px" }}>
                {storeAlertsData?.length > 0 ? (
                  storeAlertsData.map((item: any, index: number) => {
                    return (
                      <Fragment key={`${item.id}`}>
                        <ListItem
                          secondaryAction={<>{getIcon(item)}</>}
                          component={item.isRead ? "div" : "button"}
                          onClick={
                            item.isRead
                              ? undefined
                              : () => onNotificationClick(item)
                          }
                          sx={{ background: "#FFFFFF" }}
                        >
                          <ListItemIcon sx={{ minWidth: "25px" }}>
                            {!item.isRead && (
                              <img src={UNREAD_ICON} alt="info" />
                            )}
                          </ListItemIcon>
                          <ListItemText sx={{ marginRight: 1 }}>
                            <Stack>
                              <Typography
                                sx={{ fontSize: "14px", color: "#061E23" }}
                              >
                                {item.emailMessage}
                              </Typography>
                            </Stack>
                            <Stack
                              sx={{ marginTop: "5px" }}
                              display={"flex"}
                              justifyContent={"flex-start"}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                              >
                                {(item?.storeId || item?.districtId) &&
                                  currentRole !== UserRole.StoreManager && (
                                    <Chip
                                      sx={{
                                        background: "rgba(187, 239, 255, 0.54)",
                                        borderRadius: "4px",
                                      }}
                                      onClick={() =>
                                        onNotificationRedirect(item)
                                      }
                                      icon={
                                        <img
                                          src={OPEN_NEW_ICON}
                                          alt="open new"
                                        />
                                      }
                                      label={
                                        item.storeId
                                          ? `Store #${item.storeCode || ""}`
                                          : "District"
                                      }
                                    />
                                  )}
                                <Typography
                                  sx={{
                                    marginLeft: "10px",
                                    fontSize: "12px",
                                    color: "rgba(6, 30, 35, 0.74)",
                                  }}
                                >
                                  {item.emailSentDateString}
                                </Typography>
                              </Stack>
                            </Stack>
                          </ListItemText>
                        </ListItem>
                        <Divider
                          key={`${item.id}-${index}-${item.storeId}`}
                          variant="fullWidth"
                          component="li"
                        />
                      </Fragment>
                    );
                  })
                ) : (
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    flex={1}
                    spacing={1}
                  >
                    <NotificationsOffOutlined
                      fontSize="large"
                      sx={{ color: "rgba(6, 30, 35, 0.34)" }}
                    />
                    <Typography sx={{ ...CONTENT_BOX_TITLE, color: "#595959" }}>
                      No notifications found!
                    </Typography>
                  </Stack>
                )}
              </List>
            )}
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </>
  );
}
