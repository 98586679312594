import { axiosInstance } from "./serviceConfig";

const regionService = {
  getRegions: (term?: string) =>
    axiosInstance.get(
      term ? `/api/vp/regions?searchText=${term}` : "/api/vp/regions",
    ),
  getRegionDashboard: (startDate: string) =>
    axiosInstance.get(`/api/vp/regions/dashboard?startDate=${startDate}`),
  getRegionDashboardById: (regionId: number, startDate: string) =>
    axiosInstance.get(
      `/api/vp/regions/${regionId}/dashboard?startDate=${startDate}`,
    ),
  getRegionChart: (startDate: string, numberOfWeeks: number) =>
    axiosInstance.get(
      `/api/vp/regions/graphdata?startDate=${startDate}&numberOfWeeks=${numberOfWeeks}`,
    ),
  getRegionChartById: (
    regionId: number,
    startDate: string,
    numberOfWeeks: number,
  ) =>
    axiosInstance.get(
      `/api/vp/regions/${regionId}/districts/graphdata?startDate=${startDate}&numberOfWeeks=${numberOfWeeks}`,
    ),
  getStores: ({ districtId, regionId, take, skip, searchTerm }: any) => {
    return districtId && regionId
      ? axiosInstance.get(
          `/api/vp/stores?RegionId=${regionId}&DistrictId=${districtId}&Take=${take}&Skip=${skip}&SearchText=${searchTerm}`,
        )
      : axiosInstance.get(
          `/api/vp/stores?Take=${take}&Skip=${skip}&SearchText=${searchTerm}`,
        );
  },
  getDistrictsByRegion: () => axiosInstance.get("api/region"),
};

export default regionService;
