import { Typography, Box, Stack, StackProps, Divider } from "@mui/material";
import {
  WIDGET_TITLE,
  WIDGET_UNIT,
  WIDGET_VALUE,
  WIDGET_WRAPPER,
} from "../screens/Store/styles";

interface WidgetProps extends StackProps {
  icon?: string;
  data: {
    title: string;
    value: string | number;
    unit: string;
    value2?: string | number;
    unit2?: string;
  }[];
  flexValues?: number[];
}

// Define the component function
function StatisticsWidget({ icon, data, flexValues, ...rest }: WidgetProps) {
  return (
    <Stack sx={WIDGET_WRAPPER} {...rest}>
      {icon && <img src={icon} alt="icon" />}
      {data.map(({ title, value, unit, value2, unit2 }, index) => (
        <Stack
          key={`statistics_${index}`}
          height={46}
          borderRight={
            index < data?.length - 1
              ? "1px solid rgba(6, 30, 35, 0.10)"
              : "none"
          }
          flex={flexValues?.[index] || 1}
        >
          <Typography sx={WIDGET_TITLE}>{title}</Typography>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography component="div" sx={WIDGET_VALUE}>
              {value}{" "}
              {unit && (
                <Box sx={WIDGET_UNIT}>
                  {unit === "Hrs" ? unit : `(${unit})`}
                </Box>
              )}
            </Typography>
            {value2 && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
            {value2 && (
              <Typography component="div" sx={WIDGET_VALUE}>
                {value2}{" "}
                {unit2 && (
                  <Box sx={WIDGET_UNIT}>
                    {unit2 === "Hrs" ? unit : `(${unit2})`}
                  </Box>
                )}
              </Typography>
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

export default StatisticsWidget;
