import { SxProps } from "@mui/material";

// VP Flow Screens
export const VP_LEFT_WRAPPER: SxProps = {
  width: 318,
  borderRadius: "12px",
  background: "#FFFFFF",
};

export const VP_RIGHT_WRAPPER: SxProps = {
  padding: "24px 24px 42px 24px",
  background: "#FFFFFF",
  borderRadius: "12px",
  flex: 1,
};

export const VP_FILTER_TITLE: SxProps = {
  fontSize: 20,
  lineHeight: "24px",
  fontWeight: 500,
  color: "#061E23",
};

export const VP_FILTER_SUBTITLE: SxProps = {
  fontSize: 12,
  lineHeight: "21px",
  fontWeight: 500,
  color: "#595959",
};

export const VP_SEARCH_INPUT: SxProps = {
  border: "1px solid rgba(6, 30, 35, 0.10)",
  borderRadius: "40px",
  padding: "8px 16px",
  "& .MuiInputBase-input": {
    border: "none",
    padding: 0,
  },
};

export const VP_ACCORDION_WRAPPER: SxProps = {
  border: "1px solid rgba(6, 30, 35, 0.10)",
  background: "rgba(6, 30, 35, 0.01)",
  padding: "12px 12px 12px 16px",
  borderRadius: "8px !important",
  "&::before": {
    height: 0,
  },
  "&.Mui-expanded": {
    border: "1px solid #FEBE38",
    borderLeftWidth: "3px",
  },
};

export const VP_ACCORDION_SUMMARY: SxProps = {
  "&.MuiButtonBase-root": {
    padding: 0,
  },
  ".MuiAccordionSummary-content": {
    margin: 0,
  },
};

export const VP_ACCORDION_TITLE: SxProps = {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "21px",
  color: "#061E23",
  textTransform: "uppercase",
};

export const VP_ACCORDION_TEXT: SxProps = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "17px",
  color: "#383838",
};

export const VP_ACCORDION_ITEM: SxProps = {
  padding: 1,
  borderRadius: "8px",
  border: "1px solid rgba(6, 30, 35, 0.08)",
  background: "rgba(6, 30, 35, 0.01)",
  marginTop: 1,
  "&.Mui-selected": {
    background: "rgba(6, 30, 35, 0.01)",
    border: "1px solid #33B000",
  },
};

export const VP_ACCORDION_ITEM_TEXT: SxProps = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "21px",
  color: "#061E23",
};

export const VP_TITLE: SxProps = {
  fontSize: 24,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
};

export const VP_SUBTITLE: SxProps = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "21px",
  color: "rgba(6, 30, 35, 0.80)",
};

export const VP_CONTENT_BOX_TITLE: SxProps = {
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
};

export const VP_CONTENT_BOX_SUBTITLE: SxProps = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "21px",
  color: "rgba(6, 30, 35, 0.74)",
};

export const TEXT_BTN: SxProps = {
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "20px",
  color: "#204EC4",
  textTransform: "capitalize",
};

export const VP_GRID_TITLE: SxProps = {
  fontSize: 14,
  fontWeight: 400,
  color: "#061E23",
  lineHeight: "24px",
  paddingLeft: 0.5,
};
