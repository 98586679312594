import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: "Roboto",
  },
  palette: {
    primary: {
      main: "#204EC4",
    },
    secondary: {
      main: "#FEBE38",
    },
    error: {
      main: "#EE4112",
    },
    background: {
      default: "rgba(222, 222, 233, 0.2)",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          marginRight: 24,
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "24px",
          color: "rgba(6, 30, 35, 0.74)",
          padding: "10px 12px",
          textTransform: "capitalize",
          "&.Mui-selected": {
            color: "#204EC4",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
        indicator: {
          backgroundColor: "#204EC4",
        },
      },
    },
  },
});

export default theme;
