import { SxProps } from "@mui/material";

export const STORES_SEARCH_INPUT: SxProps = {
  border: "1px solid rgba(6, 30, 35, 0.10)",
  borderRadius: "4px",
  padding: "8px 16px",
  "& .MuiInputBase-input": {
    border: "none",
    padding: 0,
  },
};

export const STORES_HEADER_WRAPPER: SxProps = {
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 2,
  borderRadius: "8px 8px 0px 0px",
  borderTop: "1px solid rgba(6, 30, 35, 0.10)",
  borderRight: "1px solid rgba(6, 30, 35, 0.10)",
  borderLeft: "1px solid rgba(6, 30, 35, 0.10)",
  background: "rgba(6, 30, 35, 0.01)",
};

export const STORES_CREATE_BTN: SxProps = {
  borderRadius: "4px",
  height: 40,
  border: "1px solid #FF9700",
  background: "#FEBE38",
  textTransform: "capitalize",
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
  boxShadow: "none",
  padding: "8px 12px 8px 8px",
  "&:hover": {
    border: "1px solid #FF9700",
    background: "#FEBE38",
  },
};

export const PAGE_WRAPPER: SxProps = {
  background: "#FFFFFF",
  borderRadius: "12px",
  padding: 3,
  flex: 1,
};
export const TABLE: SxProps = {
  // borderCollapse: 'separate',
  // borderSpacing: '0px 4px'
};
export const TABLE_HEADER: SxProps = {
  borderRadius: "8px",
};
export const TABLE_ROW_COL: SxProps = {
  borderBottom: "0",
  background: "#FFF7F5",
  fontSize: "12px",
  color: "#061E23",
};
