import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  RowHeightParams,
} from "ag-grid-community";
import { COLUMN_DEFS } from "./columns";
import { NEW_RECORD } from "./mock";
import _ from "lodash";

const DEFAULT_ROW_HEIGHT = 56;

const DAYS_LIST = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const getDayName = (index: number) => DAYS_LIST[index];

export const useScheduleGrid = (
  isEditable: boolean,
  scheduleData: any,
  smDetails: any,
) => {
  const [rowData, setRowData] = useState<any[] | null>(null);
  const [bottomRowData, setBottomRowData] = useState<any[]>([]);
  const [columnDefs] = useState<(ColDef<any> | ColGroupDef<any>)[] | null>(
    COLUMN_DEFS,
  );

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
    };
  }, []);

  useEffect(() => {
    if (scheduleData) {
      const gridData = transformGridData(scheduleData);
      const bottomData = transformGridBottomRows(scheduleData);

      setRowData(gridData);
      setBottomRowData(bottomData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData]);

  // Function to generate a unique identifier
  function generateUniqueId() {
    return Math.random().toString(36).slice(2, 9);
  }

  function generateNewSchedule(
    record: any,
    newEmployeeName: string,
    newJobId: string | number,
    newJobName: string,
  ) {
    const updatedRecord = _.cloneDeep({ ...record });

    // Update employeeName, jobId, and jobName
    updatedRecord.employeeName = newEmployeeName;
    updatedRecord.jobId = newJobId;
    updatedRecord.jobName = newJobName;
    updatedRecord[
      "id"
    ] = `${newEmployeeName}_${newJobId}_${generateUniqueId()}`;
    return updatedRecord;
  }

  const onNewEmployeeAdd = (newEntry: any, _gridRef: any) => {
    if (newEntry) {
      const tempRowData = _.cloneDeep(rowData);
      tempRowData?.push(
        generateNewSchedule(
          NEW_RECORD,
          newEntry?.employeeName,
          newEntry?.employeePosition?.jobId,
          newEntry?.employeePosition?.name,
        ),
      );
      setRowData(_.cloneDeep(tempRowData));
    }
  };

  const onCellEditingStarted = useCallback(
    (params: CellEditingStartedEvent) => {
      const nonEditableCells = [
        "Total Scheduled Hours",
        // "Expected Clocked Hours",
        "Recommended Hours",
        "Over/(Under) Recommended Hours",
        // "Carry over",
        // "Carry forward",
      ];

      const isCellNonEditable =
        !isEditable ||
        nonEditableCells.includes(params?.value) ||
        nonEditableCells.includes(params?.data?.employeeName);

      if (isCellNonEditable) {
        params.api.stopEditing();
      }
    },
    [isEditable],
  );

  const onCellEditingStopped = useCallback(
    (params: CellEditingStoppedEvent) => {
      params.api.redrawRows();
      // params.api.refreshClientSideRowModel();
      // let rowData: any[] = [];
      // params.api.forEachNode((node: { data: any }) => rowData.push(node.data));
    },
    [],
  );

  const getRowHeight = useCallback(
    (params: RowHeightParams): number | undefined | null =>
      params.data.rowHeight || DEFAULT_ROW_HEIGHT,
    [],
  );

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // params.api.showLoadingOverlay();
    params.api.sizeColumnsToFit();
  }, []);

  const transformGridData = (inputData: any) => {
    if (inputData?.scheduleEmployees?.length > 0) {
      return inputData.scheduleEmployees.map((employee: any) => {
        const transformedEmployee: any = {
          employeeName: employee.employeeName,
          jobId: employee.jobId,
          jobName: employee.jobName,
        };

        employee.scheduleHours.forEach((hours: any, index: number) => {
          const dayName = getDayName(index);

          transformedEmployee[dayName] = {
            inTime: hours.inTime ? hours.inTime.substring(0, 5) : null,
            outTime: hours.outTime ? hours.outTime.substring(0, 5) : null,
            hoursText: hours.hoursText,
            clockHoursText: hours.clockHoursText,
            hoursInMinutes: hours.hoursInMinutes,
            clockHoursInMinutes: hours.clockHoursInMinutes,
            carryOverHoursInMinutes: hours.carryOverHoursInMinutes,
            carryOverHoursInMinutesText: hours.carryOverHoursInMinutesText,
            scheduleId: hours.scheduleId,
          };
        });

        transformedEmployee.weeklyTotalHoursInMinutes =
          employee.weeklyTotalHoursInMinutes;
        transformedEmployee.weeklyTotalHoursText =
          employee.weeklyTotalHoursText;

        return transformedEmployee;
      });
    } else {
      const tempRowData = [];
      tempRowData.push(
        generateNewSchedule(
          NEW_RECORD,
          smDetails?.name || "",
          smDetails?.jobId || "",
          smDetails?.jobName || "",
        ),
      );
      return tempRowData;
    }
  };

  const transformGridBottomRows = (inputData: any) => {
    return [
      {
        rowHeight: 2,
        isBlankRow: true,
      },
      {
        rowHeight: 38,
        twoColspan: true,
        employeeName: "Total Scheduled Hours",
        weeklyTotalHoursText: inputData?.totalWeeklyHoursText,
        ...transformBottomRowData("totalHoursText", inputData),
      },
      {
        rowHeight: 24,
        isBlankRow: true,
      },
      // {
      //   rowHeight: 38,
      //   twoColspan: true,
      //   employeeName: "Expected Clocked Hours",
      //   weeklyTotalHoursText: inputData?.totalWeeklyClockHoursText,
      //   ...transformBottomRowData("totalClockHoursText", inputData, true),
      // },
      {
        rowHeight: 38,
        twoColspan: true,
        employeeName: "Recommended Hours",
        weeklyTotalHoursText: inputData?.totalWeeklyRecommendedHoursText,
        ...transformBottomRowData("recommendedHoursText", inputData),
      },
      {
        rowHeight: 38,
        twoColspan: true,
        employeeName: "Over/(Under) Recommended Hours",
        weeklyTotalHoursText:
          inputData?.totalWeeklyOverUnderRecommendedHoursText,
        ...transformBottomRowData("overOrUnderRecommendedHoursText", inputData),
      },
    ];
  };

  const transformBottomRowData = (
    hoursTextKey: string,
    inputData: any,
    // isExpectedClocked?: boolean
  ) => {
    const bottomRowData: any = {};
    inputData.scheduleDays.forEach((day: any, index: number) => {
      const dayName = getDayName(index);

      bottomRowData[dayName] = {
        // inTime: isExpectedClocked
        //   ? index === 0
        //     ? "Carry over"
        //     : index === inputData.scheduleDays?.length - 1
        //     ? "Carry forward"
        //     : ""
        //   : "",
        inTime: "",
        outTime: "",
        hoursText: day[hoursTextKey],
      };

      // Additional conditions for "Carry over" and "Carry forward"
      // if (
      //   isExpectedClocked &&
      //   index === 0 &&
      //   inputData.previousWeekCarryOverHoursInMinutesText !== null
      // ) {
      //   bottomRowData[dayName].outTime =
      //     inputData.previousWeekCarryOverHoursInMinutesText;
      // }

      // if (
      //   isExpectedClocked &&
      //   index === inputData.scheduleDays?.length - 1 &&
      //   inputData.carryForwardHoursInMinutesText !== null
      // ) {
      //   bottomRowData[dayName].outTime =
      //     inputData.carryForwardHoursInMinutesText;
      // }
    });

    return bottomRowData;
  };

  const convertGridDataToScheduleEmp = () => {
    const finalData = rowData?.map(
      ({ employeeName, jobId, jobName, ...days }: any) => {
        const scheduleHours = Object.keys(days).map((day) => {
          if (DAYS_LIST.indexOf(day) > -1) {
            const {
              inTime,
              outTime,
              hoursText,
              clockHoursText,
              hoursInMinutes,
              clockHoursInMinutes,
              carryOverHoursInMinutes,
              carryOverHoursInMinutesText,
              scheduleId,
            } = days[day];

            const tempHours = {
              scheduleId,
              inTime: inTime || null,
              outTime: outTime || null,
              hoursInMinutes,
              clockHoursInMinutes,
              carryOverHoursInMinutes,
              hoursText,
              clockHoursText,
              carryOverHoursInMinutesText,
            };
            return tempHours;
          }
          return null;
        });

        const tempItem = {
          employeeName,
          jobId,
          jobName,
          scheduleHours: _.compact(scheduleHours),
          weeklyTotalHoursInMinutes: days.weeklyTotalHoursInMinutes,
          weeklyTotalHoursText: days.weeklyTotalHoursText,
        };
        return tempItem;
      },
    );
    return finalData;
  };

  return {
    rowData,
    bottomRowData,
    columnDefs,
    defaultColDef,
    onCellEditingStarted,
    onCellEditingStopped,
    getRowHeight,
    onGridReady,
    convertGridDataToScheduleEmp,
    onNewEmployeeAdd,
  };
};
