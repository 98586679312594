export const NEW_RECORD = {
  employeeName: null,
  jobId: null,
  jobName: null,
  sunday: {
    inTime: null,
    outTime: null,
    hoursText: null,
    clockHoursText: null,
    hoursInMinutes: null,
    clockHoursInMinutes: null,
    carryOverHoursInMinutes: null,
    carryOverHoursInMinutesText: null,
    scheduleId: 0,
  },
  monday: {
    inTime: null,
    outTime: null,
    hoursText: null,
    clockHoursText: null,
    hoursInMinutes: null,
    clockHoursInMinutes: null,
    carryOverHoursInMinutes: null,
    carryOverHoursInMinutesText: null,
    scheduleId: 0,
  },
  tuesday: {
    inTime: null,
    outTime: null,
    hoursText: null,
    clockHoursText: null,
    hoursInMinutes: null,
    clockHoursInMinutes: null,
    carryOverHoursInMinutes: null,
    carryOverHoursInMinutesText: null,
    scheduleId: 0,
  },
  wednesday: {
    inTime: null,
    outTime: null,
    hoursText: null,
    clockHoursText: null,
    hoursInMinutes: null,
    clockHoursInMinutes: null,
    carryOverHoursInMinutes: null,
    carryOverHoursInMinutesText: null,
    scheduleId: 0,
  },
  thursday: {
    inTime: null,
    outTime: null,
    hoursText: null,
    clockHoursText: null,
    hoursInMinutes: null,
    clockHoursInMinutes: null,
    carryOverHoursInMinutes: null,
    carryOverHoursInMinutesText: null,
    scheduleId: 0,
  },
  friday: {
    inTime: null,
    outTime: null,
    hoursText: null,
    clockHoursText: null,
    hoursInMinutes: null,
    clockHoursInMinutes: null,
    carryOverHoursInMinutes: null,
    carryOverHoursInMinutesText: null,
    scheduleId: 0,
  },
  saturday: {
    inTime: null,
    outTime: null,
    hoursText: null,
    clockHoursText: null,
    hoursInMinutes: null,
    clockHoursInMinutes: null,
    carryOverHoursInMinutes: null,
    carryOverHoursInMinutesText: null,
    scheduleId: 0,
  },
  weeklyTotalHoursInMinutes: null,
  weeklyTotalHoursText: null,
};
