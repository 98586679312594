import { ChangeEvent, useEffect, useState } from "react";
import districtService from "../../../services/districtService";

export const useDistrictStores = () => {
  const [dmStores, setDmStores] = useState([]);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    getDistrictManagerStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    setSearchTerm(value);
  };

  const getDistrictManagerStores = async () => {
    try {
      // Fetch logged in user store

      setIsDistrictLoading(true);
      await districtService
        .getDistrictManagerStores(searchTerm)
        .then(async (response) => {
          if (response?.data) {
            setDmStores(response?.data);
            setIsDistrictLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching stores:", error);
          setIsDistrictLoading(false);
        });
    } catch (error) {
      console.error("[getDistrictManagerStores]: ", error);
    }
  };
  useEffect(() => {
    if (searchTerm.length > 3) {
      getDistrictManagerStores();
    }
    if (searchTerm.length === 0) {
      getDistrictManagerStores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  return {
    isDistrictLoading,
    dmStores,
    searchTerm,
    handleClearSearch,
    onSearch,
  };
};
