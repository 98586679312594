import styled from "@emotion/styled";
import { SwitchProps, Switch } from "@mui/material";

interface CustomSwitchProps extends SwitchProps {}

export const CustomSwitch: React.FC<CustomSwitchProps> = styled(
  Switch,
)<CustomSwitchProps>(() => ({
  width: 36,
  height: 20,
  padding: 0,
  color: "#FFF",
  marginLeft: "12px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#204EC4",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#FFF",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 1,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#808083",
    opacity: 1,
  },
}));
