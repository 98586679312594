import _ from "lodash";
import { CHART_COLORS, ROLE_COLORS } from "./constants";

export const getInitials = (account: any) => {
  const name = account ? account?.name : "";
  if (!name) {
    return "";
  }
  const splittedName: string[] = name?.split(" ") || [];
  let initials = "";

  if (splittedName?.length > 2) {
    initials =
      (splittedName[2]?.charAt(0) || "") + (splittedName[0]?.charAt(0) || "") ||
      "";
  } else {
    initials =
      (splittedName[1]?.charAt(0) || "") + (splittedName[0]?.charAt(0) || "") ||
      "";
  }
  return initials;
};

export const convertArrayToObject = (inputArray: any[]) => {
  const resultObject: any = {};

  inputArray.forEach((item) => {
    const key = Object.keys(item)[0];
    resultObject[key] = item[key];
  });

  return resultObject;
};

export const getColorCode = (index: number) => {
  if (index < CHART_COLORS.length) {
    return CHART_COLORS[index];
  } else {
    // Generate color by incrementing RGB values based on the index
    const baseColor = CHART_COLORS[index % CHART_COLORS.length];
    const increment = Math.floor(index / CHART_COLORS.length);

    // Parse RGB values
    const [, r, g, b]: any = baseColor.match(/^#(..)(..)(..)$/);

    // Calculate new RGB values by incrementing the base color
    const newR = Math.min(parseInt(r, 16) + increment, 255)
      .toString(16)
      .padStart(2, "0");
    const newG = Math.min(parseInt(g, 16) + increment, 255)
      .toString(16)
      .padStart(2, "0");
    const newB = Math.min(parseInt(b, 16) + increment, 255)
      .toString(16)
      .padStart(2, "0");

    // Construct the new color
    const newColor = `#${newR}${newG}${newB}`;

    return newColor;
  }
};

export const getColourByRole = (role: string, index: number) => {
  return (
    ROLE_COLORS.find(
      (item) => item.role.toLowerCase().trim() === role.toLowerCase().trim(),
    )?.color || getColorCode(CHART_COLORS.length + index)
  );
};

export const getFullAddressFromStoreDetails = (storeDetails: any) => {
  if (!storeDetails) return "";
  const { address1, address2, city, state } = storeDetails;

  // Create an array to hold non-empty parts of the address
  const addressParts: string[] = [];

  // Add non-empty address lines to the array
  if (address1?.trim()) addressParts.push(_.startCase(_.lowerCase(address1)));
  if (address2?.trim()) addressParts.push(_.startCase(_.lowerCase(address2)));

  // Add city and state if available
  if (city || state) {
    const cityStateString = [city, state].filter(Boolean).join(", ");
    addressParts.push(_.startCase(_.lowerCase(cityStateString)));
  }

  // Join all parts with commas and return the result
  return addressParts.join(", ");
};

export const generateChartData = (data: any) => {
  const target = {
    data: data?.varianceToTarget,
    lines: data?.varianceToTarget
      ? Object.keys(data?.varianceToTarget[0])?.filter((k) => k !== "date")
      : [],
  };

  const plan = {
    data: data?.varianceToPlan,
    lines: data?.varianceToPlan
      ? Object.keys(data?.varianceToPlan[0])?.filter((k) => k !== "date")
      : [],
  };

  return {
    target,
    plan,
    store: data?.varianceToPlanByStores || null,
  };
};

export const getGridContainerHeight = (data: any[] = []) => {
  const records = Math.min(data?.length || 2, 10);
  const dynamicHeight = records * 48;
  const staticHeight = 56 + 5;
  return staticHeight + dynamicHeight;
};

export const formatValue = (value: any) => {
  let renderedValue = null;

  if (value === null) {
    renderedValue = "-";
  } else if (typeof value === "number") {
    renderedValue = value.toFixed(1);
  } else if (typeof value === "string" && !isNaN(parseFloat(value))) {
    const numericValue = parseFloat(value);
    renderedValue = numericValue.toFixed(1);
  } else {
    // Convert string to first letter capital
    renderedValue = _.startCase(_.lowerCase(value));
  }
  return renderedValue;
};

export const CustomCellRenderer = ({ value }: any) => {
  return formatValue(value);
};
