import { Alert, AlertTitle, Snackbar } from "@mui/material";

const CustomSnackbar = ({
  open,
  onClose,
  severity,
  title,
  message,
  autoHideDuration = 8000,
}: any) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
