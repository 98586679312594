import { Stack } from "@mui/material";
import { createContext, useContext } from "react";
import { Outlet } from "react-router-dom";
import { VP_RIGHT_WRAPPER } from "../Region/styles";
import StoreFilter from "./StoreFilter";
import { useDistrict } from "./districtHelper";

const districtContext = createContext<any>({});
export default function District() {
  const {
    dmStores,
    isDistrictLoading,
    districtDashboardData,
    districtEffiencies,
    selectedTab,
    districtPlans,
    chartLoading,
    districtChartData,
    keyAlerts,
    selectedLastWeek,
    getSelectedWeek,
    handleTabChange,
    handleLastWeekChange,
  } = useDistrict();

  return (
    <Stack flex={1} direction={"row"} spacing={2}>
      <StoreFilter storesData={dmStores} isLoading={isDistrictLoading} />
      <Stack sx={{ ...VP_RIGHT_WRAPPER }} spacing={2}>
        <districtContext.Provider
          value={{
            isDistrictLoading,
            districtDashboardData,
            districtEffiencies,
            districtPlans,
            selectedTab,
            chartLoading,
            districtChartData,
            keyAlerts,
            selectedLastWeek,
            getSelectedWeek,
            handleTabChange,
            handleLastWeekChange,
          }}
        >
          <Outlet />
        </districtContext.Provider>
      </Stack>
    </Stack>
  );
}
// Custom hook to use the context
export const useDistrictContext = () => {
  return useContext(districtContext);
};
