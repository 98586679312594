import {
  Box,
  Skeleton,
  Stack,
  SxProps,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { GridReadyEvent, RowHeightParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import {} from ".";
import { useRomContext } from "..";
import CustomLineChart from "../../../components/CustomLineChart";
import CustomWeekRangeInput from "../../../components/CustomWeekRangeInput";
import KeyAlerts from "../../../components/KeyAlerts";
import StatisticsWidget from "../../../components/StatsticsWidget";
import { VP_GRID_TITLE, VP_SUBTITLE, VP_TITLE } from "../../Region/styles";
import { CONTENT_BOX, GRID_WRAPPER } from "../../Store/styles";
import { useROMDistrict } from "../romDistrictHelper";
import { CustomSelect } from "../../../components";
import { WEEKS_DATA } from "../../../utils/constants";

const DEFAULT_ROW_HEIGHT = 48;

export default function ROMDistricts() {
  const { getSelectedWeek, selectedWeek } = useRomContext();
  const dashboardGridRef = useRef<AgGridReact>(null);
  const effieciencyGridRef = useRef<AgGridReact>(null);
  const planGridRef = useRef<AgGridReact>(null);

  const {
    dashboardLoading,
    chartLoading,
    districtDashboardData,
    gridColumns,
    selectedTab,
    efficiencyStores,
    planStores,
    districtStoreChartData,
    keyAlerts,
    selectedLastWeek,
    handleTabChange,
    handleLastWeekChange,
  } = useROMDistrict(selectedWeek);

  const EFFICIENCY_DATA = [
    {
      title: "Actual Efficiency",
      value: _.round(districtDashboardData?.kpiData?.efficiency, 1) || 0,
      unit: "",
    },
    {
      title: "Target Efficiency",
      value: _.round(districtDashboardData?.kpiData?.target, 1) || 0,
      unit: "",
    },
    {
      title: "Over/-Under Target",
      value:
        _.round(districtDashboardData?.kpiData?.overUnderTargetValue, 1) || 0,
      unit: "",
      value2: districtDashboardData?.kpiData?.overUnderTargetPercent
        ? `${_.round(
            districtDashboardData?.kpiData?.overUnderTargetPercent,
            1,
          )}%`
        : "",
      unit2: "",
    },
  ];

  const ACTUAL_DATA = [
    {
      title: "Actual Hours",
      value: _.round(districtDashboardData?.kpiData?.actualHoursText, 1) || 0,
      unit: "Hrs",
    },
    {
      title: "Plan Hours",
      value: _.round(districtDashboardData?.kpiData?.planHoursText, 1) || 0,
      unit: "Hrs",
    },
    {
      title: "Over/-Under Plan",
      value:
        _.round(districtDashboardData?.kpiData?.overUnderPlanHoursText, 1) || 0,
      unit: "",
      value2: districtDashboardData?.kpiData?.overUnderPlanHoursPercent
        ? `${_.round(
            districtDashboardData?.kpiData?.overUnderPlanHoursPercent,
            1,
          )}%`
        : "",
      unit2: "",
    },
  ];

  const defaultColDefEditable = useMemo(() => {
    return {
      sortable: true,
      width: 90,
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      width: 90,
      editable: false,
    };
  }, []);

  const getRowHeight = useCallback(
    (params: RowHeightParams): number | undefined | null =>
      params.data.rowHeight || DEFAULT_ROW_HEIGHT,
    [],
  );

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      if (gridColumns) {
        params.api.sizeColumnsToFit();
      }
    },
    [gridColumns],
  );

  const getGridContainerHeight = (data: any[] = []) => {
    const records = Math.min(data?.length || 1, 10);
    const dynamicHeight = records * 48;
    const staticHeight = 56 + 5;
    return staticHeight + dynamicHeight;
  };

  const renderGrid = (
    gridRef: React.RefObject<AgGridReact>,
    gridId: string,
    rowData: any[],
    headerHeight: number,
    defaultColConf: any,
  ) => (
    <Stack className="ag-theme-alpine" sx={GRID_WRAPPER}>
      <div style={{ height: "100%", width: "100%" }}>
        <AgGridReact
          gridId={gridId}
          ref={gridRef}
          rowData={rowData}
          columnDefs={gridColumns}
          defaultColDef={defaultColConf}
          animateRows={true}
          getRowHeight={getRowHeight}
          headerHeight={headerHeight}
          onGridReady={onGridReady}
          suppressClickEdit={true}
          suppressRowClickSelection={true}
        />
      </div>
    </Stack>
  );

  const renderLoadingSkeleton = () => (
    <Stack spacing={0.25} flex={1}>
      {[...Array(4)].map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={index > 0 ? 48 : 56}
        />
      ))}
    </Stack>
  );

  const renderGridContent = (
    data: any[],
    gridRef: React.RefObject<AgGridReact>,
    gridId: string,
    headerHeight: number,
    defaultColConf: any,
  ) => (
    <>
      {dashboardLoading
        ? renderLoadingSkeleton()
        : renderGrid(gridRef, gridId, data, headerHeight, defaultColConf)}
    </>
  );

  const renderStatisticsWidgets = () => (
    <Stack direction="row" alignItems="flex-start" width="100%" flex={1}>
      {dashboardLoading ? (
        <Stack direction="row" alignItems="center" spacing={1.5} width="100%">
          {[...Array(3)].map((_, index) => (
            <Skeleton
              key={`district-kpi-skeleton-${index}`}
              variant="rounded"
              height={74}
              sx={{ flex: 1 }}
            />
          ))}
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" spacing={1.5} width="100%">
          <StatisticsWidget
            flex={1}
            data={EFFICIENCY_DATA}
            flexValues={[0.75, 0.75, 1]}
          />
          <StatisticsWidget
            flex={1}
            data={ACTUAL_DATA}
            flexValues={[0.75, 0.75, 1]}
          />
        </Stack>
      )}
    </Stack>
  );

  const renderGridTitle = (data: any[]) => (
    <>
      {!dashboardLoading && data?.length > 0 && (
        <Typography
          sx={VP_GRID_TITLE}
        >{`Showing Stores (${data?.length})`}</Typography>
      )}
    </>
  );

  const getTabContent = () => {
    const GRID_CONTAINER: SxProps = {
      ...CONTENT_BOX,
      background: "rgba(187, 239, 255, 0.24)",
      p: 0,
      overflow: "hidden",
      maxHeight: 500,
      width: "100%",
    };
    switch (selectedTab) {
      case 0:
        return (
          <React.Fragment>
            {renderStatisticsWidgets()}

            <Stack spacing={1}>
              {renderGridTitle(districtDashboardData?.dashboardData)}
              <Stack
                sx={{
                  ...GRID_CONTAINER,
                  minHeight: getGridContainerHeight(
                    districtDashboardData?.dashboardData,
                  ),
                }}
              >
                {renderGridContent(
                  districtDashboardData?.dashboardData,
                  dashboardGridRef,
                  "dashboard-grid",
                  28,
                  defaultColDef,
                )}
              </Stack>
            </Stack>

            <Stack flex={1} direction={"column"} height={"100%"} spacing={2}>
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                alignSelf={"flex-end"}
              >
                <CustomSelect
                  label={""}
                  id="weeks"
                  name="weeks"
                  placeholder="Select Week"
                  options={WEEKS_DATA}
                  value={selectedLastWeek.name}
                  onChangeOption={handleLastWeekChange}
                />
              </Stack>
              {chartLoading ? (
                <Skeleton
                  key={`store-chart-skeleton`}
                  variant="rounded"
                  height={360}
                  sx={{ minHeight: 360 }}
                />
              ) : (
                <CustomLineChart
                  data={districtStoreChartData?.data || null}
                  lines={districtStoreChartData?.lines || []}
                  xDataKey="date"
                  yLabel="% Over / -Under Weekly Plan"
                  chartTitle={districtStoreChartData?.chartTitle}
                  chartSubtitle={districtStoreChartData?.chartSubtitle}
                />
              )}
            </Stack>

            {dashboardLoading ? (
              <Skeleton
                key={`store-chart-skeleton`}
                variant="rounded"
                height={350}
                sx={{ minHeight: 350 }}
              />
            ) : (
              <KeyAlerts alerts={keyAlerts} />
            )}
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Stack spacing={1}>
              {renderGridTitle(efficiencyStores)}
              <Stack
                sx={{
                  ...GRID_CONTAINER,
                  minHeight: getGridContainerHeight(efficiencyStores),
                }}
              >
                {renderGridContent(
                  efficiencyStores,
                  effieciencyGridRef,
                  "efficiency-grid",
                  56,
                  defaultColDefEditable,
                )}
              </Stack>
            </Stack>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <Stack spacing={1}>
              {renderGridTitle(planStores)}
              <Stack
                sx={{
                  ...GRID_CONTAINER,
                  minHeight: getGridContainerHeight(planStores),
                }}
              >
                {renderGridContent(
                  planStores,
                  planGridRef,
                  "plan-grid",
                  56,
                  defaultColDef,
                )}
              </Stack>
            </Stack>
          </React.Fragment>
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack spacing={1}>
          <Typography sx={VP_TITLE}>
            {dashboardLoading ? (
              <Skeleton width={206} />
            ) : (
              `Analysis for ${
                districtDashboardData?.kpiData?.districtName || ""
              }`
            )}
          </Typography>
          <Typography sx={VP_SUBTITLE}>
            {dashboardLoading ? (
              <Skeleton width={324} />
            ) : (
              `Here is your analysis for stores in ${
                districtDashboardData?.kpiData?.districtName || ""
              }`
            )}
          </Typography>
        </Stack>
        <Stack
          flex={0.5}
          justifyContent={"flex-end"}
          direction={"row"}
          alignItems={"center"}
        >
          <CustomWeekRangeInput onWeekChange={getSelectedWeek} />
        </Stack>
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: "rgba(6, 30, 35, 0.10)" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="region store tabs"
        >
          <Tab label="Dashboard" value={0} />
          <Tab label="Efficiency" value={1} />
          <Tab label="Plans" value={2} />
        </Tabs>
      </Box>

      <Stack style={{ marginTop: 24, overflow: "auto" }} spacing={2}>
        {getTabContent()}
      </Stack>
    </React.Fragment>
  );
}
