import {
  FormControl,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import PERSON_ACTIVE_ICON from "../../assets/icons/person-active.svg";
import PERSON_ICON from "../../assets/icons/person.svg";
import SEARCH_ICON from "../../assets/icons/search.svg";
import { BootstrapInput } from "../../components/CustomInput";
import { ROUTES } from "../../routes/constants";
import { District } from "../Region/RegionFilter";
import {
  VP_ACCORDION_ITEM,
  VP_ACCORDION_ITEM_TEXT,
  VP_ACCORDION_TEXT,
  VP_FILTER_SUBTITLE,
  VP_FILTER_TITLE,
  VP_LEFT_WRAPPER,
  VP_SEARCH_INPUT,
} from "../Region/styles";
import _ from "lodash";

interface DistrictFilterProps {
  districtData: any[];
  isLoading: boolean;
  onDistrictChange: (district: District | null) => void;
}

export default function DistrictFilter({
  districtData = [],
  isLoading,
  onDistrictChange,
}: DistrictFilterProps) {
  const navigate = useNavigate();
  const params = useParams();
  const romStore = useMatch(ROUTES.ROM_STORES);
  const romKPI = useMatch(ROUTES.ROM);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedDistrictId, setSelectedDistrictId] = useState<any>(null);

  useEffect(() => {
    if (!_.isEmpty(params) && params.districtId) {
      setSelectedDistrictId(parseInt(params.districtId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.districtId]);

  useEffect(() => {
    if (romStore || romKPI) {
      setSearchTerm("");
      setSelectedDistrictId(null);
      if (romStore) {
        onDistrictChange(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [romStore, romKPI]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    setSearchTerm(value);
  };

  const handleDistrictSelect = (district: District) => {
    const districtId = district.districtId;
    setSelectedDistrictId(
      districtId === selectedDistrictId ? null : districtId,
    );
    if (romStore) {
      onDistrictChange(districtId === selectedDistrictId ? null : district);
    } else {
      if (districtId === selectedDistrictId) {
        navigate("..");
      } else {
        navigate(`${ROUTES.ROM}/district/${districtId}`);
      }
    }
  };

  return (
    <Stack sx={VP_LEFT_WRAPPER}>
      <Stack
        padding={"24px 24px 16px 24px"}
        spacing={2}
        sx={{ borderBottom: "1px solid rgba(6, 30, 35, 0.08)" }}
      >
        <Stack spacing={0.5}>
          <Typography sx={VP_FILTER_TITLE}>{"By Districts"}</Typography>
          <Typography sx={VP_FILTER_SUBTITLE}>
            {isLoading ? (
              <Skeleton />
            ) : (
              `Showing ${districtData.length} districts`
            )}
          </Typography>
        </Stack>
        <FormControl variant="outlined">
          <BootstrapInput
            sx={VP_SEARCH_INPUT}
            endAdornment={<img src={SEARCH_ICON} alt="search" />}
            fullWidth
            placeholder="Search for Stores"
            value={searchTerm}
            onChange={onSearch}
          />
        </FormControl>
      </Stack>
      <Stack p={2} spacing={1} overflow={"auto"}>
        {isLoading ? (
          <Stack spacing={1}>
            {[...Array(3)].map((_, index) => (
              <Skeleton
                key={`skeleton-${index}`}
                variant="rounded"
                height={66}
              />
            ))}
          </Stack>
        ) : (
          <List disablePadding>
            {districtData
              ?.filter((district: District) =>
                searchTerm.length > 1
                  ? district.name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  : true,
              )
              .map((district: any, index: number) => (
                <ListItemButton
                  key={district.districtId}
                  sx={VP_ACCORDION_ITEM}
                  selected={district.districtId === selectedDistrictId}
                  onClick={() => {
                    handleDistrictSelect(district);
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={
                        selectedDistrictId === district.districtId
                          ? PERSON_ACTIVE_ICON
                          : PERSON_ICON
                      }
                      alt="person"
                    />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <Stack>
                      <Typography
                        sx={{
                          ...VP_ACCORDION_ITEM_TEXT,
                          color: "#061E23",
                        }}
                      >
                        {district.name}
                      </Typography>
                      <Typography sx={VP_ACCORDION_TEXT}>
                        {district.numberOfStores} Stores
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>
              ))}
          </List>
        )}
      </Stack>
    </Stack>
  );
}
