import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes/constants";
import { CssBaseline } from "@mui/material";

export const AuthLayout = ({ children }: any) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(ROUTES.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <CssBaseline enableColorScheme />
      {children}
    </>
  );
};
