import { useEffect, useState, useCallback, useRef, useContext } from "react";
import { useScheduleGrid } from "./gridHelper";
import { AgGridReact } from "ag-grid-react";
import {
  Typography,
  Skeleton,
  Box,
  Stack,
  Button,
  IconButton,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import _ from "lodash";
import { SelectionChangedEvent } from "ag-grid-community";
import {
  SCHEDULE_TABLE_HEADER_LABEL,
  SCHEDULE_TABLE_HEADER_VALUE,
  SCHEDULE_TABLE_HEADER_UNIT,
  CANCEL_BTN,
  COPY_BTN,
  SAVE_BTN,
  DONE_BTN,
  ADD_BTN,
  ICON_BTN,
  GRID_WRAPPER,
  CONTENT_BOX,
} from "../styles";
import CLOCK_ICON from "../../../assets/icons/Icon-under.svg";
import PRINT_ICON from "../../../assets/icons/print.svg";
import EDIT_ICON from "../../../assets/icons/pencil.svg";
import ADD_ICON from "../../../assets/icons/add.svg";
import CLOSE_ICON from "../../../assets/icons/Icon-close.svg";
import COPY_ICON from "../../../assets/icons/Icon-copy.svg";
import SAVE_ICON from "../../../assets/icons/Icon-save.svg";
import DONE_ICON from "../../../assets/icons/Icon-done.svg";
import INFO_ICON from "../../../assets/icons/Icon-Info.svg";
import DELETE_ICON from "../../../assets/icons/Icon-delete.svg";
import AddEmployeeDialog from "../AddEmployeeDialog";
import CustomSnackbar from "../../../components/CustomSnackbar";
import ConfirmationBox from "../../../components/ConfirmationBox";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../styles/grid.scss";
import { HomeContext } from "../../Home";
import { UserRole } from "../../../utils/constants";
import { useLocalStorage } from "@uidotdev/usehooks";
import { addWeeks, format, isSameWeek, startOfWeek } from "date-fns";
import ScheduleTable from "./ScheduleTable";
import { Close } from "@mui/icons-material";

// Extract constants to avoid repetition
const EMPTY_SKELETON = <Skeleton variant="rectangular" height={35} />;
const INITIAL_ALERT = {
  open: false,
  severity: "success",
  title: "",
  message: "",
};

export default function ScheduleGrid({
  scheduleData,
  isLoading,
  jobs,
  SMDetails,
  storeData,
  fetchPreviousWeekSchedules,
  saveCurrentSchedules,
}: any) {
  const gridRef: any = useRef();
  const [storedWeek] = useLocalStorage("storedWeek", "");

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [openAddEmp, setOpenAddEmp] = useState<boolean>(false);
  const [deletedEmployees, setDeletedEmployees] = useState<string[]>([]);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<any>(INITIAL_ALERT);
  const [showPublish, setShowPublish] = useState<boolean>(false);
  const [showCopy, setShowCopy] = useState<boolean>(false);
  const [openGridModal, setOpenGridModal] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);

  const {
    rowData,
    bottomRowData,
    columnDefs,
    defaultColDef,
    onCellEditingStarted,
    onCellEditingStopped,
    getRowHeight,
    onGridReady,
    convertGridDataToScheduleEmp,
    onNewEmployeeAdd,
  } = useScheduleGrid(isEditing, scheduleData, SMDetails);

  const { currentRole } = useContext(HomeContext);

  const getScheduleGridHeight = (data: any) => {
    const records = Math.min(data?.length || 1, 10);
    const dynamicHeight = records * 55;
    const staticHeight = 78 + 60 + 37 * 4 + 23 + 19;
    return staticHeight + dynamicHeight;
  };

  useEffect(() => {
    if (storedWeek) {
      const { start } = JSON.parse(storedWeek);
      const currentDate = new Date();
      const nextWeek = startOfWeek(addWeeks(currentDate, 1));
      const afterNextWeek = startOfWeek(addWeeks(currentDate, 2));
      const isEqualWeek =
        isSameWeek(new Date(start), nextWeek) ||
        isSameWeek(new Date(start), afterNextWeek);
      setIsEditable(isEqualWeek);
    }
  }, [storedWeek]);

  useEffect(() => {
    if (gridRef && !isEditing) {
      gridRef?.current?.api?.stopEditing();
    }
  }, [gridRef, isEditing]);

  const onEmployeeAdd = (data: any) => {
    onNewEmployeeAdd(data, gridRef);
    setOpenAddEmp(false);
  };

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    var rows = event.api.getSelectedNodes() || [];
    if (rows.length > 0) {
      const dataRows = rows.map((r) => r.data);
      if (dataRows.length > 0) {
        const tempEmployees = dataRows.map((d) => d.employeeName);
        setDeletedEmployees(tempEmployees);
      }
    } else {
      setDeletedEmployees([]);
    }
  }, []);

  const isValidTime = (time: string) => {
    // Regular expression to validate time in "HH:mm" format
    const timeRegex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;
    return time === null || time === "" || timeRegex.test(time);
  };

  const validateScheduleData = (scheduleData: any) => {
    for (const employee of scheduleData) {
      for (const schedule of employee.scheduleHours) {
        if (!isValidTime(schedule.inTime) || !isValidTime(schedule.outTime)) {
          setShowAlert({
            open: true,
            severity: "warning",
            title: "Invalid",
            message: "Please enter valid time values between 00:00 and 23:59.",
          });
          return false; // Validation failed, return false
        }
      }
    }
    return true; // All validations passed
  };

  const handleSave = async (isPublished = false) => {
    try {
      const payload = convertGridDataToScheduleEmp();

      // Validate schedule data before saving
      if (!validateScheduleData(payload)) {
        return; // Exit the function if validation fails
      }

      const tempSchedules = _.cloneDeep(scheduleData);

      if (payload) {
        tempSchedules.scheduleEmployees = payload;
      }

      if (deletedEmployees.length > 0) {
        tempSchedules.deletedEmployees = deletedEmployees;
      }

      if (isPublished) {
        tempSchedules.isPublished = true;
      } else {
        tempSchedules.isPublished = false;
      }

      await saveCurrentSchedules(tempSchedules);
      setShowAlert({
        open: true,
        severity: "success",
        title: "Success",
        message: "The operation was successful!",
      });
      setDeletedEmployees([]);
      setIsEditing(false);
    } catch (error) {
      setIsEditing(false);
    }
  };

  const onSaveDraft = async () => {
    await handleSave();
  };

  const onPublish = async () => {
    setShowPublish(false);
    await handleSave(true);
  };

  const onDelete = async () => {
    setShowDelete(false);
    await handleSave();
  };

  const onCopyPrevious = async () => {
    setShowCopy(false);
    await fetchPreviousWeekSchedules();
  };

  const getTableTitleData = () => {
    const storeCode = storeData?.storeCode || "";
    const tempWeekRange = JSON.parse(storedWeek);
    const weekRange = `${format(
      new Date(tempWeekRange.start),
      "yyyy-MM-dd",
    )} - ${format(new Date(tempWeekRange.end), "yyyy-MM-dd")}`;
    const fileName = `schedule_${storeCode}_${weekRange}.pdf`;
    return {
      storeCode,
      weekRange,
      fileName,
    };
  };

  const onPrint = useCallback(async () => {
    setOpenGridModal(true);

    // Wait for the scrolling to complete
    await new Promise((resolve) => setTimeout(resolve, 500));

    window.print();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDialogTitle = () => {
    const data = getTableTitleData();
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">{`Store #${data.storeCode}`}</Typography>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography variant="h6">{`Week Range: ${data.weekRange}`}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenGridModal(false)}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack
      sx={{
        ...CONTENT_BOX,
        background: "rgba(187, 239, 255, 0.24)",
        p: 0,
        minHeight: getScheduleGridHeight(rowData),
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"16px 24px"}
      >
        <Stack direction={"row"} spacing={3}>
          <img src={CLOCK_ICON} alt="clock" />
          {[...Array(3)].map((_, index) => (
            <Stack
              key={index}
              height={46}
              borderRight="1px solid #ECECEE"
              paddingRight="20px"
            >
              <Typography sx={SCHEDULE_TABLE_HEADER_LABEL}>
                {isLoading ? (
                  <Skeleton width={120} />
                ) : (
                  [
                    "Plan Hours",
                    "Total Scheduled Hours",
                    "Over/(Under) Plan Hours",
                  ][index]
                )}
              </Typography>
              <Typography component="div" sx={SCHEDULE_TABLE_HEADER_VALUE}>
                {isLoading ? (
                  EMPTY_SKELETON
                ) : (
                  <>
                    {
                      [
                        parseFloat(
                          scheduleData?.totalWeeklyRecommendedHoursText || 0,
                        ).toFixed(1),
                        parseFloat(
                          scheduleData?.totalWeeklyHoursText || 0,
                        ).toFixed(1),
                        parseFloat(
                          scheduleData?.totalWeeklyOverUnderRecommendedHoursText ||
                            0,
                        ).toFixed(1),
                      ][index]
                    }{" "}
                    <Box sx={SCHEDULE_TABLE_HEADER_UNIT}>Hrs</Box>
                  </>
                )}
              </Typography>
            </Stack>
          ))}

          {!isLoading && (
            <Stack spacing={1}>
              {scheduleData?.isPublished ? (
                <Chip
                  label="Published"
                  size="small"
                  sx={{
                    fontWeight: 500,
                    background: "#33B000",
                    color: "#FFFFFF",
                  }}
                />
              ) : (
                <Chip
                  label="In Draft"
                  size="small"
                  sx={{
                    fontWeight: 500,
                    background: "#009FF3",
                    color: "#FFFFFF",
                  }}
                />
              )}
              <Stack direction={"row"} spacing={0.5}>
                <img src={INFO_ICON} alt="info" width={18} height={18} />
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "rgba(6, 30, 35, 0.84)",
                  }}
                >
                  {"24 Hrs Time Format"}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        {!isLoading &&
        isEditable &&
        currentRole &&
        (currentRole === UserRole.StoreManager ||
          currentRole === UserRole.DistrictManagers) ? (
          scheduleData?.isPublished ? (
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              <img src={INFO_ICON} alt="info" width={18} height={18} />
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "rgba(6, 30, 35, 0.84)",
                }}
              >
                {"Caution: You cannot edit the published schedule."}
              </Typography>
              <IconButton aria-label="print" sx={ICON_BTN} onClick={onPrint}>
                <img src={PRINT_ICON} alt="print" />
              </IconButton>
            </Stack>
          ) : deletedEmployees.length > 0 ? (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#061E23",
                }}
              >{`${deletedEmployees.length} Employee(s) Selected`}</Typography>
              <IconButton
                aria-label="delete"
                sx={ICON_BTN}
                style={{ background: "#FFFFFF", border: "1px solid #ECECEE" }}
                onClick={() => setShowDelete(true)}
              >
                <img src={DELETE_ICON} alt="delete" />
              </IconButton>
            </Stack>
          ) : isEditing ? (
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              {[CANCEL_BTN, COPY_BTN, SAVE_BTN, DONE_BTN].map((btn, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  startIcon={
                    <img
                      src={[CLOSE_ICON, COPY_ICON, SAVE_ICON, DONE_ICON][index]}
                      alt=""
                    />
                  }
                  sx={btn}
                  onClick={() => {
                    if (index === 0) {
                      setShowCancel(true); // Handle "Close" button click
                    } else if (index === 1) {
                      setShowCopy(true); // Handle "Copy Previous Week" button click
                    } else if (index === 2) {
                      onSaveDraft(); // Handle "Save Draft" button click
                    } else if (index === 3) {
                      setShowPublish(true); // Handle "Publish" button click
                    }
                  }}
                >
                  {
                    ["Close", "Copy Previous Week", "Save Draft", "Publish"][
                      index
                    ]
                  }
                </Button>
              ))}
            </Stack>
          ) : (
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Button
                variant="outlined"
                startIcon={<img src={ADD_ICON} alt="add" />}
                sx={ADD_BTN}
                onClick={() => setOpenAddEmp(true)}
              >
                Add Employee
              </Button>
              <IconButton
                aria-label="edit"
                sx={ICON_BTN}
                onClick={() => setIsEditing(true)}
              >
                <img src={EDIT_ICON} alt="edit" />
              </IconButton>
              <IconButton aria-label="print" sx={ICON_BTN} onClick={onPrint}>
                <img src={PRINT_ICON} alt="print" />
              </IconButton>
            </Stack>
          )
        ) : (
          !isLoading && (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <IconButton aria-label="print" sx={ICON_BTN} onClick={onPrint}>
                <img src={PRINT_ICON} alt="print" />
              </IconButton>
            </Stack>
          )
        )}
      </Stack>
      {isLoading ? (
        <Stack spacing={0.25} flex={1}>
          {[...Array(9)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={index < 5 ? 55 : 37}
            />
          ))}
        </Stack>
      ) : (
        <Stack className="ag-theme-alpine" sx={GRID_WRAPPER}>
          {scheduleData && (
            <div style={{ height: "100%", width: "100%" }} id={"schedule-grid"}>
              <AgGridReact
                gridId={"schedule-grid"}
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection="multiple" // Options - allows click selection of rows
                pinnedBottomRowData={bottomRowData}
                getRowHeight={getRowHeight}
                headerHeight={30}
                singleClickEdit={true}
                stopEditingWhenCellsLoseFocus={true}
                suppressRowClickSelection={true}
                onCellEditingStarted={onCellEditingStarted}
                onCellEditingStopped={onCellEditingStopped}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged}
              />
            </div>
          )}
        </Stack>
      )}

      <AddEmployeeDialog
        openAddEmp={openAddEmp}
        positions={jobs}
        onAddEmployeeClose={() => setOpenAddEmp(false)}
        onEmployeeAdd={onEmployeeAdd}
      />

      <ConfirmationBox
        openDialog={showDelete}
        onCloseDialog={() => setShowDelete(false)}
        onConfirm={onDelete}
        title="Are you sure you want to delete this user?"
        content="This will delete this user permanently. You cannot undo this action."
        severity="error"
      />

      <ConfirmationBox
        openDialog={showPublish}
        onCloseDialog={() => setShowPublish(false)}
        onConfirm={onPublish}
        title="Are you sure you want to publish the schedule?"
        content="Once the schedule is published, any modifications are not allowed."
        severity="success"
      />

      <ConfirmationBox
        openDialog={showCopy}
        onCloseDialog={() => setShowCopy(false)}
        onConfirm={onCopyPrevious}
        title="Are you sure you want to copy previous week schedule?"
        content="Once the schedule is copied, any existing entries in the current schedule will be overwritten."
        severity="warning"
      />

      <ConfirmationBox
        openDialog={showCancel}
        onCloseDialog={() => setShowCancel(false)}
        onConfirm={() => {
          setIsEditing(false);
          setShowCancel(false);
        }}
        title="Are you sure you want to proceed with canceling the schedule editing?"
        content="If you don't save or publish, you'll lose any changes you made. Make sure to save or publish to keep your edits!"
        severity="warning"
      />

      <CustomSnackbar
        open={showAlert.open}
        onClose={() => setShowAlert(INITIAL_ALERT)}
        severity={showAlert.severity}
        title={showAlert.title}
        message={showAlert.message}
      />
      <Dialog
        fullScreen
        scroll="paper"
        open={openGridModal}
        onClose={() => setOpenGridModal(false)}
        id="schedule-modal"
        slotProps={{ backdrop: { sx: { background: "#FFF" } } }}
      >
        <DialogTitle>{renderDialogTitle()}</DialogTitle>
        <DialogContent sx={{ display: "flex", flex: 1 }}>
          {!_.isEmpty(rowData) && <ScheduleTable data={rowData} />}
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
