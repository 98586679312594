import { SxProps } from "@mui/material";

export const TITLE: SxProps = {
  fontSize: 20,
  fontWeight: 500,
  lineHeight: "20px",
  color: "#061E23",
};

export const CONTENT_TEXT: SxProps = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "20px",
  color: "rgba(6, 30, 35, 0.80)",
};

export const WIDGET_WRAPPER: SxProps = {
  flexDirection: "row",
  alignItems: "center",
  gap: 3,
  padding: "12px 24px",
  borderRadius: "8px",
  background: "rgba(255, 228, 172, 0.74)",
  border: "1px solid #FFE4AC",
};

export const WIDGET_TITLE: SxProps = {
  fontSize: 12,
  fontWeight: 500,
  color: "#061E23",
};

export const WIDGET_VALUE: SxProps = {
  fontSize: 24,
  fontWeight: 500,
  color: "#061E23",
};

export const WIDGET_UNIT: SxProps = {
  display: "inline",
  fontSize: 12,
  fontWeight: 400,
  color: "#061E23",
};

export const CONTENT_BOX: SxProps = {
  padding: 3,
  borderRadius: "8px",
  border: "1px solid #59D8FF",
  background: "#FFFFFF",
  flex: 1,
  minHeight: 260,
};

export const CONTENT_BOX_TITLE: SxProps = {
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
};

export const CONTENT_BOX_SUBTITLE: SxProps = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "21px",
  color: "#595959",
};

export const LIST_PRIMARY_TEXT: SxProps = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "24px",
  color: "#0F3D61",
};

export const LIST_SECONDARY_TEXT: SxProps = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "21px",
  color: "#595959",
};

export const SWITCH_LABEL: any = {
  fontWeight: 400,
  fontSize: 12,
  lineHeight: "20px",
  color: "#061E23",
};

export const TABLE_ROW_WRAPPER: SxProps = {
  border: 0,
  borderTop: "1px solid rgba(6, 30, 35, 0.10)",
  "&:last-child td, &:last-child th": { borderBottom: 0 },
};

export const TABLE_CELL_WRAPPER: SxProps = {
  padding: "8px 8px 8px 12px",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "24px",
  color: "rgba(6, 30, 35, 0.80)",
  border: 0,
  borderRight: "1px solid rgba(6, 30, 35, 0.10)",
  "&:last-child": { borderRight: 0 },
};

export const CHIP_WRAPPER: SxProps = {
  display: "flex",
  alignItems: "center",
  borderRadius: "4px",
  background: "#F9F9FA",
  fontSize: 11,
  fontWeight: 500,
  lineHeight: "24px",
  color: "rgba(6, 30, 35, 0.80)",
  margin: "0 16px 16px 0",
};

export const SCHEDULE_TABLE_HEADER_LABEL: SxProps = {
  fontWeight: 500,
  fontSize: 12,
  color: "#595959",
};

export const SCHEDULE_TABLE_HEADER_VALUE: SxProps = {
  fontSize: 20,
  fontWeight: 500,
  color: "#032E45",
};

export const SCHEDULE_TABLE_HEADER_UNIT: SxProps = {
  display: "inline",
  fontSize: 12,
  fontWeight: 400,
  color: "#383838",
};

export const ICON_BTN: SxProps = {
  width: 32,
  height: 32,
  padding: "6px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  border: "1px solid #00BDFC",
  background: "rgba(187, 239, 255, 0.60)",
  "&:hover": {
    border: "1px solid #00BDFC",
    background: "rgba(187, 239, 255, 0.60)",
  },
};

export const ADD_BTN: SxProps = {
  borderRadius: "4px",
  height: 32,
  border: "1px solid #9FDB44",
  background: "#D4F1B3",
  textTransform: "capitalize",
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
  boxShadow: "none",
  padding: "4px 12px 4px 8px",
  "&:hover": {
    border: "1px solid #9FDB44",
    background: "#D4F1B3",
  },
};

export const CANCEL_BTN: SxProps = {
  borderRadius: "4px",
  height: 32,
  border: "1px solid rgba(6, 30, 35, 0.10)",
  background: "#FFFFFF",
  textTransform: "capitalize",
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#808083",
  boxShadow: "none",
  padding: "4px 12px 4px 8px",
  "&:hover": {
    border: "1px solid rgba(6, 30, 35, 0.10)",
    background: "#FFFFFF",
  },
};

export const COPY_BTN: SxProps = {
  borderRadius: "4px",
  height: 32,
  border: "1px solid rgba(6, 30, 35, 0.13)",
  background: "rgba(6, 30, 35, 0.05)",
  textTransform: "capitalize",
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
  boxShadow: "none",
  padding: "4px 12px 4px 8px",
  "&:hover": {
    border: "1px solid rgba(6, 30, 35, 0.13)",
    background: "rgba(6, 30, 35, 0.05)",
  },
};

export const SAVE_BTN: SxProps = {
  borderRadius: "4px",
  height: 32,
  border: "1px solid #009FF3",
  background: "#009FF3",
  textTransform: "capitalize",
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#FFFFFF",
  boxShadow: "none",
  padding: "4px 12px 4px 8px",
  "&:hover": {
    border: "1px solid #009FF3",
    background: "#009FF3",
  },
};

export const DONE_BTN: SxProps = {
  borderRadius: "4px",
  height: 32,
  border: "1px solid #33B000",
  background: "#33B000",
  textTransform: "capitalize",
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#FFFFFF",
  boxShadow: "none",
  padding: "4px 12px 4px 8px",
  "&:hover": {
    border: "1px solid #33B000",
    background: "#33B000",
  },
};

export const MODAL_CANCEL_BTN: SxProps = {
  borderRadius: "4px",
  height: 40,
  border: "none",
  background: "rgba(6, 30, 35, 0.16)",
  textTransform: "capitalize",
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "20px",
  color: "#061E23",
  boxShadow: "none",
  padding: "10px 16px",
  letterSpacing: "0.07px",
  "&:hover": {
    border: "none",
    background: "rgba(6, 30, 35, 0.16)",
  },
};

export const MODAL_SUCCESS_BTN: SxProps = {
  borderRadius: "4px",
  height: 40,
  border: "none",
  background: "#006636",
  textTransform: "capitalize",
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "20px",
  color: "#FFF",
  boxShadow: "none",
  padding: "10px 16px",
  letterSpacing: "0.07px",
  cursor: "pointer",
  "&:hover": {
    border: "none",
    background: "#006636",
  },
  "&:disabled": {
    color: "#FFF",
    opacity: 0.5,
    cursor: "not-allowed",
  },
};

export const GRID_WRAPPER: SxProps = {
  width: "100%",
  borderRadius: "8px",
  borderTop: "1px solid #CEE8EF",
  borderBottom: "1px solid #CEE8EF",
  background: "#FFFFFF",
  flex: 1,
};

export const DM_STORE_LIST_RIGHT_TEXT: SxProps = {
  fontSize: 11,
  fontWeight: 400,
  lineHeight: "10px",
  color: "#061E23CC",
};

export const BACK: SxProps = {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#204EC4",
};

export const TARGET_VALUE_WRAPPER: SxProps = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "8px 16px",
  gap: 1,
  borderRadius: "4px",
  border: "1px solid rgba(6, 30, 35, 0.10)",
  background: "#FFFFFF",
};

export const TARGET_VALUE: SxProps = {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "24px",
  color: "rgba(6, 30, 35, 0.74)",
};
