import { Stack, Typography } from "@mui/material";

const OTRender = (params: any) => {
  const { data } = params;
  const { scheduleHoursText, otHoursText } = data;

  let renderedValue = "-";
  if (
    typeof scheduleHoursText === "string" &&
    !isNaN(parseFloat(scheduleHoursText))
  ) {
    renderedValue = parseFloat(scheduleHoursText).toFixed(1);
  }

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={1}
    >
      <Stack spacing={0.75}>
        <span>{renderedValue}</span>
        {otHoursText && (
          <Typography
            sx={{
              fontSize: 11,
              color: "#061E23",
              padding: "3px 4px",
              borderRadius: "2px",
              background: "#FFE4AC",
              lineHeight: 1,
            }}
          >
            {`${parseFloat(otHoursText).toFixed(1)} (OT)`}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default OTRender;
