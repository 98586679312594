import {
  FormControl,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import CLOSE_ICON from "../../../assets/icons/Icon-close.svg";

import { useNavigate } from "react-router-dom";
import SEARCH_ICON from "../../../assets/icons/search.svg";
import { BootstrapInput } from "../../../components/CustomInput";
import { ROUTES } from "../../../routes/constants";
import { StoreTable } from "../../Stores/StoreTable";
import {
  STORES_HEADER_WRAPPER,
  STORES_SEARCH_INPUT,
} from "../../Stores/styles";
import { useDistrictStores } from "./districtStoresHelpe";
import PERSON_IMG from "../../../assets/images/person-blue.png";
import { TITLE } from "../../Store/styles";

const DistrictsStores = () => {
  const navigate = useNavigate();
  const {
    dmStores,
    searchTerm,
    isDistrictLoading,
    handleClearSearch,
    onSearch,
  } = useDistrictStores();

  const STORES_WRAPPER: SxProps = {
    background: "#FFFFFF",
  };
  return (
    <Stack flex={1} spacing={3} sx={STORES_WRAPPER}>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <FormControl variant="outlined" sx={{ width: 357, marginTop: 2 }}>
          <BootstrapInput
            sx={STORES_SEARCH_INPUT}
            endAdornment={
              searchTerm ? (
                <IconButton
                  size="small"
                  sx={{ padding: "0 4px", background: "transparent" }}
                  onClick={handleClearSearch}
                >
                  <img src={CLOSE_ICON} alt="search" />
                </IconButton>
              ) : (
                <img src={SEARCH_ICON} alt="search" />
              )
            }
            fullWidth
            placeholder="Search for store, email, address"
            value={searchTerm}
            onChange={onSearch}
          />
        </FormControl>
      </Stack>

      <Stack flex={1}>
        <Stack sx={STORES_HEADER_WRAPPER}>
          {dmStores && dmStores.length > 0 && (
            <Stack direction={"row"} alignItems={"center"} spacing={2} flex={1}>
              <img src={PERSON_IMG} alt="person" width={40} />
              <Stack spacing={0.5}></Stack>
              <Stack>
                <Typography sx={TITLE}>
                  {(dmStores[0] as any)?.districtManagerName || ""}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        <StoreTable
          rows={dmStores}
          isLoading={isDistrictLoading}
          onRedirect={(storeId: number) => {
            if (storeId) {
              navigate(`${ROUTES.DISTRICT_STORE}/${storeId}`, {
                state: { isFromDistrict: true },
              });
            }
          }}
        />
      </Stack>
    </Stack>
  );
};

export default DistrictsStores;
