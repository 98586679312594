import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { BACKDROP_WRAPPER, LOADING_WRAPPER } from "../styles/styles";

export const Spinner = ({ ...props }) => {
  return (
    <Backdrop
      open={true}
      {...props}
      sx={BACKDROP_WRAPPER}
      style={{ margin: 0 }}
    >
      <Box sx={LOADING_WRAPPER}>
        <CircularProgress color="inherit" />
        <Typography color={"inherit"} variant="h6">
          {"Loading data..."}
        </Typography>
      </Box>
    </Backdrop>
  );
};
