import { ChangeEvent, useEffect, useState } from "react";
import romService from "../../../services/romService";
import { District } from "../../Region/RegionFilter";

export const useRomStores = (selectedDistrict: District) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showCreateStore, setShowCreateStore] = useState<boolean>(false);
  const [rmStores, setRmStores] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e?.target?.value || "");
  };

  const handleCreateStoreClose = () => {
    setShowCreateStore(false);
  };

  const getRomStores = async (newPage: number) => {
    try {
      setIsLoading(true);
      const reqPayload = {
        districtId: selectedDistrict?.districtId,
        regionId: selectedDistrict?.regionId,
        take: rowsPerPage,
        skip: newPage * rowsPerPage,
        searchTerm,
      };

      await romService
        .getRomStores(reqPayload)
        .then(async (response) => {
          if (response?.data) {
            setRmStores(response?.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching stores:", error);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("[getRomStores]: ", error);
    }
  };

  const onSubmit = async () => {
    handleCreateStoreClose();
    await getRomStores(0);
  };

  const handleOpenCreateStore = () => {
    setShowCreateStore(true);
  };

  useEffect(() => {
    if (searchTerm.length > 3 || searchTerm.length === 0) {
      getRomStores(page > 0 ? page : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, selectedDistrict, rowsPerPage, page]);

  return {
    isLoading,
    rmStores,
    searchTerm,
    showCreateStore,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClearSearch,
    onSearch,
    handleOpenCreateStore,
    handleCreateStoreClose,
    onSubmit,
  };
};
