import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  SxProps,
  styled,
  tableCellClasses,
} from "@mui/material";

const TABLE_WRAPPER: SxProps = {
  minWidth: 650,
  border: "none",
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFF",
    color: "rgba(3, 46, 69, 0.80)",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "21px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    textAlign: "center",
    maxWidth: 64,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "rgba(249, 249, 250, 0.54)",
    color: "rgba(3, 46, 69, 0.80)",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "21px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    textAlign: "center",
    maxWidth: 64,
  },
}));

const ScheduleTable = ({ data }: any) => {
  return (
    <TableContainer component={Paper} sx={TABLE_WRAPPER} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ maxWidth: 120 }}>
              Employee
            </StyledTableCell>
            <StyledTableCell style={{ maxWidth: 120 }}>
              Position
            </StyledTableCell>
            <StyledTableCell colSpan={3}>Sunday</StyledTableCell>
            <StyledTableCell colSpan={3}>Monday</StyledTableCell>
            <StyledTableCell colSpan={3}>Tuesday</StyledTableCell>
            <StyledTableCell colSpan={3}>Wednesday</StyledTableCell>
            <StyledTableCell colSpan={3}>Thursday</StyledTableCell>
            <StyledTableCell colSpan={3}>Friday</StyledTableCell>
            <StyledTableCell colSpan={3}>Saturday</StyledTableCell>
            <StyledTableCell>Weekly Total</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>In</StyledTableCell>
            <StyledTableCell>Out</StyledTableCell>
            <StyledTableCell>Hrs</StyledTableCell>
            <StyledTableCell>In</StyledTableCell>
            <StyledTableCell>Out</StyledTableCell>
            <StyledTableCell>Hrs</StyledTableCell>
            <StyledTableCell>In</StyledTableCell>
            <StyledTableCell>Out</StyledTableCell>
            <StyledTableCell>Hrs</StyledTableCell>
            <StyledTableCell>In</StyledTableCell>
            <StyledTableCell>Out</StyledTableCell>
            <StyledTableCell>Hrs</StyledTableCell>
            <StyledTableCell>In</StyledTableCell>
            <StyledTableCell>Out</StyledTableCell>
            <StyledTableCell>Hrs</StyledTableCell>
            <StyledTableCell>In</StyledTableCell>
            <StyledTableCell>Out</StyledTableCell>
            <StyledTableCell>Hrs</StyledTableCell>
            <StyledTableCell>In</StyledTableCell>
            <StyledTableCell>Out</StyledTableCell>
            <StyledTableCell>Hrs</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((employee: any, index: number) => {
            if (employee) {
              return (
                <TableRow key={index}>
                  <StyledTableCell style={{ maxWidth: 140, textAlign: "left" }}>
                    {employee?.employeeName || "-"}
                  </StyledTableCell>
                  <StyledTableCell style={{ maxWidth: 140, textAlign: "left" }}>
                    {employee?.jobName || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.sunday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.sunday?.inTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.sunday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.sunday?.outTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell style={{ background: "#f2f2f5" }}>
                    {employee?.sunday?.clockHoursText || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.monday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.monday?.inTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.monday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.monday?.outTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell style={{ background: "#f2f2f5" }}>
                    {employee?.monday?.clockHoursText || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.tuesday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.tuesday?.inTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.tuesday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.tuesday?.outTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell style={{ background: "#f2f2f5" }}>
                    {employee?.tuesday?.clockHoursText || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.wednesday?.carryOverHoursInMinutesText !==
                        null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.wednesday?.inTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.wednesday?.carryOverHoursInMinutesText !==
                        null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.wednesday?.outTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell style={{ background: "#f2f2f5" }}>
                    {employee?.wednesday?.clockHoursText || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.thursday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.thursday?.inTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.thursday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.thursday?.outTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell style={{ background: "#f2f2f5" }}>
                    {employee?.thursday?.clockHoursText || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.friday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.friday?.inTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.friday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.friday?.outTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell style={{ background: "#f2f2f5" }}>
                    {employee?.friday?.clockHoursText || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.saturday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.saturday?.inTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        employee?.saturday?.carryOverHoursInMinutesText !== null
                          ? "#FF3130"
                          : "rgba(3, 46, 69, 0.80)",
                    }}
                  >
                    {employee?.saturday?.outTime || "-"}
                  </StyledTableCell>
                  <StyledTableCell style={{ background: "#f2f2f5" }}>
                    {employee?.saturday?.clockHoursText || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      background:
                        employee?.weeklyTotalHoursText &&
                        parseFloat(employee?.weeklyTotalHoursText) > 40
                          ? "#FFE4AC"
                          : "#FFFFFFF",
                    }}
                  >
                    {employee?.weeklyTotalHoursText || "-"}
                  </StyledTableCell>
                </TableRow>
              );
            } else {
              return (
                <TableRow key={index}>
                  <StyledTableCell style={{ maxWidth: 140, textAlign: "left" }}>
                    {"No data available"}
                  </StyledTableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScheduleTable;
