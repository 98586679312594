import { useLocalStorage } from "@uidotdev/usehooks";
import { format } from "date-fns";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import districtService from "../../services/districtService";
import romService from "../../services/romService";
import storeService from "../../services/storeService";
import { getFullAddressFromStoreDetails } from "../../utils/helpers";

export const useStore = () => {
  const { storeId } = useParams();
  const { state } = useLocation();
  const isFromDistrict = state ? state.isFromDistrict : false;
  const isFromROM = state ? state.isFromROM : false;
  const isFromVP = state ? state.isFromVP : false;
  const [storeDetails, setStoreDetails] = useState<any>(null);
  const [isStoreUpdate, setIsStoreUpdate] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState<any>(null);
  const [dashboardChartData, setDashboardChartData] = useState<any>(null);
  const [storeKPIData, setStoreKPIData] = useState<any>(null);
  const [storeAlertsData, setStoreAlertsData] = useState<any>(null);
  const [jobs, setJobs] = useState<any>([]);
  const [scheduleData, setScheduleData] = useState<any>(null);
  const [scheduleChartData, setScheduleChartData] = useState<any>(null);

  const [isStoreLoading, setIsStoreLoading] = useState<boolean>(false);
  const [isDashboardLoading, setIsDashboardLoading] = useState<boolean>(false);
  const [isScheduleLoading, setIsScheduleLoading] = useState<boolean>(false);
  const [weeklyDemandStaffingData, setWeeklyDemandStaffingData] =
    useState<any>(null);
  const [weeklyEfficiencytrendsData, setWeeklyEfficiencytrendsData] =
    useState<any>(null);
  const [daywiseEfficiencytrendsData, setDaywiseEfficiencytrendsData] =
    useState<any>(null);
  const [efficiencyTarget, setEfficiencyTarget] = useState<any>(null);
  const [forecastAccuracy, setForecastAccuracy] = useState<any>(null);
  // eslint-disable-next-line
  const [storedWeek, setStoredWeek] = useLocalStorage("storedWeek", "");
  const [SMDetails, setSMDetails] = useState<any>(null);

  const getStoreByEmail = async () => {
    setIsStoreLoading(true);
    setIsDashboardLoading(true);
    try {
      // Fetch logged in user store
      await storeService
        .getStore()
        .then(async (response) => {
          if (response?.data) {
            setStoreDetails(response?.data);
            setIsStoreLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching store:", error);
          setIsStoreLoading(false);
        });
    } catch (error) {
      console.error("[getStoreByEmail]: ", error);
      setIsStoreLoading(false);
    }
  };

  const getStoreByStoreId = async (storeId: string) => {
    setIsStoreLoading(true);
    setIsDashboardLoading(true);
    try {
      // Fetch logged in user store
      await storeService
        .getStoreById(storeId)
        .then(async (response) => {
          if (response?.data) {
            setStoreDetails(response?.data);
            setIsStoreLoading(false);
            setIsStoreUpdate(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching store:", error);
          setIsStoreLoading(false);
          setIsStoreUpdate(false);
        });
    } catch (error) {
      console.error("[getStoreByStoreId]: ", error);
      setIsStoreLoading(false);
      setIsStoreUpdate(false);
    }
  };

  const fetchJobs = async () => {
    try {
      // Fetch Jobs
      await storeService
        .getJobs()
        .then((response) => {
          if (response?.data && response.data?.length > 0) {
            setJobs(response?.data);

            const smJobData =
              response?.data?.length > 0 &&
              _.find(
                response?.data,
                (j) =>
                  j.name.toLowerCase().replaceAll(/\s/g, "") === "storemanager",
              );
            setSMDetails({
              name: storeDetails?.sm1name || "",
              jobId: smJobData?.jobId || "",
              jobName: smJobData?.name || "",
            });
          }
        })
        .catch((error) => {
          // Handle errors specific to this component
          console.error("Error fetching jobs:", error);
        });
    } catch (error) {}
  };

  const getHoursByWeek = async (storeId: number, startWeek: string) => {
    try {
      // Fetch Store
      await storeService
        .getHoursByWeek(storeId, startWeek)
        .then((response) => {
          if (response?.data) {
            setDashboardChartData(response?.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching hours:", error);
        });
    } catch (error) {
      console.error("[getHoursByWeek]: ", error);
    }
  };

  const getKPIByWeek = async (storeId: number, startWeek: string) => {
    try {
      // Fetch Store
      await storeService
        .getStoreKPIByWeek(storeId, startWeek)
        .then((response) => {
          if (response?.data) {
            setStoreKPIData(response?.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching KPIs:", error);
        });
    } catch (error) {
      console.error("[getKPIByWeek]: ", error);
    }
  };

  const getAlertsByWeek = async (startWeek: string) => {
    try {
      // Fetch Store
      await storeService
        .getAlertsByWeek(startWeek)
        .then((response) => {
          if (response?.data) {
            setStoreAlertsData(response?.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching KPIs:", error);
        });
    } catch (error) {
      console.error("[getKPIByWeek]: ", error);
    }
  };

  const fetchSchedulesByStoreId = async (storeId: number, week: string) => {
    try {
      // Fetch Schedules
      await storeService
        .getSchedules(storeId, week)
        .then((response) => {
          if (response?.data) {
            setScheduleData(response?.data);
          }
        })
        .catch((error) => {
          // Handle errors specific to this component
          console.error("Error fetching schedules:", error);
        });
    } catch (error) {}
  };

  const fetchPreviousWeekSchedules = async () => {
    setIsScheduleLoading(true);
    try {
      // Fetch Schedules
      await storeService
        .getPreviousWeekSchedules({
          storeId: storeDetails.storeId,
          scheduleDate: selectedWeek,
        })
        .then((response) => {
          if (response?.data) {
            setScheduleData(response?.data);
          }
          setIsScheduleLoading(false);
        })
        .catch((error) => {
          // Handle errors specific to this component
          console.error("Error fetching schedules:", error);
          setIsScheduleLoading(false);
        });
    } catch (error) {
      setIsScheduleLoading(false);
    }
  };

  const saveCurrentSchedules = async (payload: any) => {
    setIsScheduleLoading(true);
    try {
      // Save Schedules
      let requestPayload = null;
      if (payload?.scheduleDays?.length > 0) {
        const tempDays = _.clone(payload?.scheduleDays);

        tempDays[0].scheduleDate = selectedWeek;
        requestPayload = {
          ...payload,
          scheduleDays: tempDays,
        };
      }
      await storeService
        .saveSchedules(requestPayload)
        .then(async (response) => {
          if (response?.data) {
            await fetchScheduleChartData();
            setScheduleData(response?.data);
            await getHoursByWeek(storeDetails.storeId, selectedWeek);
            await getKPIByWeek(storeDetails.storeId, selectedWeek);
            await getAlertsByWeek(selectedWeek);
            if (isFromDistrict || isFromROM || isFromVP) {
              let serviceInstance: any = storeService;
              if (isFromROM) {
                serviceInstance = romService;
              } else if (isFromVP) {
                serviceInstance = storeService;
              } else {
                serviceInstance = districtService;
              }
              await fetchEfficiencyTarget(serviceInstance);
              await fetchWeeklyDemandStaffingData(serviceInstance);
              await fetchWeeklyEfficiencytrends(serviceInstance);
              await fetchDayWiseEfficiencytrends(serviceInstance);
            }
            setTimeout(() => {
              setIsScheduleLoading(false);
            }, 1000);
          } else {
            setIsScheduleLoading(false);
          }
        })
        .catch((error) => {
          // Handle errors specific to this component
          console.error("Error fetching schedules:", error);
          setIsScheduleLoading(false);
        });
    } catch (error) {
      setIsScheduleLoading(false);
    }
  };

  const fetchScheduleChartData = async () => {
    try {
      // Fetch Schedules Chart Data
      await storeService
        .getScheduleChartData({
          storeId: storeDetails.storeId,
          scheduleDate: selectedWeek,
        })
        .then((response) => {
          if (response?.data) {
            setScheduleChartData(response?.data);
          }
        })
        .catch((error) => {
          // Handle errors specific to this component
          console.error("Error fetching chart data:", error);
        });
    } catch (error) {}
  };

  const fetchEfficiencyTarget = async (serviceInstance: any) => {
    try {
      const response = await serviceInstance.getEfficiencyTarget(
        storeDetails.storeId,
        selectedWeek,
      );
      if (response?.data) {
        setEfficiencyTarget(response?.data);
      }
    } catch (error) {
      console.error("Error [fetchEfficiencyTarget]:", error);
    }
  };

  const fetchWeeklyDemandStaffingData = async (serviceInstance: any) => {
    try {
      const response = await serviceInstance.getWeeklyDemandStaffingData(
        storeDetails.storeId,
        selectedWeek,
      );
      if (response?.data) {
        setWeeklyDemandStaffingData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching weekly demand staffing chart data:", error);
    }
  };

  const fetchWeeklyEfficiencytrends = async (serviceInstance: any) => {
    try {
      const response = await serviceInstance.getWeeklyEfficiencyTreadsData(
        storeDetails.storeId,
        selectedWeek,
      );
      if (response?.data) {
        setWeeklyEfficiencytrendsData(response?.data);
      }
    } catch (error) {
      console.error(
        "Error fetching weekly efficiencytrends chart data:",
        error,
      );
    }
  };

  const fetchDayWiseEfficiencytrends = async (serviceInstance: any) => {
    try {
      const response = await serviceInstance.getDayWiseEfficiencyTreadsData(
        storeDetails.storeId,
        selectedWeek,
      );
      if (response?.data) {
        setDaywiseEfficiencytrendsData(response?.data);
      }
    } catch (error) {
      console.error(
        "Error fetching daywise efficiencytrends chart data:",
        error,
      );
    }
  };

  const fetchSMEfficiencyTarget = async (serviceInstance: any) => {
    try {
      const response = await serviceInstance.getSMEfficiencyTarget(
        storeDetails.storeId,
        selectedWeek,
      );
      if (response?.data) {
        setEfficiencyTarget(response?.data);
      }
    } catch (error) {
      console.error("Error [fetchEfficiencyTarget]:", error);
    }
  };

  const fetchSMWeeklyDemandStaffingData = async (serviceInstance: any) => {
    try {
      const response = await serviceInstance.getSMWeeklyDemandStaffingData(
        storeDetails.storeId,
        selectedWeek,
      );
      if (response?.data) {
        setWeeklyDemandStaffingData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching weekly demand staffing chart data:", error);
    }
  };

  const fetchSMWeeklyEfficiencytrends = async (serviceInstance: any) => {
    try {
      const response = await serviceInstance.getSMWeeklyEfficiencyTrendsData(
        storeDetails.storeId,
        selectedWeek,
      );
      if (response?.data) {
        setWeeklyEfficiencytrendsData(response?.data);
      }
    } catch (error) {
      console.error(
        "Error fetching weekly efficiencytrends chart data:",
        error,
      );
    }
  };

  const fetchSMDayWiseEfficiencytrends = async (serviceInstance: any) => {
    try {
      const response = await serviceInstance.getSMDayWiseEfficiencyTrendsData(
        storeDetails.storeId,
        selectedWeek,
      );
      if (response?.data) {
        setDaywiseEfficiencytrendsData(response?.data);
      }
    } catch (error) {
      console.error(
        "Error fetching daywise efficiencytrends chart data:",
        error,
      );
    }
  };

  const fetchSMWeeklyForecastAccuracyTrends = async (serviceInstance: any) => {
    try {
      const response = await serviceInstance.getWeeklyForecastAccuracyTrends(
        storeDetails.storeId,
        selectedWeek,
      );
      if (response?.data) {
        setForecastAccuracy(response?.data);
      }
    } catch (error) {
      console.error(
        "Error fetching weekly forecast accuracy trends chart data:",
        error,
      );
    }
  };

  useEffect(() => {
    // if (!isStoreUpdate) return;
    if (storeId) {
      getStoreByStoreId(storeId as string);
    } else {
      getStoreByEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, isStoreUpdate]);

  useEffect(() => {
    if (!_.isEmpty(storeDetails)) {
      fetchJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeDetails]);

  useEffect(() => {
    if (storeDetails && selectedWeek) {
      setIsDashboardLoading(true);
      setIsScheduleLoading(true);
      getHoursByWeek(storeDetails.storeId, selectedWeek);
      getKPIByWeek(storeDetails.storeId, selectedWeek);
      getAlertsByWeek(selectedWeek);
      setTimeout(() => {
        setIsDashboardLoading(false);
      }, 500);

      fetchSchedulesByStoreId(storeDetails.storeId, selectedWeek);
      fetchScheduleChartData();
      if (isFromDistrict || isFromROM || isFromVP) {
        let serviceInstance: any = storeService;
        if (isFromROM) {
          serviceInstance = romService;
        } else if (isFromVP) {
          serviceInstance = storeService;
        } else {
          serviceInstance = districtService;
        }
        fetchEfficiencyTarget(serviceInstance);
        fetchWeeklyDemandStaffingData(serviceInstance);
        fetchWeeklyEfficiencytrends(serviceInstance);
        fetchDayWiseEfficiencytrends(serviceInstance);
      } else {
        fetchSMEfficiencyTarget(storeService);
        fetchSMWeeklyDemandStaffingData(storeService);
        fetchSMWeeklyEfficiencytrends(storeService);
        fetchSMDayWiseEfficiencytrends(storeService);
      }
      fetchSMWeeklyForecastAccuracyTrends(storeService);
      setTimeout(() => {
        setIsScheduleLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeDetails, selectedWeek]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getFullAddress = () => {
    return getFullAddressFromStoreDetails(storeDetails);
  };

  const getSelectedWeek = (weekRange: any) => {
    if (weekRange) {
      const startWeek = format(weekRange.start, "yyyy-MM-dd");
      setSelectedWeek(startWeek);
      setStoredWeek(JSON.stringify(weekRange));
    }
  };

  return {
    isStoreLoading,
    isDashboardLoading,
    isScheduleLoading,
    storeDetails,
    selectedTab,
    selectedWeek,
    dashboardChartData,
    storeKPIData,
    storeAlertsData,
    scheduleData,
    SMDetails,
    jobs,
    scheduleChartData,
    storeId,
    weeklyDemandStaffingData,
    weeklyEfficiencytrendsData,
    daywiseEfficiencytrendsData,
    efficiencyTarget,
    forecastAccuracy,
    isFromDistrict,
    isFromVP,
    isFromROM,
    setIsStoreUpdate,
    handleTabChange,
    getFullAddress,
    getSelectedWeek,
    fetchPreviousWeekSchedules,
    saveCurrentSchedules,
  };
};
