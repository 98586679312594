import { Skeleton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import {
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomLineChart from "../../components/CustomLineChart";
import { formatValue, getColorCode } from "../../utils/helpers";
import { CONTENT_BOX, CONTENT_BOX_TITLE } from "../Store/styles";
import { VP_CONTENT_BOX_SUBTITLE, VP_CONTENT_BOX_TITLE } from "./styles";
import { HideImageOutlined } from "@mui/icons-material";
import { CustomSelect } from "../../components";
import { WEEKS_DATA } from "../../utils/constants";

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <Stack
        spacing={1}
        sx={{
          background: "#FFF",
          border: "1px solid rgba(6, 30, 35, 0.10)",
          p: 1,
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            sx={{ fontSize: 14, fontWeight: 500 }}
          >{`Store #${payload[0].payload.storeCode}`}</Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
            {payload[0].name}:
          </Typography>
          <Typography sx={{ fontSize: 14 }}>{`${formatValue(payload[0].value)}${
            payload[0].unit
          }`}</Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
            {payload[1].name}:
          </Typography>
          <Typography sx={{ fontSize: 14 }}>{`${formatValue(payload[1].value)}${
            payload[1].unit
          }`}</Typography>
        </Stack>
      </Stack>
    );
  }

  return null;
};

const RegionChart = ({
  isLoading,
  chartData,
  isRegion = true,
  selectedLastWeek,
  handleLastWeekChange,
}: any) => {
  const renderScatterCharts = () => {
    return Object.keys(chartData?.store).map(
      (key: string, keyIndex: number) => (
        <Scatter
          id={`store-chart-${key}-${keyIndex}`}
          key={`store-chart-${key}-${keyIndex}`}
          data={chartData.store[key]}
          fill={getColorCode(keyIndex)}
          name={key}
        />
      ),
    );
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        alignSelf={"flex-end"}
      >
        <CustomSelect
          label={""}
          id="weeks"
          name="weeks"
          placeholder="Select Week"
          options={WEEKS_DATA}
          value={selectedLastWeek.name}
          onChangeOption={handleLastWeekChange}
        />
      </Stack>
      {isLoading ? (
        <Stack direction={"row"} spacing={1.5}>
          {[...Array(2)].map((_, index) => (
            <Skeleton
              key={`content-skeleton-${index}`}
              variant="rounded"
              height={350}
              sx={{ flex: 1 }}
            />
          ))}
        </Stack>
      ) : (
        <Stack direction={"row"} spacing={2}>
          <CustomLineChart
            data={chartData?.target?.data || null}
            lines={chartData?.target?.lines || []}
            xDataKey="date"
            yLabel="% Over / -Under Weekly Target"
            chartTitle={`${chartData?.target?.chartTitle} - Last ${selectedLastWeek.name}`}
            chartSubtitle={chartData?.target?.chartSubtitle}
          />
          <CustomLineChart
            data={chartData?.plan?.data || null}
            lines={chartData?.plan?.lines || []}
            xDataKey="date"
            yLabel="% Over / -Under Weekly Plan"
            chartTitle={`${chartData?.plan?.chartTitle} - Last ${selectedLastWeek.name}`}
            chartSubtitle={chartData?.plan?.chartTitle}
          />
        </Stack>
      )}

      {isLoading && isRegion ? (
        <Skeleton
          variant="rounded"
          height={525}
          sx={{ flex: 1, minHeight: 525 }}
        />
      ) : (
        <Stack sx={{ ...CONTENT_BOX, minHeight: 525 }} spacing={1.5}>
          <Stack>
            <Typography sx={VP_CONTENT_BOX_TITLE}>
              {`Variance to Plan by Store - Last ${selectedLastWeek.name}`}
            </Typography>
            <Typography sx={VP_CONTENT_BOX_SUBTITLE}>
              Plan Weekly Hours, Actual Hours % Over/Under Plan
            </Typography>
          </Stack>
          {chartData?.store ? (
            <ResponsiveContainer
              width="100%"
              height="100%"
              id={"store-scatter"}
            >
              <ScatterChart
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid vertical={false} />
                <XAxis
                  type="number"
                  dataKey="planHrs"
                  name="Hours"
                  className="chart-axis-text"
                  minTickGap={30}
                >
                  <Label
                    value="Plan Weekly Hours"
                    position="insideBottom"
                    offset={0}
                  />
                </XAxis>
                <YAxis
                  className="chart-axis-text"
                  // interval={0}
                  type="number"
                  name="Percentage"
                  label={{
                    value: `% Over / -Under Plan`,
                    style: { textAnchor: "middle" },
                    angle: -90,
                    position: "left",
                    offset: 0,
                  }}
                  unit={"%"}
                  dataKey="planHrsPercent"
                  minTickGap={30}
                />
                <Tooltip filterNull content={<CustomTooltip />} />
                <Legend
                  formatter={(value) => _.upperCase(value)}
                  wrapperStyle={{ fontSize: 14, paddingTop: 4 }}
                  iconType="circle"
                />
                <ReferenceLine
                  y={5}
                  stroke="#EE4112"
                  strokeDasharray="5 5"
                  position="end"
                  isFront
                >
                  <Label
                    value="Compliance threshold allowance of 5%"
                    position="insideBottomRight"
                    style={{ fontWeight: 500, fill: "#EE4112" }}
                  />
                </ReferenceLine>
                {renderScatterCharts()}
              </ScatterChart>
            </ResponsiveContainer>
          ) : (
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              flex={1}
              spacing={1}
            >
              <HideImageOutlined
                fontSize="large"
                sx={{ color: "rgba(6, 30, 35, 0.34)" }}
              />
              <Typography sx={{ ...CONTENT_BOX_TITLE, color: "#595959" }}>
                No data available!
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default RegionChart;
