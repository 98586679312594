import { Add } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  Paper,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRomContext } from "..";
import CLOSE_ICON from "../../../assets/icons/Icon-close.svg";
import SEARCH_ICON from "../../../assets/icons/search.svg";
import PERSON_IMG from "../../../assets/images/person-blue.png";
import { BootstrapInput } from "../../../components/CustomInput";
import { ROUTES } from "../../../routes/constants";
import { TITLE } from "../../Store/styles";
import CreateStoreDialog from "../../Stores/CreateStoreDialog";
import { StoreTable } from "../../Stores/StoreTable";
import {
  STORES_CREATE_BTN,
  STORES_HEADER_WRAPPER,
  STORES_SEARCH_INPUT,
} from "../../Stores/styles";
import { useRomStores } from "./romStores";
import { useState } from "react";

const ROMStores = () => {
  const { selectedDistrict } = useRomContext();
  const navigate = useNavigate();
  const {
    searchTerm,
    showCreateStore,
    rmStores,
    isLoading,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClearSearch,
    onSearch,
    handleOpenCreateStore,
    handleCreateStoreClose,
    onSubmit,
  } = useRomStores(selectedDistrict);

  const [selectedStore, setSelectedStore] = useState<any>(null);

  const onRedirectStore = (storeId: number) => {
    if (storeId) {
      navigate(`${ROUTES.ROM}/store/${storeId}`, {
        state: { isFromROM: true },
      });
    }
  };

  const onEditStore = (data: any) => {
    setSelectedStore(data);
    setTimeout(() => {
      handleOpenCreateStore();
    }, 500);
  };

  return (
    <Stack flex={1} spacing={3}>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <FormControl variant="outlined" sx={{ width: 357 }}>
          <BootstrapInput
            sx={STORES_SEARCH_INPUT}
            endAdornment={
              searchTerm ? (
                <IconButton
                  size="small"
                  sx={{ padding: "0 4px", background: "transparent" }}
                  onClick={handleClearSearch}
                >
                  <img src={CLOSE_ICON} alt="search" />
                </IconButton>
              ) : (
                <img src={SEARCH_ICON} alt="search" />
              )
            }
            fullWidth
            placeholder="Search for store, email, address"
            value={searchTerm}
            onChange={onSearch}
          />
        </FormControl>
      </Stack>

      <Stack flex={1}>
        <Stack sx={STORES_HEADER_WRAPPER}>
          <Button
            startIcon={<Add />}
            sx={STORES_CREATE_BTN}
            onClick={handleOpenCreateStore}
          >
            Create Store
          </Button>
          {selectedDistrict && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <img src={PERSON_IMG} alt="person" width={40} />
              <Stack spacing={0.5}></Stack>
              <Stack>
                <Typography sx={TITLE}>{selectedDistrict.name}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Paper
          elevation={0}
          sx={{
            borderRadius: 0,
            boxShadow: "none",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <StoreTable
            rows={rmStores?.data}
            isLoading={isLoading}
            onRedirect={onRedirectStore}
            onEdit={onEditStore}
          />
          <TablePagination
            component="div"
            count={rmStores?.totalCount || 0}
            page={page || 0}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage || 0}
            rowsPerPageOptions={[]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Stack>
      <CreateStoreDialog
        openDialog={showCreateStore}
        onClose={handleCreateStoreClose}
        onSubmit={onSubmit}
        storeData={selectedStore}
      />
    </Stack>
  );
};

export default ROMStores;
