import { Skeleton, Stack, Typography } from "@mui/material";
import { GridReadyEvent, RowHeightParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo } from "react";

import { useDistrictContext } from "..";
import "../../../styles/grid.scss";
import "../../../styles/region-grid.scss";
import { CONTENT_BOX, GRID_WRAPPER } from "../../Store/styles";
import { PLANS_COLUMN_DEFS } from "../DistrictGrid/PlansColumns";
import { DM_STORE_COUNT_TEXT } from "../styles";
export default function PlansTab() {
  const { districtPlans, isDistrictLoading } = useDistrictContext();

  const DEFAULT_ROW_HEIGHT = 48;
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      width: 90,
      editable: false,
    };
  }, []);
  const getRowHeight = useCallback(
    (params: RowHeightParams): number | undefined | null =>
      params.data.rowHeight || DEFAULT_ROW_HEIGHT,
    [],
  );

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      if (defaultColDef) {
        params.api.sizeColumnsToFit();
      }
    },
    [defaultColDef],
  );

  return (
    <Stack style={{ marginTop: 24, overflow: "auto" }} spacing={2} flex={1}>
      <Stack direction={"row"} spacing={1.5} sx={{ marginTop: 16 }}>
        <Typography sx={DM_STORE_COUNT_TEXT}>
          Showing Stores ({districtPlans?.length})
        </Typography>
      </Stack>
      <Stack
        sx={{
          ...CONTENT_BOX,
          background: "rgba(187, 239, 255, 0.24)",
          p: 0,
          overflow: "hidden",
          width: "100%",
        }}
      >
        {isDistrictLoading ? (
          <Stack spacing={0.25} flex={1}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={index > 0 ? 48 : 56}
              />
            ))}
          </Stack>
        ) : (
          <Stack className="ag-theme-alpine" sx={GRID_WRAPPER}>
            {districtPlans && (
              <div style={{ height: "100%", width: "100%" }}>
                <AgGridReact
                  // ref={gridRef}
                  rowData={districtPlans}
                  columnDefs={PLANS_COLUMN_DEFS} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  getRowHeight={getRowHeight}
                  headerHeight={56}
                  onGridReady={onGridReady}
                  rowSelection="multiple"
                />
              </div>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
