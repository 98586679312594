import { useState } from "react";
import { Close } from "@mui/icons-material";
import { Dialog, Typography, Stack, IconButton, Button } from "@mui/material";
import { CustomInput, CustomSelect } from "../../components";
import { MODAL_CANCEL_BTN, MODAL_SUCCESS_BTN } from "./styles";

const AddEmployeeDialog = ({
  openAddEmp,
  positions,
  onAddEmployeeClose,
  onEmployeeAdd,
}: any) => {
  const [employeeName, setEmployeeName] = useState<string>("");
  const [employeePosition, setEmployeePosition] = useState<any>(null);

  const handleClose = () => {
    onAddEmployeeClose();
    setEmployeeName("");
    setEmployeePosition(null);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={openAddEmp}
      PaperProps={{
        sx: {
          padding: 3,
          background: "#FFFFFF",
          borderRadius: "8px",
          width: 368,
        },
      }}
    >
      <Stack spacing={4}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 500,
              lineHeight: "24px",
              color: "#061E23",
            }}
          >
            Add Employee
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Stack spacing={3}>
          <CustomInput
            label="Employee Name"
            id="empName"
            placeholder="Enter Name"
            value={employeeName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmployeeName(e.target.value);
            }}
          />
          <CustomSelect
            label={"Position"}
            id="position"
            placeholder="Select Position"
            options={positions}
            valueKey="jobId"
            value={employeePosition?.name}
            onChangeOption={(val: any) => {
              setEmployeePosition(val);
            }}
          />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={1.5}
          >
            <Button sx={MODAL_CANCEL_BTN} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              sx={MODAL_SUCCESS_BTN}
              onClick={() => {
                onEmployeeAdd({ employeeName, employeePosition });
                setEmployeeName("");
                setEmployeePosition(null);
              }}
              disabled={!employeeName || !employeePosition?.name}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AddEmployeeDialog;
