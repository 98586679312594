import { Skeleton, Stack } from "@mui/material";
import FILE_ICON from "../../assets/icons/file.svg";
import HOURS_ICON from "../../assets/icons/hours.svg";
import PLAN_ICON from "../../assets/icons/plan.svg";
import GROUP_ICON from "../../assets/icons/variance.svg";
import StatisticsWidget from "../../components/StatsticsWidget";
import ScheduleGrid from "./ScheduleGrid";
import ScheduleChart from "./ScheduleChart";
import _ from "lodash";

const ICONS_ARRAY = [FILE_ICON, HOURS_ICON, PLAN_ICON, GROUP_ICON, GROUP_ICON];

export default function SchedulesTab({
  isLoading,
  jobs,
  SMDetails,
  scheduleData,
  scheduleChartData,
  storeData,
  fetchPreviousWeekSchedules,
  saveCurrentSchedules,
}: any) {
  const SCHEDULE_STATS = [
    {
      title: "LW Plan",
      value:
        _.round(
          scheduleData?.previousWeekSchedule?.plannedHoursInMinutesText,
          1,
        ) || 0,
      unit: "Hrs",
    },
    {
      title: "LW Scheduled",
      value:
        _.round(
          scheduleData?.previousWeekSchedule?.scheduledHoursInMinutesText,
          1,
        ) || 0,
      unit: "Hrs",
    },
    {
      title: "LW Actual",
      value:
        _.round(
          scheduleData?.previousWeekSchedule?.actualsHoursInMinutesText,
          1,
        ) || 0,
      unit: "Hrs",
    },
    {
      title: "Actual vs Plan",
      value:
        _.round(
          scheduleData?.previousWeekSchedule?.actualVsPlannedHoursInMinutesText,
          1,
        ) || 0,
      unit:
        _.round(
          scheduleData?.previousWeekSchedule?.actualVsPlannedHoursPercent,
          1,
        ) || "",
    },
    {
      title: "Actual vs Scheduled",
      value:
        _.round(
          scheduleData?.previousWeekSchedule
            ?.actualVsScheduledHoursInMinutesText,
          1,
        ) || 0,
      unit:
        _.round(
          scheduleData?.previousWeekSchedule?.actualVsScheduledHoursPercent,
          1,
        ) || "",
    },
  ];

  return (
    <Stack style={{ marginTop: 24, overflow: "auto" }} spacing={2} flex={1}>
      {isLoading ? (
        <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
          <Skeleton variant="rounded" height={70} sx={{ flex: 1 }} />
          <Skeleton variant="rounded" height={70} sx={{ flex: 1 }} />
          <Skeleton variant="rounded" height={70} sx={{ flex: 1 }} />
          <Skeleton variant="rounded" height={70} sx={{ flex: 1 }} />
          <Skeleton variant="rounded" height={70} sx={{ flex: 1 }} />
        </Stack>
      ) : (
        <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
          {SCHEDULE_STATS.map((item: any, index: number) => (
            <StatisticsWidget
              key={`schedule-widget_${index}`}
              icon={ICONS_ARRAY[index]}
              flex={1}
              data={[item]}
            />
          ))}
        </Stack>
      )}

      <ScheduleGrid
        jobs={jobs}
        SMDetails={SMDetails}
        scheduleData={scheduleData}
        isLoading={isLoading}
        storeData={storeData}
        fetchPreviousWeekSchedules={fetchPreviousWeekSchedules}
        saveCurrentSchedules={saveCurrentSchedules}
      />

      {isLoading ? (
        <Stack minHeight={450}>
          <Skeleton variant="rounded" height={"100%"} width={"100%"} />
        </Stack>
      ) : (
        <ScheduleChart chartData={scheduleChartData} />
      )}
    </Stack>
  );
}
