import { SxProps } from "@mui/material";

export const BACKDROP_WRAPPER: SxProps = () => ({
  zIndex: 1,
});

export const LOADING_WRAPPER: SxProps = () => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "#FEBE38",
});

export const TOOLBAR_WRAPPER: SxProps = {
  display: "flex",
  alignItems: "center",
  paddingX: 3,
  justifyContent: "space-between",
  flexGrow: 1,
};

export const USERNAME_TEXT: SxProps = {
  fontWeight: 500,
  fontSize: 12,
  lineHeight: "16px",
  color: "#FFFFFF",
  textTransform: "lowercase",
};

export const APP_WRAPPER: SxProps = {
  display: "flex",
  background: "#fff7f5",
  flex: 1,
};

export const APP_CONTAINER: SxProps = {
  display: "flex",
  flex: 1,
  margin: 2,
};
