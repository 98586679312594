import {
  ColDef,
  ColGroupDef,
  INumberCellEditorParams,
} from "ag-grid-community";
import StoreCellRenderer from "./storeCellRenderer";
import ActionCellRenderer from "./actionCellRendere";
import { CustomCellRenderer } from "../../../utils/helpers";

export const EFFICIENCY_COLUMN_DEFS: (ColDef<any> | ColGroupDef<any>)[] = [
  {
    field: "storeCode",
    headerName: "Store#",
    minWidth: 120,
    headerClass: ["region-grid-header-cell"],
    pinned: "left",
    cellClass: "region-grid-cell",
    cellRenderer: StoreCellRenderer,
  },
  {
    field: "city",
    headerName: "City",
    minWidth: 170,
    headerClass: ["region-grid-header-left-border", "region-grid-header-cell"],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "sm1name",
    headerName: "Store Manager",
    minWidth: 170,
    headerClass: ["region-grid-header-left-border", "region-grid-header-cell"],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "sm2name",
    headerName: "Store Account",
    minWidth: 170,
    headerClass: ["region-grid-header-left-border", "region-grid-header-cell"],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "previousTarget",
    headerName: "Previous Target",
    minWidth: 170,
    headerClass: ["region-grid-header-left-border", "region-grid-header-cell"],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "targetValue",
    headerName: "New Target",
    minWidth: 170,
    headerClass: ["region-grid-header-left-border", "region-grid-header-cell"],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
    cellEditor: "agNumberCellEditor",
    cellEditorParams: {
      min: 0,
      max: 100,
    } as INumberCellEditorParams,
    editable: true,
  },
  {
    field: "",
    headerName: "",
    maxWidth: 80,
    headerClass: ["region-grid-header-left-border", "region-grid-header-cell"],
    cellClass: "region-grid-cell",
    cellRenderer: ActionCellRenderer,
  },
];
