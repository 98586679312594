import { AuthenticatedTemplate, useAccount } from "@azure/msal-react";
import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useLocation, useMatch, useNavigate } from "react-router-dom";
import MiniDrawer from "../components/Drawer";
import { ROUTES } from "../routes/constants";
import { APP_CONTAINER, APP_WRAPPER } from "../styles/styles";
import { UserRole } from "../utils/constants";

export const HomeContext = React.createContext<any>(null);

const Home: React.FC = () => {
  const account = useAccount();
  const location = useLocation();
  const homeMatch = useMatch(ROUTES.HOME);
  const navigate = useNavigate();
  const currentRole =
    account?.idTokenClaims?.roles?.[0] || UserRole.StoreManager;

  useEffect(() => {
    if (location && homeMatch) {
      if (currentRole === UserRole.VP) {
        navigate(ROUTES.REGION);
      } else if (currentRole === UserRole.RegionalManagers) {
        navigate(ROUTES.ROM);
      } else if (currentRole === UserRole.DistrictManagers) {
        navigate(ROUTES.DISTRICT);
      } else {
        navigate(ROUTES.STORE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <AuthenticatedTemplate>
      <HomeContext.Provider value={{ currentRole }}>
        <Box sx={APP_WRAPPER}>
          <MiniDrawer />
          <Container sx={APP_CONTAINER} maxWidth={false} disableGutters>
            <Outlet />
          </Container>
        </Box>
      </HomeContext.Provider>
    </AuthenticatedTemplate>
  );
};

export default Home;
