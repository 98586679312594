import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from "axios";
import { msalInstance } from "..";
import { loginRequest } from "../config/authConfig";
import { ROUTES } from "../routes/constants";

const API_BASE_URL = process.env.REACT_APP_API_URL;

function getAuthToken() {
  return msalInstance
    .acquireTokenSilent(loginRequest)
    .then((tokenResponse) => {
      // Do something with the tokenResponse
      // return tokenResponse?.idToken || tokenResponse?.accessToken;
      return tokenResponse?.accessToken || tokenResponse?.idToken;
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(loginRequest);
      }
    });
}

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor for handling authorization
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

// Interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally, e.g., redirect to login for unauthorized access
    if (error?.response?.status === 404) {
      // Handle unauthorized access
      window.location.assign(ROUTES.NOTFOUND);
    }
    if (error?.response?.status >= 400) {
      window.location.assign(ROUTES.ERROR);
    }
    // Handle other types of errors as needed
    return Promise.reject(error);
  },
);
