import { useCallback, useEffect, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { IconButton, SxProps } from "@mui/material";
import EDIT_ICON from "../../../assets/icons/pencil.svg";
import DONE_ICON from "../../../assets/icons/Icon-done.svg";
import React from "react";
import districtService from "../../../services/districtService";
import { useSnackbar } from "notistack";
import { addWeeks, format, isAfter, isEqual, startOfWeek } from "date-fns";
import { useLocalStorage } from "@uidotdev/usehooks";

const SAVE_BTN: SxProps = {
  width: 24,
  height: 24,
  padding: 0.5,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  border: "1px solid #33B000",
  background: "#33B000",
  flexShrink: 0,
  "&:hover": {
    border: "1px solid #33B000",
    background: "#33B000",
  },
};

const EDIT_BTN: SxProps = {
  width: 24,
  height: 24,
  padding: 0.5,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  border: "1px solid #009FF3",
  background: "#FFFFFF",
  flexShrink: 0,
  "&:hover": {
    border: "1px solid #009FF3",
    background: "#FFFFFF",
  },
  "&.Mui-disabled": {
    background: "rgba(6, 30, 35, 0.24)",
    opacity: 0.5,
    cursor: "not-allowed",
  },
};

const ActionCellRenderer = (props: ICellRendererParams<any>) => {
  const { api } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const [storedWeek] = useLocalStorage("storedWeek", "");

  useEffect(() => {
    if (storedWeek) {
      const { start } = JSON.parse(storedWeek);
      const currentDate = new Date();
      const targetDate = startOfWeek(addWeeks(currentDate, 3));
      const isEqualOrAfter =
        isEqual(new Date(start), targetDate) ||
        isAfter(new Date(start), targetDate);
      setIsEditable(isEqualOrAfter);
    }
  }, [storedWeek]);

  const saveEfficiencyData = async (reqData: any) => {
    try {
      const { start } = JSON.parse(storedWeek);
      const startWeek = format(new Date(start), "yyyy-MM-dd");

      await districtService
        .saveRMDistrictEfficiencyById(
          reqData?.districtId as string,
          startWeek,
          reqData,
        )
        .then(async () => {})
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error("[saveEfficiencyData]: ", error);
    }
  };

  const onEdit = useCallback(() => {
    setIsEditing(true);
    const selectedRowIndex = props?.node?.rowIndex as number;
    api.setFocusedCell(selectedRowIndex, "targetValue");
    api.startEditingCell({
      rowIndex: selectedRowIndex,
      colKey: "targetValue",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(async () => {
    setIsEditing(false);
    api.stopEditing();
    await saveEfficiencyData(props.data);
    enqueueSnackbar("Success: The operation was successful!", {
      variant: "success",
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {isEditing ? (
        <IconButton onClick={onSubmit} sx={SAVE_BTN}>
          <img src={DONE_ICON} alt="edit" width={16} />
        </IconButton>
      ) : (
        <IconButton onClick={onEdit} sx={EDIT_BTN} disabled={!isEditable}>
          <img src={EDIT_ICON} alt="edit" width={16} />
        </IconButton>
      )}
    </React.Fragment>
  );
};

export default ActionCellRenderer;
