import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import CreateStoreForm from "./CreateStoreForm";

const DIALOG_WRAPPER: SxProps = {
  padding: 3,
  background: "#FFFFFF",
  borderRadius: "12px",
  boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.25)",
  minWidth: 1024,
  overflow: "hidden",
};

const TITLE: SxProps = {
  fontSize: 24,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
};

const CreateStoreDialog = ({
  storeData,
  openDialog,
  onClose,
  onSubmit,
}: any) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={openDialog}
      PaperProps={{
        sx: DIALOG_WRAPPER,
      }}
    >
      <Stack spacing={3}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography sx={TITLE}>
            {storeData ? "Edit Store" : "Create Store"}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <DialogContent sx={{ p: 0 }}>
          <CreateStoreForm
            onCancel={onClose}
            onSubmit={onSubmit}
            isEnable={storeData ? true : false}
            storeData={storeData}
          />
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default CreateStoreDialog;
