import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import romService from "../../services/romService";
import { PLANS_COLUMN_DEFS } from "./ROMGrid/PlansColumns";
import { DASHBOARD_COLUMN_DEFS } from "./ROMGrid/dashboardColumns";
import { EFFICIENCY_COLUMN_DEFS } from "./ROMGrid/efficiencyColumns";
import { WEEKS_DATA } from "../../utils/constants";

export const useROMDistrict = (selectedWeek: string) => {
  const { districtId, regionId } = useParams();
  const [dashboardLoading, setDashboardLoading] = useState<boolean>(false);
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [districtDashboardData, setDistrictDashboardData] = useState<any>({
    dashboardData: null,
    kpiData: null,
  });
  const [gridColumns, setGridColumns] = useState(DASHBOARD_COLUMN_DEFS);
  const [selectedTab, setSelectedTab] = useState(0);
  const [districtStoreChartData, setDistrictStoreChartData] =
    useState<any>(null);
  const [efficiencyStores, setEfficiencyStores] = useState<any>(null);
  const [planStores, setPlanStores] = useState<any>(null);
  const [keyAlerts, setKeyAlerts] = useState([]);
  const [selectedLastWeek, setSelectedLastWeek] = useState<any>(WEEKS_DATA[0]);

  const getDistrictDashboardData = async () => {
    setDashboardLoading(true);
    try {
      await romService
        .getDistrictDashboardById(districtId as string, selectedWeek)
        .then(async (response) => {
          if (response?.data) {
            setDistrictDashboardData({
              dashboardData: response?.data?.districtStoresDashboard,
              kpiData: response?.data?.districtDashboardKPI,
            });
            setDashboardLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching district data:", error);
          setDashboardLoading(false);
        });
    } catch (error) {
      console.error("[getDistrictDashboardData]: ", error);
      setDashboardLoading(false);
    }
  };

  const getEfficiencyData = async () => {
    setDashboardLoading(true);
    try {
      await romService
        .getDistrictEfficiencyById(districtId as string, selectedWeek)
        .then(async (response) => {
          if (response?.data) {
            setEfficiencyStores(response?.data);
            setDashboardLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching effieciency data:", error);
          setDashboardLoading(false);
        });
    } catch (error) {
      console.error("[getEfficiencyData]: ", error);
      setDashboardLoading(false);
    }
  };

  const getPlanData = async () => {
    try {
      setDashboardLoading(true);
      await romService
        .getDistrictPlanById(districtId as string, selectedWeek)
        .then(async (response) => {
          if (response?.data) {
            setPlanStores(response?.data);
            setDashboardLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching effieciency data:", error);
          setDashboardLoading(false);
        });
    } catch (error) {
      console.error("[getEfficiencyData]: ", error);
      setDashboardLoading(false);
    }
  };

  const getDistrictStoreChartData = async (weeks: number | null = null) => {
    setChartLoading(true);
    try {
      await romService
        .getDistrictStoreChartDataByDistrictId(
          districtId as string,
          selectedWeek,
          weeks ?? selectedLastWeek.value,
        )
        .then(async (response) => {
          if (response?.data) {
            const varianceToPlan = {
              data: response?.data?.varianceToPlan,
              lines: response?.data?.varianceToPlan
                ? Object.keys(response?.data?.varianceToPlan[0])?.filter(
                    (k) => k !== "date",
                  )
                : [],
              chartTitle: `Weekly Hours Variance to Plan - Last ${
                weeks ?? selectedLastWeek.value
              } Weeks`,
              chartSubtitle: "Actual Hours % Over/Under Plan",
            };
            setDistrictStoreChartData(varianceToPlan);
            setChartLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching district data:", error);
          setChartLoading(false);
        });
    } catch (error) {
      console.error("[getDistrictStoreChartData]: ", error);
      setChartLoading(false);
    }
  };

  const getKeyAlertsByDistrictId = async () => {
    setDashboardLoading(true);
    try {
      await romService
        .getKeyAlertsByDistrictId(districtId as string, selectedWeek)
        .then(async (response) => {
          if (response?.data) {
            setKeyAlerts(response?.data);
            setDashboardLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching key alerts:", error);
          setDashboardLoading(false);
        });
    } catch (error) {
      console.error("[getKeyAlertsByDistrictId]: ", error);
      setDashboardLoading(false);
    }
  };
  useEffect(() => {
    if (districtId && selectedWeek) {
      getDistrictDashboardData();
      getDistrictStoreChartData();
      getEfficiencyData();
      getPlanData();
      getKeyAlertsByDistrictId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionId, districtId, selectedWeek]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    switch (newValue) {
      case 0:
        setGridColumns(DASHBOARD_COLUMN_DEFS);
        break;
      case 1:
        setGridColumns(EFFICIENCY_COLUMN_DEFS);
        break;
      case 2:
        setGridColumns(PLANS_COLUMN_DEFS);
        break;

      default:
        break;
    }
  };

  const handleLastWeekChange = async (val: any) => {
    try {
      setSelectedLastWeek(val);
      await getDistrictStoreChartData(val.value);
    } catch (error) {
      console.error("[handleLastWeekChange] Error: ", error);
    }
  };

  return {
    dashboardLoading,
    districtDashboardData,
    gridColumns,
    selectedTab,
    chartLoading,
    districtStoreChartData,
    efficiencyStores,
    planStores,
    keyAlerts,
    selectedLastWeek,
    handleTabChange,
    handleLastWeekChange,
  };
};
