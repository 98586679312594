import React from "react";
import { Stack, Typography, Skeleton } from "@mui/material";
import { CONTENT_BOX, CONTENT_BOX_TITLE } from "./styles";
import HOURS_ICON from "../../assets/icons/hours.svg";
import PLAN_ICON from "../../assets/icons/plan.svg";
import FILE_ICON from "../../assets/icons/file.svg";
import StatisticsWidget from "../../components/StatsticsWidget";
import {
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import _ from "lodash";
import KeyAlerts from "../../components/KeyAlerts";

export default function DashboardTab({
  chartData,
  kpiData,
  alerts,
  isLoading,
}: any) {
  const [lineData, setLineData] = React.useState<any>([]);
  const PLANNED_HOURS_DATA = [
    {
      title: "Planned Hours",
      value: _.round(kpiData?.plannedHoursText, 1) || 0,
      unit: "Hrs",
    },
    {
      title: "Scheduled Hours",
      value: _.round(kpiData?.scheduledHoursText, 1) || 0,
      unit: "Hrs",
    },
    {
      title: "Actual Hours",
      value: _.round(kpiData?.actualHoursText, 1) || 0,
      unit: "Hrs",
    },
  ];

  const ACTUAL_VS_PLAN_DATA = [
    {
      title: "Actual vs. Plan",
      value: _.round(kpiData?.actualVsPlannedText, 1) || 0,
      unit: "",
    },
    {
      title: "% Over(Under)Plan",
      value: _.round(kpiData?.overUnderPlanPercent, 1) || 0,
      unit: "%",
    },
  ];

  const SCHEDULE_VS_PLAN_DATA = [
    {
      title: "Scheduled vs Plan",
      value: _.round(kpiData?.scheduledVsPlannedText, 1) || 0,
      unit: `${_.round(kpiData?.scheduledVsPlannedPercent, 1) || 0}%`,
    },
    {
      title: "Actual vs. Scheduled",
      value: _.round(kpiData?.actualVsScheduledText, 1) || 0,
      unit: `${_.round(kpiData?.actualVsScheduledPercent, 1) || 0}%`,
    },
  ];

  React.useEffect(() => {
    if (chartData) {
      const tempChartData: any = [];
      chartData.dates.forEach((date: string, index: number) => {
        const tempItem = {
          date: date,
          plan: chartData.planData[index],
          actual: chartData.actualData[index],
          scheduled: chartData.scheduleData[index],
        };
        tempChartData.push(tempItem);
      });
      setLineData(tempChartData);
    }
  }, [chartData]);

  return (
    <Stack style={{ marginTop: 24, overflow: "auto" }} spacing={2} flex={1}>
      {isLoading ? (
        <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
          {[...Array(3)].map((_, index) => (
            <Skeleton
              key={`kpi-skeleton-${index}`}
              variant="rounded"
              height={70}
              sx={{ flex: 1 }}
            />
          ))}
        </Stack>
      ) : (
        <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
          <StatisticsWidget
            icon={HOURS_ICON}
            flex={1.5}
            data={PLANNED_HOURS_DATA}
          />
          <StatisticsWidget
            icon={FILE_ICON}
            flex={1}
            data={ACTUAL_VS_PLAN_DATA}
          />
          <StatisticsWidget
            icon={PLAN_ICON}
            flex={1}
            data={SCHEDULE_VS_PLAN_DATA}
          />
        </Stack>
      )}

      {isLoading ? (
        <Stack direction={"row"} spacing={1.5}>
          {[...Array(2)].map((_, index) => (
            <Skeleton
              key={`content-skeleton-${index}`}
              variant="rounded"
              height={350}
              sx={{ flex: 1 }}
            />
          ))}
        </Stack>
      ) : (
        <Stack direction={"row"} spacing={1.5}>
          <Stack sx={CONTENT_BOX} spacing={1.5} height={350}>
            <Typography sx={CONTENT_BOX_TITLE}>
              Planned, Scheduled and Actual Hours by Week(for the past 4 weeks)
            </Typography>
            {chartData && (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={lineData}
                  margin={{
                    top: 20,
                    right: 10,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis
                    dataKey="date"
                    className="chart-axis-text"
                    padding={{ left: 30, right: 30 }}
                  >
                    <Label value="Weeks" position="insideBottom" offset={0} />
                  </XAxis>
                  <YAxis className="chart-axis-text">
                    <Label
                      value="Hours"
                      position="insideLeft"
                      offset={10}
                      angle={270}
                    />
                  </YAxis>
                  <Tooltip
                    contentStyle={{ textTransform: "capitalize", fontSize: 14 }}
                    filterNull
                    formatter={(value) =>
                      typeof value === "number" ? value.toFixed(1) : value
                    }
                  />
                  <Legend
                    formatter={(value) => _.startCase(_.lowerCase(value))}
                    wrapperStyle={{ fontSize: 14, paddingTop: 4 }}
                  />
                  <Line
                    type="linear"
                    dataKey="plan"
                    stroke="#2D70BA"
                    strokeDasharray="20 20"
                    strokeWidth={4}
                    animationDuration={500}
                  />
                  <Line
                    type="linear"
                    dataKey="scheduled"
                    stroke="#50AD60"
                    strokeWidth={4}
                    animationDuration={500}
                  />
                  <Line
                    type="linear"
                    dataKey="actual"
                    stroke="#A3AAAC"
                    strokeWidth={4}
                    animationDuration={500}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </Stack>
          <KeyAlerts alerts={alerts} />
        </Stack>
      )}
    </Stack>
  );
}
