import { Container, CssBaseline } from "@mui/material";
import React from "react";

export const NoAuthLayout = ({ children }: any): React.ReactElement => {
  return (
    <>
      <CssBaseline enableColorScheme />
      <Container maxWidth={false} disableGutters>
        {children}
      </Container>
    </>
  );
};
