import _ from "lodash";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  Label,
  YAxis,
  Legend,
  Line,
  Tooltip,
} from "recharts";
import { DataKey } from "recharts/types/util/types";
import { Stack, Typography } from "@mui/material";
import {
  VP_CONTENT_BOX_TITLE,
  VP_CONTENT_BOX_SUBTITLE,
} from "../screens/Region/styles";
import { CONTENT_BOX } from "../screens/Store/styles";
import { getColorCode } from "../utils/helpers";

const CustomLineChart = ({
  data,
  lines,
  xDataKey,
  yLabel,
  chartTitle,
  chartSubtitle,
}: any) => {
  // Filter out keys with null values
  const validLines = lines.filter((key: DataKey<any> | undefined | any) => {
    const hasNullValues = data.some((entry: any) => entry[key] === null);
    return !hasNullValues;
  });

  return (
    <Stack sx={{ ...CONTENT_BOX, minHeight: 450 }} spacing={1.5}>
      <Stack>
        <Typography sx={VP_CONTENT_BOX_TITLE}>{chartTitle}</Typography>
        <Typography sx={VP_CONTENT_BOX_SUBTITLE}>{chartSubtitle}</Typography>
      </Stack>
      {data && (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 10, right: 20, left: 20, bottom: 20 }}
          >
            <CartesianGrid />
            <XAxis
              dataKey={xDataKey}
              className="chart-axis-text"
              interval={0}
              angle={285}
              tickMargin={35}
            >
              <Label value="Weeks" position="insideBottom" offset={0} />
            </XAxis>
            <YAxis
              className="chart-axis-text"
              interval={0}
              type="number"
              label={{
                value: yLabel,
                style: { textAnchor: "middle" },
                angle: -90,
                position: "left",
                offset: 0,
              }}
              unit={"%"}
            />
            <Tooltip
              contentStyle={{ textTransform: "capitalize", fontSize: 14 }}
              filterNull
              formatter={(value) =>
                typeof value === "number" ? value.toFixed(1) : value
              }
            />
            <Legend
              formatter={(value) => {
                if (value.split(" ").length > 1 || value === "total") {
                  return _.startCase(_.lowerCase(value));
                } else {
                  return _.upperCase(value);
                }
              }}
              wrapperStyle={{ fontSize: 13, paddingTop: 40, bottom: 0 }}
              iconType="circle"
            />
            {validLines.map((key: DataKey<any> | undefined, index: number) => {
              const isTotal = key === "total";
              return (
                <Line
                  id={`line-${key}-${index}`}
                  key={`line-${key}-${index}`}
                  type="monotone"
                  dataKey={key}
                  stroke={isTotal ? "#000000" : getColorCode(index)}
                  strokeWidth={2}
                  animationDuration={500}
                  strokeDasharray={isTotal ? "5 5" : ""}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      )}
    </Stack>
  );
};

export default CustomLineChart;
