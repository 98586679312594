import React from "react";
import { Box, Button, SxProps, Typography } from "@mui/material";
import NOT_FOUND_IMG from "../assets/images/404.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes/constants";

const BACK_BTN: SxProps = {
  background: "#FEBE38",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
  textTransform: "capitalize",
  "&:hover": {
    background: "#FEBE38",
    color: "#061E23",
  },
};

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
      gap={2}
    >
      <img src={NOT_FOUND_IMG} alt="404" width={172} />
      <Typography fontSize={32} fontWeight={600} color="#061E23">
        Page not found
      </Typography>
      <Typography fontSize={16} fontWeight={400} color="rgba(6, 30, 35, 0.80)">
        The page you are looking for doesn't exist or another error occurred. Go
        back to the home page.
      </Typography>
      <Button variant="contained" sx={BACK_BTN} onClick={handleGoBack}>
        Go Back
      </Button>
    </Box>
  );
};

export default NotFound;
