export const CHART_COLORS = [
  "#59D8FF",
  "#9FDB44",
  "#FEBE38",
  "#009FF3",
  "#33B000",
  "#EE4112",
  "#204EC4",
  "#006636",
];

export const ROLE_COLORS = [
  {
    role: "Sales Associate",
    color: "#1f77b4",
  },
  {
    role: "Store Ambassador",
    color: "#ff7f0e",
  },
  {
    role: "Assistant Store Manager",
    color: "#2ca02c",
  },
  {
    role: "Tank Wagon Driver",
    color: "#D62728",
  },
  {
    role: "Driver",
    color: "#9467BD",
  },
  {
    role: "Cafe Sales Assoc",
    color: "#C7877A",
  },
  {
    role: "Car Wash Attendant",
    color: "#E377C2",
  },
  {
    role: "Warehouse Supervisor",
    color: "#7F7F7F",
  },
  {
    role: "Car Wash Asst Mgr",
    color: "#BCBD22",
  },
  {
    role: "Car Wash Manager",
    color: "#17BECF",
  },
  {
    role: "Lead Sales Associate",
    color: "#393B79",
  },
  {
    role: "Store Manager",
    color: "#63802D",
  },
  {
    role: "CDL Driver",
    color: "#8C6D31",
  },
  {
    role: "Oil Driver",
    color: "#843C39",
  },
  {
    role: "Warehouse Technician",
    color: "#7B4173",
  },
  {
    role: "Cafe Manager",
    color: "#80E917",
  },
  {
    role: "Assistant Plant Mgr",
    color: "#B2DF8A",
  },
  {
    role: "Labourer",
    color: "#FB9A99",
  },
  {
    role: "Cafe Asst Mgr",
    color: "#A6CEE3",
  },
  {
    role: "Warehouse Assistant",
    color: "#FDBF6F",
  },
  {
    role: "Driver Propane",
    color: "#CAB2D6",
  },
  {
    role: "Facilities  Maint Tech",
    color: "#FF8C00",
  },
  {
    role: "Maintenance",
    color: "#66C2A5",
  },
  {
    role: "Fuel Driver",
    color: "#E78AC3",
  },
  {
    role: "Deli Manager",
    color: "#91D02A",
  },
  {
    role: "Branch Manager",
    color: "#FFD92F",
  },
  {
    role: "City Driver",
    color: "#E5C494",
  },
  {
    role: "Office Manager",
    color: "#8954C1",
  },
  {
    role: "Warehouse",
    color: "#B3B3B3",
  },
  {
    role: "Propane Service Manager",
    color: "#B15928",
  },
  {
    role: "Service Tech",
    color: "#2E8B57",
  },
  {
    role: "Warehouse Manager Oil",
    color: "#556B2F",
  },
  {
    role: "Warehouse Lead",
    color: "#8B4513",
  },
  {
    role: "Warehouse Manager",
    color: "#4682B4",
  },
  {
    role: "Tank Wagon Driver Trainer",
    color: "#708090",
  },
  {
    role: "Bulk Lubricant Tech",
    color: "#6A5ACD",
  },
  {
    role: "Cafe Cook",
    color: "#D2691E",
  },
  {
    role: "Marketing Assistant Mgr",
    color: "#9ACD32",
  },
  {
    role: "OTR CDL Driver",
    color: "#8FBC8F",
  },
  {
    role: "Terminal Manager",
    color: "#B8860B",
  },
];

export enum UserRole {
  Developer = "Developer",
  StoreManager = "StoreManager",
  VP = "VP",
  DistrictManagers = "DistrictManagers",
  RegionalManagers = "RegionalManagers",
}
export enum NotificationType {
  Error = "error",
  Message = "message",
}

export const FLOAT_REGEX = /^$|^\d*\.?\d{0,1}$/;
export const ALPHABETS_REGEX = /^[A-Za-z]*$/;

export const DEFAULT_SERVICES = [
  "Manned Car Wash",
  "Unmanned Car Wash",
  "QSR",
  "Bulk Propane",
];

export const WEEKS_DATA = [
  {
    name: "4 Weeks",
    value: 4,
  },
  {
    name: "8 Weeks",
    value: 8,
  },
  {
    name: "13 Weeks",
    value: 13,
  },
];
