import { Box, Stack, Typography } from "@mui/material";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { VP_CONTENT_BOX_TITLE } from "../Region/styles";
import { CONTENT_BOX, TARGET_VALUE, TARGET_VALUE_WRAPPER } from "./styles";

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const { forecastedTransactions, actualTransactions } = payload[0]?.payload;
    return (
      <Stack
        spacing={0.5}
        sx={{
          backgroundColor: "white",
          padding: 1,
          border: "1px solid #D8D8D9",
        }}
      >
        <Typography sx={{ fontSize: 14 }}>{label}</Typography>
        <Typography
          sx={{ fontSize: 14, color: "#2D70BA" }}
        >{`Forecasted Transactions: ${
          Number(forecastedTransactions) === forecastedTransactions &&
          forecastedTransactions % 1 !== 0
            ? forecastedTransactions.toFixed(1)
            : forecastedTransactions
        }`}</Typography>
        <Typography
          sx={{ fontSize: 14, color: "#50AD60" }}
        >{`Actual Transactions: ${
          Number(actualTransactions) === actualTransactions &&
          actualTransactions % 1 !== 0
            ? actualTransactions.toFixed(1)
            : actualTransactions
        }`}</Typography>
        {payload.map((item: any, index: number) => (
          <Typography
            key={`tooltip-item-${index}`}
            sx={{
              textTransform: "capitalize",
              fontSize: 14,
            }}
          >
            {`${item.name}: ${
              Number(item.value) === item.value && item.value % 1 !== 0
                ? item.value.toFixed(1)
                : item.value
            }%`}
          </Typography>
        ))}
      </Stack>
    );
  }

  return null;
};

export default function Efficiency({
  weeklyDemandStaffingData,
  weeklyEfficiencytrendsData,
  daywiseEfficiencytrendsData,
  efficiencyTarget,
  forecastAccuracyTrends,
}: any) {
  return (
    <Stack style={{ marginTop: 24, overflow: "auto" }} spacing={2} flex={1}>
      {efficiencyTarget &&
        (efficiencyTarget?.lastWeekTargetValue ||
          efficiencyTarget?.targetValue) && (
          <Stack direction={"row"} spacing={1.5}>
            <Box sx={TARGET_VALUE_WRAPPER}>
              <Typography sx={TARGET_VALUE}>LWK Target:</Typography>
              <Typography sx={{ ...TARGET_VALUE, color: "#00942A" }}>
                {efficiencyTarget?.lastWeekTargetValue || "-"}
              </Typography>
            </Box>

            <Box sx={TARGET_VALUE_WRAPPER}>
              <Typography sx={TARGET_VALUE}>New Target:</Typography>
              <Typography sx={{ ...TARGET_VALUE, color: "#00942A" }}>
                {efficiencyTarget?.targetValue || "-"}
              </Typography>
            </Box>
          </Stack>
        )}

      <Stack sx={{ ...CONTENT_BOX, minHeight: 500 }} spacing={1.5}>
        <Stack>
          <Typography sx={VP_CONTENT_BOX_TITLE}>
            Demand and Staffing for Last 52 Weeks
          </Typography>
        </Stack>
        <ResponsiveContainer width="100%" height="100%" id={"store-scatter"}>
          <ComposedChart
            data={weeklyDemandStaffingData}
            margin={{
              top: 50,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey={"date"}
              angle={270}
              tickMargin={45}
              className="chart-axis-text"
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              tickCount={6}
              label={{
                value: "Actual Transactions",
                style: { textAnchor: "middle" },
                angle: -90,
                position: "left",
                offset: 0,
              }}
              className="chart-axis-text"
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tickCount={6}
              label={{
                value: "Actual Hours",
                style: { textAnchor: "middle" },
                angle: 90,
                position: "right",
                offset: 10,
              }}
              className="chart-axis-text"
            />
            <Tooltip
              contentStyle={{ textTransform: "capitalize", fontSize: 14 }}
              filterNull
              formatter={(value) =>
                typeof value === "number" ? value.toFixed(1) : value
              }
            />
            <Bar
              yAxisId="left"
              dataKey="sumTransactions"
              fill="#204EC4"
              name="Actual Transactions"
            >
              <LabelList
                dataKey="sumTransactions"
                position="top"
                angle={-90}
                fontSize={12}
                offset={20}
                textAnchor="middle"
              />
            </Bar>
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="sumActualHrs"
              name="Actual Hours"
              stroke="#50AD60"
              strokeWidth={4}
              dot={false}
            />
            <Legend wrapperStyle={{ fontSize: 14, paddingTop: 68 }} />
          </ComposedChart>
        </ResponsiveContainer>
      </Stack>

      <Stack sx={{ ...CONTENT_BOX, minHeight: 450 }} spacing={1.5}>
        <Stack>
          <Typography sx={VP_CONTENT_BOX_TITLE}>
            Average Demand and Staffing trends Per Week Day(for the past 4
            weeks)
          </Typography>
        </Stack>
        <ResponsiveContainer width="100%" height="100%" id={"store-scatter"}>
          <ComposedChart
            data={daywiseEfficiencytrendsData}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <Tooltip
              contentStyle={{ textTransform: "capitalize", fontSize: 14 }}
              filterNull
              formatter={(value) =>
                typeof value === "number" ? value.toFixed(1) : value
              }
            />
            <XAxis
              dataKey={"day"}
              type="category"
              className="chart-axis-text"
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              tickCount={6}
              label={{
                value: "Transactions",
                style: { textAnchor: "middle" },
                angle: -90,
                position: "left",
                offset: 0,
              }}
              className="chart-axis-text"
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tickCount={6}
              label={{
                value: "Hours",
                style: { textAnchor: "middle" },
                angle: 90,
                position: "right",
                offset: 10,
              }}
              className="chart-axis-text"
            />
            <Bar
              yAxisId="left"
              dataKey="avgTransactions"
              name="Transactions"
              barSize={30}
              fill="#204EC4"
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="avgActualHrs"
              name="Actual Hours"
              stroke="#50AD60"
              strokeWidth={4}
              dot={false}
            />
            <Legend wrapperStyle={{ fontSize: 14, paddingTop: 4 }} />
          </ComposedChart>
        </ResponsiveContainer>
      </Stack>

      <Stack sx={{ ...CONTENT_BOX, minHeight: 500 }} spacing={1.5}>
        <Stack>
          <Typography sx={VP_CONTENT_BOX_TITLE}>
            Efficiency Trends by Week(for the past 52 weeks)
          </Typography>
        </Stack>
        {weeklyEfficiencytrendsData && (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={weeklyEfficiencytrendsData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <XAxis
                dataKey="date"
                className="chart-axis-text"
                angle={270}
                tickMargin={45}
              >
                <Label value="Weeks" position="insideBottom" offset={-70} />
              </XAxis>
              <YAxis
                className="chart-axis-text"
                label={{
                  value: "Transactions per Labor Hour",
                  style: { textAnchor: "middle" },
                  angle: -90,
                  position: "left",
                  offset: 0,
                }}
              />
              <Tooltip
                contentStyle={{ textTransform: "capitalize", fontSize: 14 }}
                filterNull
                formatter={(value) =>
                  typeof value === "number" ? value.toFixed(1) : value
                }
              />
              <Legend wrapperStyle={{ fontSize: 14, paddingTop: 74 }} />
              <Line
                type="linear"
                dataKey="targetValue"
                name="Target Efficiency"
                stroke="#2D70BA"
                strokeDasharray="20 20"
                strokeWidth={4}
                animationDuration={500}
              />
              <Line
                type="linear"
                dataKey="efficiencyValue"
                name="Actual Efficiency"
                stroke="#50AD60"
                strokeWidth={4}
                animationDuration={500}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </Stack>

      <Stack sx={{ ...CONTENT_BOX, minHeight: 500 }} spacing={1.5}>
        <Stack>
          <Typography sx={VP_CONTENT_BOX_TITLE}>
            Forecast Trends by Week(for the past 52 weeks)
          </Typography>
        </Stack>
        {forecastAccuracyTrends && (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={forecastAccuracyTrends}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <XAxis
                dataKey="day"
                className="chart-axis-text"
                angle={270}
                tickMargin={45}
              >
                <Label value="Weeks" position="insideBottom" offset={-70} />
              </XAxis>
              <YAxis
                className="chart-axis-text"
                label={{
                  value: "Forecast Accuracy",
                  style: { textAnchor: "middle" },
                  angle: -90,
                  position: "left",
                  offset: 0,
                }}
                tickFormatter={(value: number) =>
                  typeof value === "number" ? value.toFixed(1) : value
                }
                unit={"%"}
                tickCount={8}
              />
              <Tooltip
                // contentStyle={{ textTransform: "capitalize", fontSize: 14 }}
                // filterNull
                // formatter={(value) =>
                //   typeof value === "number" ? value.toFixed(1) : value
                // }
                content={<CustomTooltip />}
              />
              <Legend wrapperStyle={{ fontSize: 14, paddingTop: 74 }} />
              <Line
                type="linear"
                dataKey="forcastAccuracy"
                name="Forecast Accuracy"
                stroke="#7B7B7B"
                strokeWidth={4}
                animationDuration={500}
                connectNulls
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </Stack>
    </Stack>
  );
}
