import { WorkOffOutlined } from "@mui/icons-material";
import PencilIcon from "@mui/icons-material/Edit";
import {
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CHECK_CIRCLE_ICON from "../../assets/icons/check-circle.svg";
import { CustomSwitch } from "../../components";
import CreateStoreForm from "../Stores/CreateStoreForm";
import {
  CHIP_WRAPPER,
  CONTENT_BOX,
  CONTENT_BOX_TITLE,
  SWITCH_LABEL,
  TABLE_CELL_WRAPPER,
  TABLE_ROW_WRAPPER,
} from "./styles";

export default function OverviewTab({
  storeData,
  isLoading,
  onSave,
  isEdit = false,
}: any) {
  const [editMode, setEditMode] = useState(false);
  const STORE_OVERVIEW_ROWS = [
    {
      label: "Store Code",
      value: storeData?.storeCode || "-",
    },
    {
      label: "ROC",
      value: storeData?.regionName || "",
    },
    {
      label: "RM Manager",
      value: storeData?.regionManagerName || "-",
    },
    {
      label: "RM Email",
      value: storeData?.regionManagerEmail || "-",
    },
    {
      label: "DM Manager",
      value: storeData?.districtManagerName || "-",
    },
    {
      label: "DM Email",
      value: storeData?.districtManagerEmail || "-",
    },
    {
      label: "Store Manager",
      value: storeData?.sm1name || "-",
    },
    {
      label: "Store Manager Email",
      value: storeData?.sm1email || "-",
    },
    {
      label: "Store Account",
      value: storeData?.sm2name || "-",
    },
    {
      label: "Store Account Email",
      value: storeData?.sm2email || "-",
    },
    {
      label: "Address 1",
      value: storeData?.address1 || "-",
    },
    {
      label: "Address 2",
      value: storeData?.address2 || "-",
    },
    {
      label: "City",
      value: storeData?.city || "-",
    },
    {
      label: "State",
      value: storeData?.state || "-",
    },
    {
      label: "Zipcode",
      value: storeData?.zipCode || "-",
    },
    {
      label: "Store Timings",
      value: storeData?.storeHours || [],
    },
    {
      label: "Minimum Staffing Standards",
      value: storeData?.minimumStaffingLevel
        ? parseFloat(storeData?.minimumStaffingLevel).toFixed(1)
        : "-",
    },
  ];
  const handleSubmit = () => {
    setEditMode(false);
    onSave();
  };
  return (
    <Stack style={{ marginTop: 24, overflow: "auto" }} spacing={2} flex={1}>
      {!isLoading && isEdit && (
        <Button
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            background: "#F4FAFC",
            border: "1px solid #CEE8EF",
            borderRadius: "4px",
            textTransform: "capitalize",
          }}
          startIcon={<PencilIcon />}
          onClick={() => setEditMode(true)}
        >
          {" "}
          Edit Store{" "}
        </Button>
      )}
      {isLoading ? (
        <Stack direction={"row"} spacing={2} flex={1}>
          <Stack flex={1}>
            <Skeleton variant="rounded" height={"100%"} width={"100%"} />
          </Stack>

          <Stack flex={0.6} spacing={2}>
            <Skeleton variant="rounded" height={"100%"} sx={{ flex: 1 }} />
            <Skeleton variant="rounded" height={"100%"} sx={{ flex: 1 }} />
          </Stack>
        </Stack>
      ) : isEdit && editMode ? (
        <CreateStoreForm
          onCancel={() => setEditMode(false)}
          onSubmit={handleSubmit}
          isEnable={isEdit && editMode}
          storeData={storeData}
        />
      ) : (
        <Stack direction={"row"} spacing={2} flex={1}>
          <Stack sx={CONTENT_BOX} spacing={2}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={CONTENT_BOX_TITLE}>Store Overview</Typography>
              <FormControl variant="outlined">
                <FormControlLabel
                  disableTypography
                  control={
                    <CustomSwitch
                      sx={{ m: 1 }}
                      checked={storeData?.isActive}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                      inputProps={{ "aria-label": "store-toggle" }}
                      disabled
                    />
                  }
                  label={storeData?.isActive ? "Active" : "Inactive"}
                  style={SWITCH_LABEL}
                />
              </FormControl>
            </Stack>
            <TableContainer>
              <Table aria-label="store table">
                <TableBody>
                  {STORE_OVERVIEW_ROWS.map((row, index) => (
                    <TableRow sx={TABLE_ROW_WRAPPER} key={`store-row-${index}`}>
                      <TableCell sx={TABLE_CELL_WRAPPER}>{row.label}</TableCell>
                      {row.label !== "Store Timings" ? (
                        <TableCell
                          sx={{ ...TABLE_CELL_WRAPPER, color: "#061E23" }}
                        >
                          {row.value}
                        </TableCell>
                      ) : (
                        <TableCell sx={TABLE_CELL_WRAPPER}>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            flexWrap={"wrap"}
                          >
                            {row.value.map((item: any, index: number) => (
                              <Stack key={`hours-${index}`}>
                                <Typography
                                  sx={{
                                    ...TABLE_CELL_WRAPPER,
                                    textAlign: "center",
                                    p: 0,
                                    color: "#061E23",
                                  }}
                                >
                                  {item.day ? item.day.slice(0, 3) : ""}
                                </Typography>
                                <Typography
                                  sx={TABLE_CELL_WRAPPER}
                                  style={{
                                    textAlign: "center",
                                    borderRight:
                                      "1px solid rgba(6, 30, 35, 0.10)",
                                    padding: "0 8px",
                                    color: "#061E23",
                                  }}
                                >{`${item.opensAt} - ${item.closeAt}`}</Typography>
                              </Stack>
                            ))}
                          </Stack>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack spacing={2} flex={0.6}>
            <Stack sx={CONTENT_BOX} spacing={2}>
              <Typography sx={CONTENT_BOX_TITLE}>Excluded Jobs</Typography>
              <TableContainer>
                <Table aria-label="jobs table">
                  <TableBody>
                    {storeData?.storeJobs?.length > 0 ? (
                      storeData?.storeJobs?.map((row: any, index: number) => (
                        <TableRow
                          sx={{
                            ...TABLE_ROW_WRAPPER,
                            borderColor: "#E6F5F6",
                            "&:nth-of-type(even)": {
                              backgroundColor: "rgba(249, 249, 250, 0.60)",
                            },
                          }}
                          key={`jobs-row-${index}`}
                        >
                          <TableCell
                            sx={{
                              ...TABLE_CELL_WRAPPER,
                              borderColor: "#E6F5F6",
                              color: "#0F3D61",
                            }}
                          >
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={"10px"}
                            >
                              <img src={CHECK_CIRCLE_ICON} alt="check-circle" />
                              {row?.serviceName || "-"}
                            </Stack>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...TABLE_CELL_WRAPPER,
                              borderColor: "#E6F5F6",
                              color: "#0F3D61",
                            }}
                          >
                            {row?.jobName || "-"}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow sx={TABLE_ROW_WRAPPER}>
                        <TableCell align="center">
                          <Stack
                            justifyContent={"center"}
                            alignItems={"center"}
                            flex={1}
                            spacing={1}
                          >
                            <WorkOffOutlined
                              fontSize="large"
                              sx={{ color: "rgba(6, 30, 35, 0.34)" }}
                            />
                            <Typography
                              sx={{ ...CONTENT_BOX_TITLE, color: "#595959" }}
                            >
                              No jobs found!
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack sx={{ ...CONTENT_BOX, flex: 0.5 }} spacing={2}>
              <Typography sx={CONTENT_BOX_TITLE}>Services</Typography>
              <Divider
                sx={{ borderBottom: "thin solid rgba(6, 30, 35, 0.10)" }}
              />
              <Stack direction={"row"} flexWrap={"wrap"}>
                {storeData?.storeFeatures?.length > 0 ? (
                  storeData?.storeFeatures.map(
                    (service: any, index: number) => {
                      if (service?.serviceName) {
                        return (
                          <Chip
                            key={`service-${index}`}
                            sx={CHIP_WRAPPER}
                            icon={
                              <img src={CHECK_CIRCLE_ICON} alt="check-circle" />
                            }
                            label={service?.serviceName || ""}
                            size="small"
                          />
                        );
                      }
                      return null;
                    },
                  )
                ) : (
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    flex={1}
                    spacing={1}
                  >
                    <WorkOffOutlined
                      fontSize="large"
                      sx={{ color: "rgba(6, 30, 35, 0.34)" }}
                    />
                    <Typography sx={{ ...CONTENT_BOX_TITLE, color: "#595959" }}>
                      No services found!
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
