import { axiosInstance } from "./serviceConfig";

const romService = {
  getRomDistricts: () => axiosInstance.get(`api/rm/districts`),
  getDistrictROMDashboard: (startDate: string) =>
    axiosInstance.get(`api/rm/dashboard?startDate=${startDate}`),
  getROMDistrictChart: (startDate: string, numberOfWeeks: number) =>
    axiosInstance.get(
      `/api/rm/districts/graphdata?startDate=${startDate}&numberOfWeeks=${numberOfWeeks}`,
    ),
  getDistrictDashboardById: (districtId: string, startDate: string) =>
    axiosInstance.get(
      `/api/rm/districts/${districtId}/stores/dashboard?startDate=${startDate}`,
    ),
  getDistrictEfficiencyById: (districtId: string, startDate: string) =>
    axiosInstance.get(
      `/api/rm/districts/${districtId}/stores/efficiencies?startDate=${startDate}`,
    ),
  getDistrictPlanById: (districtId: string, startDate: string) =>
    axiosInstance.get(
      `/api/rm/districts/${districtId}/stores/plans?startDate=${startDate}`,
    ),
  getWeeklyDemandStaffingData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/rm/stores/${storeId}/efficiency/graphdata/weeklydemandnstaffing?startDate=${startDate}`,
    ),
  getWeeklyEfficiencyTreadsData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/rm/stores/${storeId}/efficiency/graphdata/weeklyefficiencytrends?startDate=${startDate}`,
    ),
  getDayWiseEfficiencyTreadsData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/rm/stores/${storeId}/efficiency/graphdata/daywiseefficiencytrends?startDate=${startDate}`,
    ),
  getEfficiencyTarget: (storeId: number, startWeek: string) =>
    axiosInstance.get(
      `/api/rm/store/${storeId}/efficiency?startDate=${startWeek}`,
    ),
  getDistrictStoreChartDataByDistrictId: (
    districtId: string,
    startDate: string,
    numberOfWeeks: number,
  ) =>
    axiosInstance.get(
      `/api/rm/districts/${districtId}/stores/graphdata?startDate=${startDate}&numberOfWeeks=${numberOfWeeks}`,
    ),

  getRomStores: ({ districtId, regionId, take, skip, searchTerm }: any) => {
    return districtId && regionId
      ? axiosInstance.get(
          `/api/rm/stores?RegionId=${regionId}&DistrictId=${districtId}&Take=${take}&Skip=${skip}&SearchText=${searchTerm}`,
        )
      : axiosInstance.get(
          `/api/rm/stores?Take=${take}&Skip=${skip}&SearchText=${searchTerm}`,
        );
  },
  getKeyAlertsByDistrictId: (districtId: string, startDate: string) =>
    axiosInstance.get(`/api/rm/keyalerts/${districtId}?startDate=${startDate}`),
};

export default romService;
