import { Circle } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import CLOSE_ICON from "../../assets/icons/Icon-close.svg";
import EXPAND_DOWN_ICON from "../../assets/icons/expand_circle_down.svg";
import EXPAND_UP_ICON from "../../assets/icons/expand_circle_up.svg";
import LOCATION_PIN_ICON from "../../assets/icons/location-pin.svg";
import PERSON_ACTIVE_ICON from "../../assets/icons/person-active.svg";
import PERSON_ICON from "../../assets/icons/person.svg";
import SEARCH_ICON from "../../assets/icons/search.svg";
import { BootstrapInput } from "../../components/CustomInput";
import { ROUTES } from "../../routes/constants";
import {
  VP_ACCORDION_ITEM,
  VP_ACCORDION_ITEM_TEXT,
  VP_ACCORDION_SUMMARY,
  VP_ACCORDION_TEXT,
  VP_ACCORDION_TITLE,
  VP_ACCORDION_WRAPPER,
  VP_FILTER_SUBTITLE,
  VP_FILTER_TITLE,
  VP_LEFT_WRAPPER,
  VP_SEARCH_INPUT,
} from "./styles";
import _ from "lodash";

interface RegionFilterProps {
  regionsData: any[];
  isLoading: boolean;
  onSearchChange: (term: string) => void;
  onRegionChange: (region: Region) => void;
  onShowAll: () => void;
  onDistrictChange: (district: District | null) => void;
}

export interface Region {
  name: string;
  regionId: number;
  numerOfDistricts: number;
  numberOfStores: number;
  districts: any[];
}

export interface District {
  name: string;
  districtId: number;
  regionId: number;
  numberOfStores: number;
}

export default function RegionFilter({
  regionsData = [],
  isLoading,
  onSearchChange,
  onRegionChange,
  onShowAll,
  onDistrictChange,
}: RegionFilterProps) {
  const navigate = useNavigate();
  const params = useParams();
  const regionStoreMatch = useMatch(ROUTES.REGION_STORES);
  const regionKPIMatch = useMatch(ROUTES.REGION);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectedDistrict, setSelectedDistrict] = useState<string | null>(null);

  useEffect(() => {
    if (!_.isEmpty(params) && params.regionId && params.districtId) {
      setExpanded(params.regionId);
      setSelectedDistrict(params.districtId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (regionStoreMatch || regionKPIMatch) {
      setExpanded(false);
      setSearchTerm("");
      setSelectedDistrict(null);
      if (regionKPIMatch) {
        onShowAll();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionStoreMatch, regionKPIMatch]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    setSearchTerm(value);
    onSearchChange(value);
  };

  const handleRegionChange =
    (panel: string, selectedRegion: Region) =>
    (_event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (isExpanded) {
        onRegionChange(selectedRegion);
      } else {
        if (!regionStoreMatch) {
          navigate(ROUTES.REGION);
        }
        setSelectedDistrict(null);
        onShowAll();
      }
    };

  const handleDistrictSelect = (districtId: string, district: District) => {
    setSelectedDistrict(districtId === selectedDistrict ? null : districtId);
    if (regionStoreMatch) {
      onDistrictChange(districtId === selectedDistrict ? null : district);
    } else {
      if (districtId === selectedDistrict) {
        navigate("..");
      } else {
        navigate(
          `${ROUTES.REGION}/${district.regionId}/district/${district.districtId}`,
        );
      }
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    onSearchChange("");
  };

  // const handleShowAll = () => {
  //   setExpanded("");
  //   setSearchTerm("");
  //   onShowAll();
  // };

  return (
    <Stack sx={VP_LEFT_WRAPPER}>
      <Stack
        padding={"24px 24px 16px 24px"}
        spacing={2}
        sx={{ borderBottom: "1px solid rgba(6, 30, 35, 0.08)" }}
      >
        <Stack spacing={0.5}>
          <Typography sx={VP_FILTER_TITLE}>{"By Regions"}</Typography>
          <Typography sx={VP_FILTER_SUBTITLE}>
            {isLoading ? <Skeleton /> : `Showing ${regionsData.length} regions`}
          </Typography>
        </Stack>
        <FormControl variant="outlined">
          <BootstrapInput
            sx={VP_SEARCH_INPUT}
            endAdornment={
              searchTerm ? (
                <IconButton
                  size="small"
                  sx={{ padding: "0 4px", background: "transparent" }}
                  onClick={handleClearSearch}
                >
                  <img src={CLOSE_ICON} alt="search" />
                </IconButton>
              ) : (
                <img src={SEARCH_ICON} alt="search" />
              )
            }
            fullWidth
            placeholder="Search for Districts"
            value={searchTerm}
            onChange={onSearch}
          />
        </FormControl>
      </Stack>
      {/* <Stack
        direction={"row"}
        alignItems={"flex-end"}
        justifyContent={"flex-end"}
        paddingTop={2}
        paddingRight={2}
      >
        <Button variant="text" sx={TEXT_BTN} onClick={handleShowAll}>
          Show All
        </Button>
      </Stack> */}
      <Stack p={2} spacing={1} overflow={"auto"}>
        {isLoading ? (
          <Stack spacing={1}>
            {[...Array(3)].map((_, index) => (
              <Skeleton
                key={`skeleton-${index}`}
                variant="rounded"
                height={66}
              />
            ))}
          </Stack>
        ) : (
          regionsData.map((region: Region, index: number) => (
            <Accordion
              key={`region-accordion-${index}`}
              elevation={0}
              disableGutters
              sx={VP_ACCORDION_WRAPPER}
              expanded={expanded === `${region.regionId}`}
              onChange={handleRegionChange(`${region.regionId}`, region)}
            >
              <AccordionSummary
                sx={VP_ACCORDION_SUMMARY}
                expandIcon={
                  <img
                    src={
                      expanded === `${region.regionId}`
                        ? EXPAND_UP_ICON
                        : EXPAND_DOWN_ICON
                    }
                    style={{
                      transform:
                        expanded === `${region.regionId}`
                          ? "rotate(180deg)"
                          : "none",
                    }}
                    alt="expand"
                  />
                }
              >
                <Stack direction={"row"} spacing={2}>
                  <img src={LOCATION_PIN_ICON} alt="location" />
                  <Stack>
                    <Typography sx={VP_ACCORDION_TITLE}>
                      {region?.name || ""}
                    </Typography>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1.5}
                    >
                      <Typography sx={VP_ACCORDION_TEXT}>
                        {`${region?.numerOfDistricts || 0} Districts`}
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={0.75}
                      >
                        <Circle
                          sx={{ color: "rgba(6, 30, 35, 0.16)", fontSize: 8 }}
                        />
                        <Typography sx={VP_ACCORDION_TEXT}>
                          {`${region?.numberOfStores || 0} Stores`}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <List disablePadding>
                  {region?.districts?.map(
                    (district: District, districtIndex: number) => (
                      <ListItemButton
                        key={`dm-${districtIndex}`}
                        sx={VP_ACCORDION_ITEM}
                        selected={
                          selectedDistrict === `${district?.districtId}`
                        }
                        onClick={() =>
                          handleDistrictSelect(
                            `${district?.districtId}`,
                            district,
                          )
                        }
                      >
                        <ListItemIcon>
                          <img
                            src={
                              selectedDistrict === `${district?.districtId}`
                                ? PERSON_ACTIVE_ICON
                                : PERSON_ICON
                            }
                            alt="person"
                          />
                        </ListItemIcon>
                        <ListItemText disableTypography>
                          <Stack>
                            <Typography
                              sx={{
                                ...VP_ACCORDION_ITEM_TEXT,
                                color:
                                  selectedDistrict === `${district?.districtId}`
                                    ? "#006636"
                                    : "#061E23",
                              }}
                            >
                              {district?.name}
                            </Typography>
                            <Typography sx={VP_ACCORDION_ITEM_TEXT}>
                              {`${district?.numberOfStores || 0} Stores`}
                            </Typography>
                          </Stack>
                        </ListItemText>
                      </ListItemButton>
                    ),
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Stack>
    </Stack>
  );
}
