import { StoreOutlined } from "@mui/icons-material";
import {
  Alert,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React from "react";
import OPEN_NEW_ICON from "../../assets/icons/open-new.svg";
import { CONTENT_BOX_TITLE } from "../Store/styles";
import { getFullAddressFromStoreDetails } from "../../utils/helpers";
import EDIT_ICON from "../../assets/icons/pencil.svg";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFF7F5",
    color: "#061E23",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "24px",
    padding: "8px 8px 8px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const rowStyle = {
  border: "1px solid rgba(6, 30, 35, 0.10)", // Adjust the margin as needed
  borderRadius: "8px",
  borderBottom: "0",
};
const rowStyle1 = {
  border: "1px solid rgba(6, 30, 35, 0.10)", // Adjust the margin as needed
  borderRadius: "8px",
  borderTop: "0",
};

export const StoreTable = ({
  rows = [],
  isLoading,
  onRedirect,
  onEdit,
}: any) => {
  return (
    <TableContainer
      sx={{
        display: "flex",
        flex: 1,
        maxHeight: "calc(100vh - 250px);",
        overflow: "auto",
      }}
    >
      {isLoading ? (
        <Stack spacing={4} flex={1}>
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={index > 0 ? 120 : 48}
            />
          ))}
        </Stack>
      ) : (
        <Table aria-label="store table">
          <TableHead>
            <TableRow
              sx={{
                borderRadius: "0px 0px 8px 8px",
                border: "1px solid rgba(6, 30, 35, 0.10)",
                gap: "1px",
              }}
            >
              <StyledTableCell>Store#</StyledTableCell>
              <StyledTableCell>Store Manager Details</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Min. Staffing Standards</StyledTableCell>
              <StyledTableCell>Is Active</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ marginTop: "24px", visibility: "hidden" }}>
              <TableCell
                sx={{ borderBottom: "0" }}
                component="th"
                colSpan={5}
              />
            </TableRow>
            {rows &&
              rows.map((row: any, index: number) => (
                <React.Fragment key={`store-row-${index}`}>
                  <TableRow style={rowStyle}>
                    <TableCell sx={{ borderBottom: "0" }} align="left">
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#061E23",
                          }}
                        >
                          {row.storeCode}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            onRedirect(row.storeId);
                          }}
                        >
                          <img src={OPEN_NEW_ICON} alt="open-new" />
                        </IconButton>
                        {onEdit && (
                          <IconButton
                            onClick={() => {
                              onEdit(row);
                            }}
                          >
                            <img src={EDIT_ICON} alt="edit" />
                          </IconButton>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0" }} align="left">
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#0F3D61",
                          }}
                        >
                          {row.sm1name}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#0F3D61",
                          }}
                        >
                          {row.sm1email}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0" }} align="left">
                      <Stack flexWrap={"wrap"}>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#0F3D61",
                          }}
                        >
                          {getFullAddressFromStoreDetails(row)}
                          {row?.zipCode?.trim() ? `,${row.zipCode}` : ""}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell sx={{ borderBottom: "0" }} align="left">
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#0F3D61",
                          }}
                        >
                          {row.minimumStaffingLevel
                            ? parseFloat(row.minimumStaffingLevel).toFixed(1)
                            : ""}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0" }} align="left">
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        {row.isActive ? (
                          <Alert
                            icon={false}
                            sx={{
                              fontSize: 11,
                              padding: "0px 12px",
                              background: "rgba(89, 216, 255, 0.20)",
                              borderRadius: "4px",
                              color: "#061E23",
                            }}
                          >
                            Yes
                          </Alert>
                        ) : (
                          <Alert
                            icon={false}
                            sx={{
                              fontSize: 11,
                              padding: "0px 12px",
                              background: "rgba(254, 190, 56, 0.20)",
                              borderRadius: "4px",
                              color: "#061E23",
                            }}
                          >
                            No
                          </Alert>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow style={rowStyle1}>
                    {row?.storeJobs && row?.storeJobs?.length > 0 && (
                      <TableCell sx={{ borderBottom: "0" }}>
                        <Stack
                          display={"flex"}
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 500,
                              color: "#808083",
                            }}
                          >
                            Excluded for
                          </Typography>
                          <Divider
                            orientation="vertical"
                            sx={{ height: "20px" }}
                          />
                        </Stack>
                      </TableCell>
                    )}
                    <TableCell sx={{ borderBottom: "0" }} colSpan={2}>
                      <Stack direction={"row"} spacing={0.5}>
                        {row.storeJobs.map((job: any, index: number) => {
                          return (
                            <Typography
                              key={`${job.storeJobsId}-${index}`}
                              sx={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#595959",
                              }}
                            >
                              {` ${job.jobName} - ${job.serviceName} ${
                                row.storeJobs.length - 1 === index ? "" : ","
                              } `}
                            </Typography>
                          );
                        })}
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow style={rowStyle1}>
                    <TableCell sx={{ borderBottom: "0" }}>
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 500,
                            color: "#808083",
                          }}
                        >
                          Region | DM
                        </Typography>
                        <Divider
                          orientation="vertical"
                          sx={{ height: "20px" }}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "0" }}>
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#595959",
                          }}
                        >
                          {`${row?.regionName || ""} - ${
                            row?.districtManagerName || ""
                          }`}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      key={`${row.storeCode}-${row.sm1email}-${index}`}
                      sx={{ borderBottom: "0" }}
                      component="th"
                      colSpan={5}
                    />
                  </TableRow>
                </React.Fragment>
              ))}
            {rows && rows.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    flex={1}
                    spacing={1}
                  >
                    <StoreOutlined
                      fontSize="large"
                      sx={{ color: "rgba(6, 30, 35, 0.34)" }}
                    />
                    <Typography sx={{ ...CONTENT_BOX_TITLE, color: "#595959" }}>
                      No stores found!
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {/* <TableFooter>
            <TableRow>
              {childeren}
            </TableRow>
          </TableFooter> */}
        </Table>
      )}
    </TableContainer>
  );
};
