export const ROUTES = {
  LOGIN: "/",
  HOME: "/home",
  NOTFOUND: "/home/404",
  ERROR: "/home/error",
  STORE: "/home/store",
  REGION: "/home/regions",
  REGION_STORES: "/home/regions/stores",
  DISTRICT: "/home/districts",
  DISTRICT_STORE: "/home/districts/store",
  DISTRICT_STORES: "/home/districts/stores",
  ROM: "/home/roms",
  ROM_STORE: "/home/roms/store",
  ROM_STORES: "/home/roms/stores",
  STORES: "/home/stores",
};
