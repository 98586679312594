import { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton, Stack, SxProps, Typography } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { addWeeks, endOfWeek, format, startOfWeek, subWeeks } from "date-fns";
import CALENDAR_ICON from "../assets/icons/calendar.svg";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "../styles/datepicker.scss";

const INPUT: SxProps = {
  fontSize: 12,
  lineHeight: "24px",
  fontWeight: 500,
  color: "rgba(6, 30, 35, 0.74)",
};

const CustomWeekRangeInput = ({ onWeekChange }: any) => {
  const [storedWeek] = useLocalStorage("storedWeek", "");
  const [currentWeek, setCurrentWeek] = useState(() => {
    if (storedWeek) {
      const { start, end } = JSON.parse(storedWeek);
      return { start: new Date(start), end: new Date(end) };
    } else {
      const startDate = startOfWeek(new Date(), { weekStartsOn: 0 }); // Assuming week starts on Sunday
      const endDate = endOfWeek(startDate, { weekStartsOn: 0 });
      return { start: startDate, end: endDate };
    }
  });

  useEffect(() => {
    onWeekChange(currentWeek);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeek]);

  const handlePreviousWeek = () => {
    setCurrentWeek((prevWeek) => ({
      start: subWeeks(prevWeek.start, 1),
      end: subWeeks(prevWeek.end, 1),
    }));
  };

  const handleNextWeek = () => {
    setCurrentWeek((prevWeek) => ({
      start: addWeeks(prevWeek.start, 1),
      end: addWeeks(prevWeek.end, 1),
    }));
  };

  const onWeekSelection = (value: any) => {
    setCurrentWeek({ start: value[0], end: value[1] });
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{
        borderRadius: "4px",
        border: "1px solid rgba(6, 30, 35, 0.10)",
        background: "rgba(6, 30, 35, 0.01)",
      }}
    >
      <DateRangePicker
        caretAs={"span"}
        character=" - "
        cleanable={false}
        editable={false}
        format="dd MMMM yyyy"
        hoverRange="week"
        onChange={onWeekSelection}
        oneTap
        ranges={[]}
        showOneCalendar
        value={[currentWeek.start, currentWeek.end]}
        renderValue={(value) => (
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <img src={CALENDAR_ICON} alt="calendar" />
            <Typography sx={INPUT}>{`${format(value[0], "dd MMMM")} - ${format(
              value[1],
              "dd MMMM yyyy",
            )}`}</Typography>
          </Stack>
        )}
      />
      <IconButton onClick={handlePreviousWeek} size="small">
        <ChevronLeft />
      </IconButton>
      <IconButton onClick={handleNextWeek} size="small">
        <ChevronRight />
      </IconButton>
    </Stack>
  );
};

export default CustomWeekRangeInput;
