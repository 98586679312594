import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useDistrictContext } from "..";
import CustomWeekRangeInput from "../../../components/CustomWeekRangeInput";
import { VP_SUBTITLE, VP_TITLE } from "../../Region/styles";
import EfficiencyTab from "./EfficiencyTab";
import PlansTab from "./PlansTab";
import StoreDashboardTab from "./StoreDashboardTab";

export default function DistrictTabs() {
  const { selectedTab, handleTabChange, getSelectedWeek } =
    useDistrictContext();
  const getTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <StoreDashboardTab />;
      case 1:
        return <EfficiencyTab />;
      case 2:
        return <PlansTab />;

      default:
        break;
    }
  };
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        pb={3}
        borderBottom={"1px solid rgba(6, 30, 35, 0.10)"}
      >
        <Stack spacing={1}>
          <Typography sx={VP_TITLE}>{"Analysis for Stores"}</Typography>
          <Typography sx={VP_SUBTITLE}>
            {"Here is your analysis for stores"}
          </Typography>
        </Stack>
        <Stack
          flex={0.5}
          justifyContent={"flex-end"}
          direction={"row"}
          alignItems={"center"}
        >
          <CustomWeekRangeInput onWeekChange={getSelectedWeek} />
        </Stack>
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="store tabs"
        >
          <Tab label="Dashboard" value={0} />
          <Tab label="Efficiency" value={1} />
          <Tab label="Plans" value={2} />
        </Tabs>
      </Box>
      {getTabContent()}
    </>
  );
}
