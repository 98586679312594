import {
  Stack,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  useTheme,
  FormHelperText,
  CircularProgress,
  SxProps,
} from "@mui/material";
import { BootstrapInput } from "./CustomInput";
import { useEffect, useState } from "react";

const INPUT_LABEL: SxProps = {
  fontWeight: 500,
  fontSize: 12,
  lineHeight: "16px",
  letterSpacing: "0.4px",
  color: "#595959",
};

export const CustomSelect = ({
  label = "",
  validationText = "",
  options = [],
  maxMenuHeight = 250,
  value,
  onChangeOption,
  reset,
  error = false,
  loading = false,
  flexValue = 1,
  valueKey = "value",
  displayKey = "name",
  ...otherProps
}: any) => {
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    if (reset) {
      setSelectedValue("");
    }
  }, [reset]);

  useEffect(() => {
    setSelectedValue(value || "");
  }, [value]);

  const handleChange = (event: { target: { value: any } }) => {
    const tempSelectedValue = event.target.value;
    setSelectedValue(tempSelectedValue);

    // Find the selected option based on the value
    const selectedOption = options.find(
      (option: any) => option[displayKey] === tempSelectedValue,
    );

    // Call the onChangeOption prop with the selected label
    if (onChangeOption && selectedOption) {
      onChangeOption(selectedOption);
    }
  };

  return (
    <Stack flex={flexValue} spacing={1}>
      {label && (
        <InputLabel
          sx={INPUT_LABEL}
          style={error ? { color: theme.palette.error.main } : {}}
        >
          {label}
        </InputLabel>
      )}
      <FormControl variant="outlined" error>
        <Select
          sx={{
            "& .MuiSelect-select": {
              padding: "6px 12px",
            },
            "& .MuiSelect-nativeInput": {
              bottom: "6px",
              left: "10px",
              position: "absolute",
              opacity: 1,
              pointerEvents: "none",
              width: "80%",
              border: "none",
              fontSize: 14,
              textOverflow: "ellipsis",
            },
            ...(error && {
              borderRadius: 1,
              border: `1px solid ${theme.palette.error.main}`,
            }),
          }}
          value={selectedValue}
          onChange={handleChange}
          input={<BootstrapInput />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: maxMenuHeight,
              },
            },
          }}
          {...otherProps}
        >
          {options?.length > 0 ? (
            options.map((option: any) => (
              <MenuItem
                key={`option-${option[displayKey]}-${option[valueKey]}`}
                value={option[displayKey]}
              >
                {option[displayKey]}
              </MenuItem>
            ))
          ) : (
            <MenuItem
              value=""
              disabled
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color:
                      theme.palette.mode === "dark"
                        ? "#FF910F"
                        : theme.palette.secondary.main,
                  }}
                />
              ) : (
                "No options available"
              )}
            </MenuItem>
          )}
        </Select>
        {error && (
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {validationText}
          </FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
};
