import { Skeleton, Stack, Typography } from "@mui/material";
import { GridReadyEvent, RowHeightParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import React, { useCallback, useContext, useMemo, useRef } from "react";
import { RegionContext } from ".";
import CustomWeekRangeInput from "../../components/CustomWeekRangeInput";
import StatisticsWidget from "../../components/StatsticsWidget";
import "../../styles/region-grid.scss";
import { getGridContainerHeight } from "../../utils/helpers";
import { CONTENT_BOX, GRID_WRAPPER } from "../Store/styles";
import RegionChart from "./RegionChart";
import { VP_SUBTITLE, VP_TITLE } from "./styles";
const DEFAULT_ROW_HEIGHT = 48;

export default function RegionDashboard() {
  const {
    regionHeader,
    getSelectedWeek,
    dashboardLoading,
    chartLoading,
    regionDashboardData,
    regionChartData,
    gridColumns,
    selectedLastWeek,
    handleLastWeekChange,
  } = useContext(RegionContext);

  const regionGridRef = useRef<AgGridReact>(null);

  const EFFICIENCY_DATA = [
    {
      title: "Actual Efficiency",
      value: _.round(regionDashboardData?.kpiData?.efficiency, 1) || 0,
      unit: "",
    },
    {
      title: "Target Efficiency",
      value: _.round(regionDashboardData?.kpiData?.target, 1) || 0,
      unit: "",
    },
    {
      title: "Over/-Under Target",
      value:
        _.round(regionDashboardData?.kpiData?.overUnderTargetValue, 1) || 0,
      unit: "",
      value2: regionDashboardData?.kpiData?.overUnderTargetPercent
        ? `${_.round(regionDashboardData?.kpiData?.overUnderTargetPercent, 1)}%`
        : "",
      unit2: "",
    },
  ];

  const ACTUAL_DATA = [
    {
      title: "Actual Hours",
      value: _.round(regionDashboardData?.kpiData?.actualHoursText, 1) || 0,
      unit: "Hrs",
    },
    {
      title: "Plan Hours",
      value: _.round(regionDashboardData?.kpiData?.planHoursText, 1) || 0,
      unit: "Hrs",
    },
    {
      title: "Over/-Under Plan",
      value:
        _.round(regionDashboardData?.kpiData?.overUnderPlanHoursText, 1) || 0,
      unit: "",
      value2: regionDashboardData?.kpiData?.overUnderPlanHoursPercent
        ? `${_.round(
            regionDashboardData?.kpiData?.overUnderPlanHoursPercent,
            1,
          )}%`
        : "",
      unit2: "",
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      width: 90,
      editable: false,
    };
  }, []);

  const getRowHeight = useCallback(
    (params: RowHeightParams): number | undefined | null =>
      params.data.rowHeight || DEFAULT_ROW_HEIGHT,
    [],
  );

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      if (gridColumns) {
        params.api.sizeColumnsToFit();
      }
    },
    [gridColumns],
  );

  return (
    <React.Fragment>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        pb={3}
        borderBottom={"1px solid rgba(6, 30, 35, 0.10)"}
      >
        <Stack spacing={1}>
          <Typography sx={VP_TITLE}>{regionHeader.title}</Typography>
          <Typography sx={VP_SUBTITLE}>{regionHeader.subTitle}</Typography>
        </Stack>
        <Stack
          flex={0.5}
          justifyContent={"flex-end"}
          direction={"row"}
          alignItems={"center"}
        >
          <CustomWeekRangeInput onWeekChange={getSelectedWeek} />
        </Stack>
      </Stack>

      <Stack style={{ marginTop: 24, overflow: "auto" }} spacing={2}>
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          width={"100%"}
          flex={1}
        >
          {dashboardLoading ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1.5}
              width={"100%"}
            >
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={`kpi-skeleton-${index}`}
                  variant="rounded"
                  height={74}
                  sx={{ flex: 1 }}
                />
              ))}
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1.5}
              flex={1}
            >
              <StatisticsWidget
                flex={1}
                data={EFFICIENCY_DATA}
                flexValues={[0.75, 0.75, 1]}
              />
              <StatisticsWidget
                flex={1}
                data={ACTUAL_DATA}
                flexValues={[0.75, 0.75, 1]}
              />
            </Stack>
          )}
        </Stack>

        <Stack
          sx={{
            ...CONTENT_BOX,
            // background: "rgba(187, 239, 255, 0.24)",
            p: 0,
            overflow: "hidden",
            minHeight: getGridContainerHeight(
              regionDashboardData?.dashboardData,
            ),
            width: "100%",
          }}
        >
          {dashboardLoading ? (
            <Stack spacing={0.25} flex={1}>
              {[...Array(4)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={index > 0 ? 48 : 56}
                />
              ))}
            </Stack>
          ) : (
            <Stack className="ag-theme-alpine" sx={GRID_WRAPPER}>
              <div style={{ height: "100%", width: "100%" }}>
                <AgGridReact
                  gridId="region-grid"
                  ref={regionGridRef}
                  rowData={regionDashboardData?.dashboardData}
                  columnDefs={gridColumns} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  getRowHeight={getRowHeight}
                  headerHeight={28}
                  onGridReady={onGridReady}
                />
              </div>
            </Stack>
          )}
        </Stack>

        <RegionChart
          isLoading={chartLoading}
          chartData={regionChartData}
          selectedLastWeek={selectedLastWeek}
          handleLastWeekChange={handleLastWeekChange}
        />
      </Stack>
    </React.Fragment>
  );
}
