import NotificationsOffOutlined from "@mui/icons-material/NotificationsOffOutlined";
import { ListItemButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useLocalStorage } from "@uidotdev/usehooks";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import ERROR_ICON from "../assets/icons/error-Icon.svg";
import READ_ERROR_ICON from "../assets/icons/read-error.svg";
import READ_ICON_UPDATE from "../assets/icons/read-icon-update.svg";
import UNREAD_NOTIFICATION_ICON from "../assets/icons/unread-notificatoin-icon.svg";
import {
  CONTENT_BOX,
  CONTENT_BOX_SUBTITLE,
  CONTENT_BOX_TITLE,
  LIST_PRIMARY_TEXT,
  LIST_SECONDARY_TEXT,
} from "../screens/Store/styles";
import storeService from "../services/storeService";
import { NotificationType } from "../utils/constants";

interface Props {
  alerts: any[];
}

const getIcon = (item: any) => {
  if (item.isRead) {
    if (item.notificationType.toLowerCase() === NotificationType.Message)
      return <img src={READ_ICON_UPDATE} alt={NotificationType.Message} />;
    else if (item.notificationType.toLowerCase() === NotificationType.Error) {
      return <img src={READ_ERROR_ICON} alt={NotificationType.Error} />;
    }
  } else {
    if (item.notificationType.toLowerCase() === NotificationType.Message) {
      return <img src={UNREAD_NOTIFICATION_ICON} alt="unread" />;
    } else if (item.notificationType.toLowerCase() === NotificationType.Error) {
      return <img src={ERROR_ICON} alt="error" />;
    }
  }
};
const KeyAlerts = ({ alerts }: Props) => {
  const [storedWeek] = useLocalStorage("storedWeek", "");
  const [keyalerts, setKeyalerts] = useState<any>([]);
  useEffect(() => {
    if (alerts?.length > 0) {
      setKeyalerts(alerts);
    }
  }, [alerts]);
  const onNotificationClick = async (item: any) => {
    setKeyalerts((prev: any) => {
      const updatedData = prev.map((alert: any) => {
        if (alert.id === item.id) {
          alert.isRead = true;
        }
        return alert;
      });
      return updatedData;
    });
    try {
      const { start } = JSON.parse(storedWeek);
      await storeService.updateAlert(
        item.id,
        format(new Date(start), "yyyy-MM-dd"),
        6,
      );
    } catch (error) {
      console.log("onNotificationClick error", error);
    }
  };
  return (
    <Stack sx={CONTENT_BOX} spacing={2} height={350}>
      <Stack>
        <Typography sx={CONTENT_BOX_TITLE}>{`Key Alerts ${
          keyalerts?.length ? `(${keyalerts?.length})` : ""
        }`}</Typography>
        <Typography sx={CONTENT_BOX_SUBTITLE}>
          Provide the user with notifications
        </Typography>
      </Stack>
      {keyalerts?.length > 0 ? (
        <List sx={{ width: "100%", overflow: "auto" }}>
          {keyalerts?.map((alert: any, index: number) => (
            <React.Fragment key={`alert-item_${index}`}>
              <ListItem alignItems="flex-start">
                <ListItemButton onClick={() => onNotificationClick(alert)}>
                  <ListItemAvatar>{getIcon(alert)}</ListItemAvatar>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        sx={{
                          ...LIST_PRIMARY_TEXT,
                          fontWeight: alert?.isRead ? 400 : 500,
                        }}
                      >
                        {alert?.emailSubject || ""}
                      </Typography>
                    }
                    secondary={
                      <Typography sx={LIST_SECONDARY_TEXT}>
                        {alert?.emailMessage || ""}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider variant="fullWidth" component="li" />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          flex={1}
          spacing={1}
        >
          <NotificationsOffOutlined
            fontSize="large"
            sx={{ color: "rgba(6, 30, 35, 0.34)" }}
          />
          <Typography sx={{ ...CONTENT_BOX_TITLE, color: "#595959" }}>
            No alerts found!
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default KeyAlerts;
