import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "../components";
import { AuthLayout, NoAuthLayout } from "../layouts";
import ErrorLayout from "../layouts/Error";
import District from "../screens/District";
import DistrictsStores from "../screens/District/Stores";
import DistrictTabs from "../screens/District/Tabs";
import NotFound from "../screens/NotFound";
import ROM from "../screens/ROM";
import ROMDashboard from "../screens/ROM/ROMDashboard";
import ROMStores from "../screens/ROM/Stores";
import ROMDistricts from "../screens/ROM/Tabs";
import Region from "../screens/Region";
import RegionDashboard from "../screens/Region/RegionDashboard";
import RegionDistricts from "../screens/Region/RegionDistricts";
import Store from "../screens/Store";
import Stores from "../screens/Stores";
import { ROUTES } from "./constants";

const Home = React.lazy(() => import("../screens/Home"));

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.LOGIN}
        element={
          <NoAuthLayout>
            <Login />
          </NoAuthLayout>
        }
      />
      <Route
        path={ROUTES.HOME}
        element={
          <AuthLayout>
            <Home />
          </AuthLayout>
        }
      >
        <Route
          path={`${ROUTES.STORE}/:storeId?`}
          element={
            <AuthLayout>
              <Store />
            </AuthLayout>
          }
        />
        <Route
          path={ROUTES.REGION}
          element={
            <AuthLayout>
              <Region />
            </AuthLayout>
          }
        >
          <Route
            index
            element={
              <AuthLayout>
                <RegionDashboard />
              </AuthLayout>
            }
          />
          <Route
            path={`${ROUTES.REGION}/:regionId/district/:districtId`}
            element={
              <AuthLayout>
                <RegionDistricts />
              </AuthLayout>
            }
          />
          <Route
            path={`${ROUTES.REGION}/:regionId/store/:storeId`}
            element={
              <AuthLayout>
                <Store />
              </AuthLayout>
            }
          />
          <Route
            path={ROUTES.REGION_STORES}
            element={
              <AuthLayout>
                <Stores />
              </AuthLayout>
            }
          />
          <Route
            path={`${ROUTES.REGION}/store/:storeId?`}
            element={
              <AuthLayout>
                <Store />
              </AuthLayout>
            }
          />
        </Route>
        <Route
          path={ROUTES.DISTRICT}
          element={
            <AuthLayout>
              <District />
            </AuthLayout>
          }
        >
          <Route
            index
            element={
              <AuthLayout>
                <DistrictTabs />
              </AuthLayout>
            }
          />

          <Route
            path={`${ROUTES.DISTRICT_STORE}/:storeId`}
            element={
              <AuthLayout>
                <Store />
              </AuthLayout>
            }
          />
        </Route>
        <Route
          path={ROUTES.DISTRICT_STORES}
          element={
            <AuthLayout>
              <DistrictsStores />
            </AuthLayout>
          }
        />
        <Route
          path={ROUTES.ROM}
          element={
            <AuthLayout>
              <ROM />
            </AuthLayout>
          }
        >
          <Route
            index
            element={
              <AuthLayout>
                <ROMDashboard />
              </AuthLayout>
            }
          />
          <Route
            path={`${ROUTES.ROM}/district/:districtId`}
            element={
              <AuthLayout>
                <ROMDistricts />
              </AuthLayout>
            }
          />
          <Route
            path={`${ROUTES.ROM}/district/:districtId/store/:storeId`}
            element={
              <AuthLayout>
                <Store />
              </AuthLayout>
            }
          />
          <Route
            path={`${ROUTES.ROM}/store/:storeId?`}
            element={
              <AuthLayout>
                <Store />
              </AuthLayout>
            }
          />
          <Route
            path={ROUTES.ROM_STORES}
            element={
              <AuthLayout>
                <ROMStores />
              </AuthLayout>
            }
          />
        </Route>
        <Route
          path={ROUTES.NOTFOUND}
          element={
            <AuthLayout>
              <NotFound />
            </AuthLayout>
          }
        />
        <Route
          path={`${ROUTES.STORES}`}
          element={
            <AuthLayout>
              <Stores />
            </AuthLayout>
          }
        />
        <Route
          path={ROUTES.ERROR}
          element={
            <AuthLayout>
              <ErrorLayout />
            </AuthLayout>
          }
        />
        <Route path="*" element={<Navigate to={ROUTES.NOTFOUND} replace />} />
      </Route>
      <Route
        path={ROUTES.NOTFOUND}
        element={<Navigate to={ROUTES.NOTFOUND} />}
      />
    </Routes>
  );
};
