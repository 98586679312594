import React from "react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes/constants";
import { loginRequest } from "../config/authConfig";
import { ErrorComponent, Spinner } from ".";

export const Login = () => {
  const navigate = useNavigate();
  const { instance, inProgress, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  const authRequest = {
    ...loginRequest,
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.HOME);
    }
    // eslint-disable-next-line
  }, [inProgress, account, instance, navigate]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      loadingComponent={Spinner}
      errorComponent={ErrorComponent}
    >
      <Box>{inProgress !== InteractionStatus.None ? <Spinner /> : null}</Box>
    </MsalAuthenticationTemplate>
  );
};
