import {
  CellStyle,
  ColDef,
  ColGroupDef,
  ValueFormatterParams,
} from "ag-grid-community";
import _ from "lodash";
import { CustomCellRenderer } from "../../../utils/helpers";

function percentageFormatter(params: ValueFormatterParams) {
  return params.value == null ? "-" : _.round(params.value, 1) + "%";
}

export const REGION_COLUMN_DEFS: (ColDef<any> | ColGroupDef<any>)[] = [
  {
    field: "regionName",
    headerName: "Region",
    minWidth: 160,
    headerClass: ["region-grid-header-text", "region-grid-header-cell"],
    pinned: "left",
    cellClass: "region-grid-cell",
  },
  {
    field: "efficiency",
    headerName: "Actual Efficiency",
    minWidth: 90,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "target",
    headerName: "Target Efficiency",
    minWidth: 90,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    headerName: "Over/-Under Target",
    type: "centerAligned",
    headerClass: [
      "region-grid-header-group-cell",
      "region-grid-header-left-border",
    ],
    children: [
      {
        field: "overUnderTargetValue",
        headerName: "Value",
        minWidth: 90,
        headerClass: [
          "region-grid-header-left-border",
          "region-grid-header-cell",
        ],
        cellRenderer: CustomCellRenderer,
        cellClass: "region-grid-cell",
        cellStyle: (params) => {
          if (params?.data?.efficiency < params?.data?.target) {
            return { color: "#EE4112" } as CellStyle;
          } else {
            return { color: "#00942A" } as CellStyle;
          }
        },
      },
      {
        field: "overUnderTargetPercent",
        headerName: "Percentage",
        minWidth: 90,
        headerClass: ["region-grid-header-cell"],
        cellClass: "region-grid-cell",
        valueFormatter: percentageFormatter,
        cellStyle: (params) => {
          if (params?.data?.efficiency < params?.data?.target) {
            return { color: "#EE4112" } as CellStyle;
          } else {
            return { color: "#00942A" } as CellStyle;
          }
        },
      },
    ],
  },
  {
    field: "actualHoursText",
    headerName: "Actual Hours",
    minWidth: 90,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "planHoursText",
    headerName: "Plan Hours",
    minWidth: 90,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    headerName: "Over/-Under Plan",
    type: "centerAligned",
    headerClass: [
      "region-grid-header-group-cell",
      "region-grid-header-left-border",
    ],
    children: [
      {
        field: "overUnderPlanHoursValue",
        headerName: "Value",
        minWidth: 90,
        headerClass: [
          "region-grid-header-left-border",
          "region-grid-header-cell",
        ],
        cellRenderer: CustomCellRenderer,
        cellClass: "region-grid-cell",
        cellStyle: (params) => {
          if (
            parseFloat(params?.data?.planHoursText) <
            parseFloat(params?.data?.actualHoursText)
          ) {
            return { color: "#EE4112" } as CellStyle;
          } else {
            return { color: "#00942A" } as CellStyle;
          }
        },
      },
      {
        field: "overUnderPlanHoursPercent",
        headerName: "Percentage",
        minWidth: 90,
        headerClass: ["region-grid-header-cell"],
        cellClass: "region-grid-cell",
        valueFormatter: percentageFormatter,
        cellStyle: (params) => {
          if (
            parseFloat(params?.data?.planHoursText) <
            parseFloat(params?.data?.actualHoursText)
          ) {
            return { color: "#EE4112" } as CellStyle;
          } else {
            return { color: "#00942A" } as CellStyle;
          }
        },
      },
    ],
  },
];
