import { ColDef, ColGroupDef } from "ag-grid-community";
import RedirectStoreRender from "./RedirectRender";
import { CustomCellRenderer } from "../../../utils/helpers";

export const EFFICIENCY_COLUMN_DEFS: (ColDef<any> | ColGroupDef<any>)[] = [
  {
    field: "storeCode",
    headerName: "Store#",
    minWidth: 120,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: RedirectStoreRender,
  },
  {
    field: "city",
    headerName: "City",
    minWidth: 170,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "sm1name",
    headerName: "Store Manager",
    minWidth: 170,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    headerName: "Store Account",
    field: "sm2name",
    minWidth: 170,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "previousTarget",
    headerName: "Prev Target",
    minWidth: 170,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "targetValue",
    headerName: "New Target",
    minWidth: 170,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
];
