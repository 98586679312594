// TimeCellEditor.tsx
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { ICellEditorParams } from "ag-grid-community";
import MaskedInput from "react-text-mask";

const TimeCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  const [time, setTime] = useState(props.value);

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return time;
    },
  }));

  function timeMask(value: any) {
    const chars = value.split("");
    const hours: any = [/[0-2]/, chars[0] === "2" ? /[0-3]/ : /[0-9]/];
    const minutes = [/[0-5]/, /[0-9]/];
    return hours.concat(":").concat(minutes);
  }

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setTime(inputValue);
  };

  return (
    <MaskedInput
      mask={timeMask}
      guide={false}
      showMask
      placeholderChar={"\u2000"}
      value={time}
      onChange={handleTimeChange}
      style={{ width: 50, border: "none", outline: "none" }}
    />
  );
});

export default TimeCellEditor;
