import { Stack } from "@mui/material";
import { createContext, useContext } from "react";
import { Outlet } from "react-router-dom";
import { VP_RIGHT_WRAPPER } from "../Region/styles";
import DistrictFilter from "./DistrictFilter";
import { useROM } from "./romHelper";

const romContext = createContext<any>({});
export default function ROM() {
  const {
    districts,
    isDistrictLoading,
    districtDashboardData,
    districtEffiencies,
    selectedTab,
    districtPlans,
    districtHeader,
    gridColumns,
    districtChartData,
    chartLoading,
    selectedWeek,
    selectedDistrict,
    selectedLastWeek,
    getSelectedWeek,
    handleTabChange,
    onDistrictChange,
    handleLastWeekChange,
  } = useROM();

  return (
    <Stack flex={1} direction={"row"} spacing={2}>
      <DistrictFilter
        districtData={districts}
        isLoading={isDistrictLoading}
        onDistrictChange={onDistrictChange}
      />
      <Stack sx={{ ...VP_RIGHT_WRAPPER }} spacing={2}>
        <romContext.Provider
          value={{
            isDistrictLoading,
            districtDashboardData,
            districtEffiencies,
            districtPlans,
            selectedTab,
            districtHeader,
            gridColumns,
            districtChartData,
            chartLoading,
            selectedWeek,
            selectedDistrict,
            selectedLastWeek,
            getSelectedWeek,
            handleTabChange,
            handleLastWeekChange,
          }}
        >
          <Outlet />
        </romContext.Provider>
      </Stack>
    </Stack>
  );
}
// Custom hook to use the context
export const useRomContext = () => {
  return useContext(romContext);
};
