import { Skeleton, Stack, Typography } from "@mui/material";
import { GridReadyEvent, RowHeightParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import StatisticsWidget from "../../../components/StatsticsWidget";

import { useDistrictContext } from "..";
import CustomLineChart from "../../../components/CustomLineChart";
import KeyAlerts from "../../../components/KeyAlerts";
import "../../../styles/grid.scss";
import "../../../styles/region-grid.scss";
import { CONTENT_BOX, GRID_WRAPPER } from "../../Store/styles";
import { DASHBOARD_COLUMN_DEFS } from "../DistrictGrid/dashboardColumns";
import { DM_STORE_COUNT_TEXT } from "../styles";
import { CustomSelect } from "../../../components";
import { WEEKS_DATA } from "../../../utils/constants";

export default function StoreDashboardTab() {
  const {
    districtDashboardData,
    isDistrictLoading,
    chartLoading,
    districtChartData,
    keyAlerts,
    selectedLastWeek,
    handleLastWeekChange,
  } = useDistrictContext();

  const DEFAULT_ROW_HEIGHT = 48;

  const EFFICIENCY_DATA = [
    {
      title: "Actual Efficiency",
      value:
        _.round(districtDashboardData?.districtDashboardKPI?.efficiency, 1) ||
        0,
      unit: "",
    },
    {
      title: "Target Efficiency",
      value:
        _.round(districtDashboardData?.districtDashboardKPI?.target, 1) || 0,
      unit: "",
    },
    {
      title: "Over/-Under Target",
      value:
        _.round(
          districtDashboardData?.districtDashboardKPI?.overUnderTargetValue,
          1,
        ) || 0,
      unit: "",
      value2: districtDashboardData?.districtDashboardKPI
        ?.overUnderTargetPercent
        ? `${_.round(
            districtDashboardData?.districtDashboardKPI?.overUnderTargetPercent,
            1,
          )}%`
        : "",
      unit2: "",
    },
  ];

  const ACTUAL_DATA = [
    {
      title: "Actual Hours",
      value:
        _.round(
          districtDashboardData?.districtDashboardKPI?.actualHoursText,
          1,
        ) || 0,
      unit: "Hrs",
    },
    {
      title: "Plan Hrs",
      value:
        _.round(
          districtDashboardData?.districtDashboardKPI?.planHoursText,
          1,
        ) || 0,
      unit: "Hrs",
    },
    {
      title: "Over/-Under Plan",
      value:
        _.round(
          districtDashboardData?.districtDashboardKPI?.overUnderPlanHoursText,
          1,
        ) || 0,
      unit: "",
      value2: districtDashboardData?.districtDashboardKPI
        ?.overUnderPlanHoursPercent
        ? `${_.round(
            districtDashboardData?.districtDashboardKPI
              ?.overUnderPlanHoursPercent,
            1,
          )}%`
        : "",
      unit2: "",
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      width: 90,
      editable: false,
    };
  }, []);

  const getRowHeight = useCallback(
    (params: RowHeightParams): number | undefined | null =>
      params.data.rowHeight || DEFAULT_ROW_HEIGHT,
    [],
  );

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      if (defaultColDef) {
        params.api.sizeColumnsToFit();
      }
    },
    [defaultColDef],
  );

  return (
    <Stack style={{ marginTop: 24, overflow: "auto" }} spacing={2} flex={1}>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        width={"100%"}
        sx={{ maxHeight: 70 }}
        flex={1}
      >
        {isDistrictLoading ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1.5}
            width={"100%"}
          >
            {[...Array(3)].map((_, index) => (
              <Skeleton
                key={`kpi-skeleton-${index}`}
                variant="rounded"
                height={74}
                sx={{ flex: 1 }}
              />
            ))}
          </Stack>
        ) : (
          <Stack direction={"row"} alignItems={"center"} spacing={1.5} flex={1}>
            <StatisticsWidget
              flex={1}
              data={EFFICIENCY_DATA}
              flexValues={[0.75, 0.75, 1]}
            />
            <StatisticsWidget
              flex={1}
              data={ACTUAL_DATA}
              flexValues={[0.75, 0.75, 1]}
            />
          </Stack>
        )}
      </Stack>
      <Stack direction={"row"} spacing={1.5} sx={{ marginTop: 16 }}>
        <Typography sx={DM_STORE_COUNT_TEXT}>
          Showing Stores (
          {districtDashboardData?.districtStoresDashboard?.length})
        </Typography>
      </Stack>
      <Stack
        sx={{
          ...CONTENT_BOX,
          background: "rgba(187, 239, 255, 0.24)",
          p: 0,
          overflow: "hidden",
          width: "100%",
        }}
      >
        {isDistrictLoading ? (
          <Stack spacing={0.25} flex={1}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={index > 0 ? 48 : 56}
              />
            ))}
          </Stack>
        ) : (
          <Stack className="ag-theme-alpine" sx={GRID_WRAPPER}>
            {districtDashboardData &&
              districtDashboardData?.districtStoresDashboard && (
                <div style={{ height: "100%", width: "100%" }}>
                  <AgGridReact
                    // ref={gridRef}
                    rowData={districtDashboardData?.districtStoresDashboard}
                    columnDefs={DASHBOARD_COLUMN_DEFS} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    getRowHeight={getRowHeight}
                    headerHeight={28}
                    onGridReady={onGridReady}
                    rowSelection="multiple"
                  />
                </div>
              )}
          </Stack>
        )}
      </Stack>

      <Stack flex={1} direction={"column"} height={"100%"} spacing={2}>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignSelf={"flex-end"}
        >
          <CustomSelect
            label={""}
            id="weeks"
            name="weeks"
            placeholder="Select Week"
            options={WEEKS_DATA}
            value={selectedLastWeek.name}
            onChangeOption={handleLastWeekChange}
          />
        </Stack>
        {chartLoading ? (
          <Skeleton
            key={`store-chart-skeleton`}
            variant="rounded"
            height={360}
            sx={{ minHeight: 360 }}
          />
        ) : (
          <CustomLineChart
            data={districtChartData?.data || null}
            lines={districtChartData?.lines || []}
            xDataKey="date"
            yLabel="% Over / -Under Weekly Plan"
            chartTitle={districtChartData?.chartTitle}
            chartSubtitle={districtChartData?.chartSubtitle}
          />
        )}
      </Stack>
      <KeyAlerts alerts={keyAlerts} />
    </Stack>
  );
}
