import { ColDef, ColGroupDef } from "ag-grid-community";
import RedirectStoreRender from "./RedirectRender";
import ScheduleRender from "./ScheduleRender";
import { CustomCellRenderer } from "../../../utils/helpers";

export const PLANS_COLUMN_DEFS: (ColDef<any> | ColGroupDef<any>)[] = [
  {
    field: "storeCode",
    headerName: "Store#",
    minWidth: 120,
    flex: 1,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: RedirectStoreRender,
  },
  {
    field: "city",
    headerName: "City",
    minWidth: 170,
    flex: 1,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "sm1name",
    headerName: "Store Manager",
    minWidth: 170,
    flex: 1,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    headerName: "Store Account",
    field: "sm2name",
    minWidth: 170,
    flex: 1,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "previousPlanHoursText",
    headerName: "Prev Plan",
    minWidth: 150,
    flex: 1,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "newPlanHoursText",
    headerName: "New Plan",
    minWidth: 120,
    flex: 1,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: CustomCellRenderer,
  },
  {
    field: "scheduleHoursText",
    headerName: "Schedule",
    minWidth: 120,
    flex: 1,
    headerClass: [
      "region-grid-header-text",
      "region-grid-header-left-border",
      "region-grid-header-cell",
    ],
    cellClass: "region-grid-cell",
    cellRenderer: ScheduleRender,
  },
];
