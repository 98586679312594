import { axiosInstance } from "./serviceConfig";

const districtService = {
  getDistrictDashboardById: (districtId: string | number, startDate: string) =>
    axiosInstance.get(
      `/api/vp/districts/${districtId}/dashboard?startDate=${startDate}`,
    ),
  getDistrictEfficiencyById: (districtId: string | number, startDate: string) =>
    axiosInstance.get(
      `/api/vp/districts/${districtId}/efficiency?startDate=${startDate}`,
    ),
  getDistrictPlanById: (districtId: string | number, startDate: string) =>
    axiosInstance.get(
      `/api/vp/districts/${districtId}/plans?startDate=${startDate}`,
    ),
  getRegionDistrictStoreById: (
    regionId: string | number,
    districtId: string | number,
    startDate: string,
    numberOfWeeks: number,
  ) =>
    axiosInstance.get(
      `/api/vp/regions/${regionId}/districts/${districtId}/stores/graphdata?startDate=${startDate}&numberOfWeeks=${numberOfWeeks}`,
    ),
  getDistrictManagerStores: (searchTerm: string) =>
    axiosInstance.get(`/api/districtmanager/stores?SearchText=${searchTerm}`),
  getDistrictDashboard: (startDate: string) =>
    axiosInstance.get(`api/districtmanager/dashboard?startDate=${startDate}`),
  getDistrictEfficiency: (startDate: string) =>
    axiosInstance.get(
      `api/districtmanager/efficiencies?startDate=${startDate}`,
    ),
  getDistrictPlans: (startDate: string) =>
    axiosInstance.get(`api/districtmanager/plans?startDate=${startDate}`),
  saveDistrictEfficiencyById: (districtId: string | number, payload: any) =>
    axiosInstance.post(`/api/vp/districts/${districtId}/efficiency`, payload),
  saveRMDistrictEfficiencyById: (
    districtId: string | number,
    startDate: string,
    payload: any,
  ) =>
    axiosInstance.post(
      `/api/rm/districts/${districtId}/efficiency/${startDate}`,
      payload,
    ),
  getWeeklyDemandStaffingData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/districtmanager/stores/${storeId}/efficiency/graphdata/weeklydemandnstaffing?startDate=${startDate}`,
    ),
  getWeeklyEfficiencyTreadsData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/districtmanager/stores/${storeId}/efficiency/graphdata/weeklyefficiencytrends?startDate=${startDate}`,
    ),
  getDayWiseEfficiencyTreadsData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/districtmanager/stores/${storeId}/efficiency/graphdata/daywiseefficiencytrends?startDate=${startDate}`,
    ),
  getEfficiencyTarget: (storeId: number, startWeek: string) =>
    axiosInstance.get(
      `/api/districtmanager/stores/${storeId}/efficiency?startDate=${startWeek}`,
    ),
  getDistrictChart: (startDate: string, numberOfWeeks: number) =>
    axiosInstance.get(
      `/api/districtmanager/stores/graphdata?startDate=${startDate}&numberOfWeeks=${numberOfWeeks}`,
    ),
  getKeyAlerts: (startDate: string) =>
    axiosInstance.get(`/api/districtmanager/keyalerts?startDate=${startDate}`),
};

export default districtService;
