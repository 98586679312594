import { Box, Button, SxProps, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ERROR_IMG from "../assets/icons/error.svg";
import { ROUTES } from "../routes/constants";

const BACK_BTN: SxProps = {
  background: "#FEBE38",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
  textTransform: "capitalize",
  "&:hover": {
    background: "#FEBE38",
    color: "#061E23",
  },
};

const ErrorLayout: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
      gap={2}
    >
      <img src={ERROR_IMG} alt="500" width={85} />
      <Typography fontSize={32} fontWeight={600} color="#061E23">
        Something went wrong
      </Typography>
      <Typography fontSize={16} fontWeight={400} color="rgba(6, 30, 35, 0.80)">
        An unexpected error occurred. Please try again.
      </Typography>
      <Button variant="contained" sx={BACK_BTN} onClick={handleGoBack}>
        Go Back
      </Button>
    </Box>
  );
};

export default ErrorLayout;
