import { IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OPEN_NEW_ICON from "../../../assets/icons/open-new.svg";
import { ROUTES } from "../../../routes/constants";
import { ICellRendererParams } from "ag-grid-community";
import { useCallback } from "react";

const RedirectStoreRender = (props: ICellRendererParams<any>) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    const { storeId } = props.data!;
    if (storeId) {
      navigate(`${ROUTES.DISTRICT_STORE}/${storeId}`, {
        state: { isFromDistrict: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 400,
          color: "#061E23",
        }}
      >{`#${props.value}`}</Typography>
      <IconButton onClick={onClick}>
        <img src={OPEN_NEW_ICON} alt="open-new" />
      </IconButton>
    </Stack>
  );
};
export default RedirectStoreRender;
