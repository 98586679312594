import React, { Suspense } from "react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { AppRoutes } from "./routes";
import { Spinner } from "./components";
import { SnackbarProvider } from "notistack";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <SnackbarProvider maxSnack={3}>
        <Suspense fallback={<Spinner />}>
          <AppRoutes />
        </Suspense>
      </SnackbarProvider>
    </MsalProvider>
  );
}

export default App;
